shareItem() {
	.item {
		box-sizing: border-box;
		display: inline-block;
		height: auto;
		float: none;

		background-color: var(--gray1);
		border-radius: 24px;
		display: inline-flex;
		align-items: center;
		font-weight: var(--font-weight-medium);
		margin-top: 8px;
		margin-left: 12px;
		padding: 2px 2px 2px 8px;

		&:last-of-type {
			margin-right: 0;
		}

		& .remove, & .removeItem {
			&, &:hover {
				background: none;
				border: none;
				box-shadow: none;
				height: 16px;
				padding: 0;
				margin-left: 8px;
				width: 20px;
			}

			&Icon {
				svg {
					fill: var(--gray6) !important;
				}
			}
		}
	}
}

// Rename Folder
#editFolder {
	> div .firstFolderMessage {
		margin: -5px 0 10px;
	}
}

// Rename File
#renameFile div.filetypeicon input {
	padding-left: 27px;
}

// Email File
.fileModalWrapper {

	.file-data {

		> p {
			margin: -1px 0 5px;
			// margin-top: -1px;
			// margin-bottom: 5px;
		}
	}

	.file-icon {
		margin: 5px 14px 0 0;
		// margin-top: 5px;
		// margin-right: 14px;
	}

	.fieldRow:last-of-type {
		margin-bottom: 0;
	}

	.fieldBox {
		margin-right: 0;
	}
}

#emailFileForm {
	width: initial;
	border-top: none;

	input, textarea {
		clear: left;
	}

	.input-list {
		width: 390px;

		.inputs {
			display: none;
		}
	}

	.text,
	#emailMessage {
		width: 390px;
	}
}

// Share File/Folder
#modal-dialog .shareListInputLabel,
#stacked-modal-dialog .shareListInputLabel {
	margin-top: -3px;
}

#shareForm #shareInput {
	max-height: 100%;
	.description {
		padding: 9px 12px;
	}
}

#shareInput.disabled .description,
.shareListInputLabel.disabled {
	color: var(--gray6) !important; // match .fab-Label--disabled
}

.shareListInput {
	box-sizing: border-box;
}

#shareInput.disabled:hover,
input.disabled:hover {
	cursor: default;
}

// Delete Modal Styles
.tornEdgeContainer {
	padding-top: 6px;
	padding-bottom: 8px;
	margin-top: 20px;

	> dl {

		> dt {
			float: left;
			margin: 9px 12px 0 5px;
		}

		> dd {
			padding: 4px 0 5px;

			&:not(:last-child) {
				border-bottom: solid $bhrColors.gray6 1px;
			}

			&::after {
				content: "";
				display: block;
				clear: both;
			}

			> span {
				display: block;
			}

			> span:first-child {
				font-weight: 700;
			}

			> span:last-of-type {
				color: #909090;
				line-height: 15px;
				width: 295px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}

	> .filesCount {
		font-weight: 700;
		font-size: 18px;
		text-align: center;

		> img {
			display: block;
			margin: 8px auto 0;
		}
	}

	> .multiDelete {
		text-align: center;
	}
}

.filesDeleteMsg {
	color: #B62F00;
	display: block;
	font-weight: 700;
	margin: 1px auto 3px;
	width: 75%;
}

.specialFileDeleteMsg {
	color: #B62F00;
	display: block;
	margin: 1px auto 3px;
	width: 75%;
}

// Preview Modal
#previewDialog {
	display: flex;
	height: 100%;
	width: 100%;

	> .branded-icon {
		position: absolute;
		width: 216px;
		height: 4px;
		right: 0;
		z-index: 10;
	}

	#loadingMsg {
		margin: 50px auto 0;
		width: 50%;
		text-align: center;
	}

	#documentContainer {
		box-sizing: border-box;
		flex: 1;
		position: relative;
		overflow: hidden;

		border: none;
		height: calc(100% + 3px);
		padding-bottom: 3px; // this accounts for the .fab-Card box-shadow getting cut off by overflow: hidden
		padding-right: 40px;
	}

	#documentOptions {
		box-sizing: border-box;
		height: 100%;
		padding: 25px 32px;
		position: relative;
		text-align: left;
		overflow-y: auto;
		min-width: 354px;
		padding: 32px;
	}

	#documentViewer {
		left: -9000px;
		position: relative;
		width: 100%;
	}

	// Preview with no Preview Modal
	&.noPreview {

		> #documentContainer {
			width: calc(100% - 148px);
			border: none;
		}

		> div.branded-icon {
			display: none;
		}

		#documentOptions {
			width: 148px;
			right: 29px;
		}
	}

	#noPreviewMsg {
		background: $bhrColors.gray2;
		border: solid #afafaf 1px;
		border-radius: 3px;
		margin: 29px 40px 86px;
		font-size: 18px;
		padding: 82px 40px 83px 50px;
		line-height: 1.2em;
		width: 200px;
	}

	/* Preview with no sidebar */
	&.noSidebar {
		> #documentContainer {
			width: 100%;
			border: none;
		}
	}
}

// UI override
div.ui-dialog { // Do I need div?

	.ui-dialog-title {
		width: 94%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&.uploadFilesModal .ui-dialog-buttonpane {
		padding-top: 4px;
		padding-left: 21px;
	}
}

// Image Preview
.documentImage {
	box-sizing: border-box;
	height: calc(100% - 1px);
	overflow: auto;
	padding: 10px;

	img {
		margin: 0 auto;
		display: block;
		opacity: 0;
		transition: opacity 0.5s;

		&.image--wide {
			height: auto;
			max-width: 100%;
		}

		&.image--tall {
			max-height: 100%;
			width: auto;
		}
	}
}

.resumeImage {
    overflow: auto;
    height: 100%;
    border-top: 1px solid #afafaf;
}

#documentOptions {

	> li {
		padding-left: 4px;
		font-size: 13px;

		&.previewBtn {
			margin-bottom: 2px;

			& + li.previewLink {
				margin-top: 10px;
			}
		}

		&.previewLink {
			margin-bottom: 3px;
			padding-bottom: 0;
		}
	}

	.secondaryFileActions {
		margin-top: 16px;
		margin-bottom: 26px;
		display: flex;
		gap: 8px;
		margin-top: 8px;
		margin-bottom: 24px;

		&--esig {
			margin-bottom: 0;
		}

		// .secondaryFileActions__button
		&__button {
			display: inline-block;
			width: 100%;

			// .secondaryFileActions__button + .secondaryFileActions__button
			& + .secondaryFileActions__button {
				margin-left: 12px;
			}
		}
	}

	.previewLinks {
		padding: 20px 0 8px;

		li {
			padding: 0;
			font-size: inherit;
			margin-bottom: 12px;
		}
	}

	li {
		&.previewInfo {
			display: flex;
			align-items: center;
			margin-bottom: 0;
			padding-bottom: 0;
			fab-font-size: teenie;
			fab-line-height: teenie;
			color: var(--gray6);
			fill: var(--gray5);
			margin-bottom: 6px;

			.previewInfo__icon {
				margin-right: 8px;
			}
		}
	}
}

.documentOptions {

	&__hr {

		fab-border-color: gray2;
	}
	&__info {

		margin-top: 24px;
	}
}

// eSignature related styles
.eSignature {

	&__section-header {
		border-bottom: 1px solid #dbdbdb;
		font-size: 12px !important;
		margin: 20px 0 10px !important;
		padding: 0 0 2px !important;
	}
}


.SignatureSidebar {
	&__list {
		font-size: 13px;
		margin: 0 0 20px;

		.pendingIcon {
			fill: fab-color(attention);
			&.pending {
				fill: fab-color(attention);
			}
		}

		.completeIcon {
			fill: fab-color(success);
			&.pending {
				fill: fab-color(success);
			}
		}
	}

	&__list-icon {
		margin-right: 5px;
	}

	&__list-item {
		align-items: center;
		color: #9;
		display: flex;
		margin: 5px 0;
		padding: 0;
		line-height: 1;
	}
}

.signature-setup {
	color: #acacac;
	cursor: not-allowed;
}


.flexpaper_toolbar .flexpaper_bttnFullScreen {
	display: none;
}

// Delete Folder
#deleteFolder {
	> .deleteMessage {
		width: 85%;
		margin: 6px auto 13px;
	}

	> div.tornEdgeContainer {
		margin-top: 13px;

		.filesCount {

			> img {
				margin-bottom: 2px;
			}

			> span {
				font-weight: 400;
				font-size: 14px;
			}
		}
	}
}


// Delete Folder
.deleteModalContent {
	text-align: center;
}

.inputListWrapper {
	background: none;
	box-shadow: none;
	fab-font-size: small;
	color: fab-color(gray8);
	padding: 0;
}

.filePreviewModalHeader {
	align-items: center;
	background-color: fab-color(gray1);
	border-bottom: 1px solid fab-color(gray3);
	height: 80px;
	display: flex;
	margin: 0;
	padding: 0 32px;

	background-color: white;
	height: 88px;
	padding: 0 40px;

	// .filePreviewModalHeader__title
	&__title {
		max-width: 95%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	// .filePreviewModalHeader__icon
	&__icon {
		margin-right: 10px;
		vertical-align: middle;
	}
	// .filePreviewModalHeader--gh
	&--gh {
	// .filePreviewModalHeader--gh
		& .filePreviewModalHeader__title {
			align-items: center;
			display: flex;
		}
		// .filePreviewModalHeader--gh .filePreviewModalHeader__icon
		& .filePreviewModalHeader__icon {
			display: flex;
			margin-right: 10px;
			vertical-align: unset;
		}
	}
}

// Unique css for these templates
#modal-dialog #allEmployeesLabel,
.stacked-dialog #allEmployeesLabel {
	color: #777777;
	font-size: 13px;
	font-weight: 400;
	margin: 1px 0 10px;
	display: block;
}

.fileModalWrapper {
	padding: 0;
}

#removeFileForm {
	padding-bottom: 20px;
}

.files-add-file {
	margin-top: 6px;
}

.files-progress-wrapper {
	display: inline-block;
	margin-left: 10px;
	width: 92px;
}

// Upload Files Modal
.uploadFilesModal {

	#modal-dialog {
		padding: 1px 0;
	}

	.attach-wrapper {
		width: 100%;
		height: auto;

		> iframe.fallbackframe {
			margin: 7px 10px 0;
		}
	}

	.attach-link {
		text-decoration: none;

		.btn {
			display: block;
			width: 125px;
			margin: 0 auto;
			position: relative;
			top: -21px;
			text-decoration: none;
		}

		span:last-child {
			margin-left: 20px;
			color: #777;
			font-size: 13px;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	#shareWithEmployees + label {
		font-size: 13px;
		color: #646464;
	}

	#modal-dialog.dragOver {
		background-color: #D7D7D7;
	}

	.folderLabel {
		margin-bottom: 0;
	}

	.files:not(:empty) {
		border-bottom: solid #d9d9d9 1px;
		height: 117px;
		overflow: auto;
		margin-bottom: 2px;
	}
}

.file-wrapper {
	align-items: center;
	display: flex;

	&:not(:last-child) {
		border-bottom: solid #d9d9d9 1px;
		border: none;
		padding-bottom: 10px;
	}

	.file-x {
		cursor: pointer;
		fab-fill: gray6;
		&:hover {
			fab-fill: gray9;
		}
		background: none;
		border: none;
		box-shadow: none;
		padding: 0;
		height: 12px;
		width: 12px;
		svg {
			fill: var(--gray6) !important;
		}
	}

	.file-name {
		align-items: center;
		display: flex;
		max-width: 100%;


		//.file-nameRemoveButton
		&RemoveButton {
			cursor: pointer;
			display: flex;
			flex-direction: column;
			justify-content: center;
			min-height: 20px;
			&:not(:hover) {
				fill: #999;
			}
		}

		// .file-nameText
		&Text {
			display: inline-block;
			fab-font-size: medium;
			fab-line-height: teenie;
			flex-shrink: 2;
			max-width: 600px;
			overflow: hidden;
			text-overflow: hidden;
			white-space: nowrap;
			margin-left: 8px;
			margin-right: 12px;
		}
	}

	&.newFile:not(.uploadDone) {
		color: fab-color(gray5);

		.FileType__icon {
			fill: fab-color(gray5);
		}
	}
}

.uploadInProgress {
	fab-color: gray5;
}

.multiUpload .attach-wrapper.centerThis {
	width: 100%;
	text-align: center;
}

.DeletedFilesMessage {
	margin: 8px auto 0;
	text-align: center;

	//.DeletedFilesMessage__files
	&__files {
		padding: 0 27px;

		//.DeletedFilesMessage__files--extra-padding
		&--extra-padding {
			margin-top: 4px;
			padding-bottom: 15px;
			padding-top: 30px;
		}

		//.DeletedFilesMessage__files > dl
		> dl {
			margin-bottom: 10px;

			//.DeletedFilesMessage__files > dl > dt
			> dt {
				float: left;
				margin: 9px 12px 0 5px;
			}

			//.DeletedFilesMessage__files > dl > dd
			> dd {
				padding: 2px 0 5px;

				//.DeletedFilesMessage__files > dl > dd:not(:last-child)
				&:not(:last-child) {
					border-bottom: none;
				}

				//.DeletedFilesMessage__files > dl > dd::after
				&::after {
					content: "";
					display: block;
					clear: both;
				}

				//.DeletedFilesMessage__files > dl > dd > span
				> span {
					display: block;
				}

				//.DeletedFilesMessage__files > dl > dd > span:first-child
				> span:first-child {
					fab-font-size: large;
					fab-font-weight: bold;
				}

				//.DeletedFilesMessage__files > dl > dd > span:last-of-type
				> span:last-of-type {
					display: flex;
					justify-content: center;
					gap: 6px;
					fill: currentColor;
					fab-font-size: teenie;
					fab-line-height: teenie;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					color: var(--gray6);
				}
			}
		}
	}

	// .DeletedFilesMessage__manyFilesIcon
	&__manyFilesIcon {
		fill: var(--gray6);
	}

	// .DeletedFilesMessage > .filesCount
	> .filesCount {
		color: fab-color(gray10);
		fab-font-weight: bold;
		fab-font-size: large;
		margin-top: 10px;
		text-align: center;
	}

	// .DeletedFilesMessage > .multiDelete
	> .multiDelete {
		text-align: center;
	}
}

.specialFileDeleteMsg,
.filesDeleteMsg {
	color: fab-color(danger);
	display: block;
	width: 100%;
	margin: 0;
}

.filesDeleteMsg {
	fab-font-weight: bold;
}

.sharedFileTableWrapper {
	max-height: 235px;
	min-height: 190px;
	overflow-y: auto;
	margin: 0;
}

.sharedFileTableHeader {
	margin-top: 0;
}

.fileModalFooterOptions {
	padding-bottom: 20px;

	.input-list {
		width: 100%;
		margin: 0;
		padding: 0;
		vertical-align: bottom;
		height: auto;

		&[disabled="disabled"] {
			border: 1px solid var(--gray4);
			box-shadow: none;
			background-color: var(--gray05);
			color: var(--gray6);
		}

		.description {
			color: var(--gray5);
			padding: 6px 10px 0;
		}

		.inputs {
			display: none;
		}

		.ac_input, .ac_input:focus {
			border: none;
			box-shadow: none;
			box-sizing: border-box;
			height: 32px;
			position: relative;
			left: 6px;
			width: 95%;
		}
	}

	shareItem();

	.focused {
		box-shadow: 0 0 0 2px fab-color(theme-lightest35);
		border-color: fab-color(theme-lighter);
	}
}


#emailFileForm {
	width: initial;
	border-top: none;

	input, textarea {
		clear: left;
	}

	.input-list {
		width: 100%;
		margin: 0;
		padding: 0;
		vertical-align: bottom;
		height: auto;

		&[disabled="disabled"] {
			border: 1px solid var(--gray4);
			box-shadow: none;
			background-color: var(--gray05);
			color: var(--gray6);
		}

		.description {
			color: var(--gray4);
			padding: 9px 12px;
		}

		.inputs {
			display: none;
		}

		.ac_input, .ac_input:focus {
			border: none;
			box-shadow: none;
			box-sizing: border-box;
			height: 32px;
		}
	}

	.focused {
		box-shadow: 0 0 0 2px fab-color(theme-lightest35);
		border-color: fab-color(theme-lighter);
	}

	.emailList {
		&Wrapper {
			.emailListInput {
				max-height: 100%;
				shareItem();
			}
		}
	}
}

#fileUploadForm {
	margin: 0;

	.uploadDesc {
		fab-font-size: medium;
		text-align: center;

		margin: 0 0 16px;

		&--strong {
			color: var(--gray10);
		}

		&Text {
			display: flex;
			align-items: center;
			justify-content: center;
			fab-font-size: biggie;
			fab-line-height: medium;
			gap: spacing(2);
			margin-bottom: spacing(0.5);
		}

		.uploadModalIcon {
			fill: fab-color(theme);
			margin-right: 12px;
		}
	}
}

.FileUploadActions {
	display: flex;
	flex-wrap: wrap;
	justify-content: left;

	&.centerContent {
		justify-content: center;
		text-align: center;
	}

	.files {
		border-bottom: 1px solid var(--gray2);
		padding-bottom: spacing(1.5);
	}

	.attach-wrapper {
		min-width: 100%;
	}
}

.uploadFooterCheckboxContainer {
		margin: 30px 0 20px;
}

.FileModalShareOptionsWrapper {
	.input-list .input {
		width: 100%;
	}

	.shareForm {
		.input-list {
			margin: 0;
			box-sizing: border-box;
			height: auto;
			display: inline-block;
			width: 100%;
			max-height: none;
			padding: 8px 12px;

			&[disabled="disabled"] {
				border: 1px solid var(--gray4);
				box-shadow: none;
				background-color: var(--gray05);
				color: var(--gray6);
			}

			&.shown {
				position: relative;
				padding: 8px 12px;
			}

			.description {
				color: fab-color(gray6) ;
				display: block;
				margin: 0;
				padding: 0;
				fab-font-size: medium;
			}

			.inputs {
				display: none;
			}

			.free {
				margin: 0;
				padding: 0;
				position: relative;
				top: -1px;
				max-width: 385px;
				vertical-align: bottom;
				min-width: 15px;
				height: 18px;
			}

			shareItem();

			.input {
				display: inline-block;
				min-width: 10px;
				padding-left: 5px;
				margin-bottom: 0;
				height: 20px;
				float: none;
				vertical-align: bottom;
				position: relative;
				top: -4px;
				margin-left: spacing(1);
			}
		}

		.forInputSize {
			border: 1px solid red;
			height: 0;
			visibility: hidden;
			white-space: nowrap;
			position: absolute;
			display: inline;
		}
	}

	//.FileModalShareOptionsWrapper .ac_input, .FileModalShareOptionsWrapper .ac_input:focus
	.ac_input,
	.ac_input:focus {
		border: none;
		box-shadow: none;

		border-radius: 0;
		max-height: none;
		min-height: 0;

		&[disabled="disabled"] {
			border: none;
		}
	}
}

// Control form dropdown with really long names
.uploadFilesModal select {
	max-width: 380px;
}

.ac_results ul li {
	font-size: 14px;

	img {
		float: left;
		margin: 5px 8px 5px 3px;
		vertical-align: top;
		width: 30px;
	}

	span.name {
		display: block;
		position: relative;
		top: 2px;
	}

	span.title {
		display: block;
		font-size: 12px;
	}
}

.eSignature__sendRequest .input-list .input, .eSignature__sendRequest .input-list .description {
	margin: 4px 2px 2px;
}

/* Remove box shadow from esignature request modal. Wasn't filling the entire input space so it looked weird. */
.eSignature__sendRequest .input-list .inputs .input .ac_input {
	box-shadow: none;
}
//.RecipientsSectionLabel
.RecipientsSectionLabel {
	//.RecipientsSectionLabel--error, .RecipientsSectionLabel--error.js-highlightOnError
	&--error,
	&--error.js-highlightOnError
	{
		color: $bhrColors.error2;
		fill: $bhrColors.error1;
	}
	//.RecipientsSectionLabel__icon
	&__icon {
		position: relative;
		top: 1px;
	}
}

.eSignature__sendRequest label.disabled {
	color: #d9d9d9!important;
}

.eSignature__sendRequest .input-list.disabled,
.eSignature__sendRequest .input-list.disabled .description {
	box-shadow: none;
	color: #d9d9d9!important;
	border-color: #d7d7d7;
}

// File Type Icons
.filetypeicon {
	position: relative;

	&:before {
		content: "";
		display: inline-block;
		width: 16px;
		height: 20px;
		position: absolute;
		top: 3px;
		left: 6px;
		background-repeat: no-repeat;
	}

	&.filetype-archive:before {
		background: url("https://staticfe.bamboohr.com/resources/images/files/types/archive-sm.png");
	}

	&.filetype-default:before {
		background: url("https://staticfe.bamboohr.com/resources/images/files/types/default-sm.png");
	}

	&.filetype-doc:before {
		background: url("https://staticfe.bamboohr.com/resources/images/files/types/doc-sm.png");
	}

	&.filetype-email:before {
		background: url("https://staticfe.bamboohr.com/resources/images/files/types/email-sm.png");
	}

	&.filetype-esignature:before {
		background: url("https://staticfe.bamboohr.com/resources/images/files/types/esignature-sm.png");
	}

	&.filetype-img:before {
		background: url("https://staticfe.bamboohr.com/resources/images/files/types/img-sm.png");
	}

	&.filetype-pdf:before {
		background: url("https://staticfe.bamboohr.com/resources/images/files/types/pdf-sm.png");
	}

	&.filetype-ppt:before {
		background: url("https://staticfe.bamboohr.com/resources/images/files/types/ppt-sm.png");
	}

	&.filetype-txt:before {
		background: url("https://staticfe.bamboohr.com/resources/images/files/types/txt-sm.png");
	}

	&.filetype-webpage:before {
		background: url("https://staticfe.bamboohr.com/resources/images/files/types/webpage-sm.png");
	}

	&.filetype-xls:before {
		background: url("https://staticfe.bamboohr.com/resources/images/files/types/xls-sm.png");
	}
}

.icon.shared:before {
	width: 9px;
	height: 10px;
	background-position: 0 -301px;
	margin-right: 3px;
}
