.BhrLegend {
	flex: 1;
	min-width: 0;
	pointer-events: all;
	position: relative;

	&__legendList {
		&--columnLayout {
			display: flex;
			flex-wrap: wrap;
		}
	}

	&__legendItem {
		align-items: center;
		display: flex;
		line-height: 1.2em;
		margin: 8px 0;
		width: 100%;

		/.BhrLegend__legendList--columnLayout & {
			box-sizing: border-box;
			padding-right: 10px;
			width: 33.33%;
		}
	}

	&__swatch {
		border-radius: 2px;
		display: inline-block;
		height: 16px;
		width: 6px;

		@media print {
			-webkit-print-color-adjust: exact;
		}
	}

	&__legendText {
		flex: 1;
		+legacy() {
			font-family: BhrHeaderFont, 'Trebuchet MS';
		}
		margin: 0 0 0 8px;
		min-width: 0;
		text-align: left;
	}

	&__legendSubLabel::before {
		content: '\00a0';
	}

	&__legendSubLabel {
		color: #af;
	}

	&__legendCount {
		font-weight: 600;
		text-align: right;
		width: 25px;
	}

	&__total {
		border-top: 1px solid #d8;
		display: none;
		font-weight: 600;
		padding-left: 15px;
		padding-top: 10px;
	}
}
