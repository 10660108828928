.BhrDonut {
	display: inline-block;
	flex-shrink: 0;
	position: relative;

	&__container {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		left: 50%;
		position: absolute;
		top: 50%;
	}

	&__content {
		box-sizing: border-box;
		max-height: 100%;
		text-align: center;
		width: 100%;
	}

	&__icon {
		fill: #d8;
	}

	&__title {
		color: #8;
		font-size: 35px;
		font-weight: 500;
	}

	&__label {
		box-sizing: border-box;
		color: #af;
		text-align: center;
	}

	&__value {
		box-sizing: border-box;
		display: none;
		+legacy() {
			font-family: BhrHeaderFont, 'Trebuchet MS';
		}
		text-align: center;
		width: 100%;
	}

	&__valueLabel {
		box-sizing: border-box;
		color: #af;
		text-align: center;
	}

}
