.ChangePasswordForm {

	+encore() {
		display: flex;
		flex-grow: 1;
		margin-bottom: spacing(0.5);
		flex-direction: column;

		&__divider {
			margin: spacing(2) 0 spacing(4);
			border-color: var(--gray2);
		}
	}


	.max-length-error-msg {
		display: none;
		fab-text(small);

		&:before {
			display: none;
		}
	}

	.error .max-length-error-msg {
		display: block;
	}

	.error input[type="password"] {
		border-color: fab-color(danger);

		& + .fab-TextInputNub .fab-TextInputNub__icon {
			fill: fab-color(danger);
		}

		&:focus {
			box-shadow: 0 0 0 2px rgba(fab-color(danger), 0.15);
		}
	}

	&__submitButton {
		margin-top: 4px;
	}

	.inputIcons {
		input {
			width: 256px;
		}
	}

	.ValidCheck__icon {
		// @startCleanup encore
		+jadeFabricTheme() {
			left: 12px;
			position: relative;
			top: 8px;
		}
		// @endCleanup encore

		+encore() {
			margin-left: spacing(2);
		}
	}

	.PassCheck__icon {
		position: relative;
		// @startCleanup encore
		left: -8px;
		top: 3px;
		// @endCleanup encore

		+encore() {
			top: 0;
			left: 0;
			margin-right: spacing(2);
		}
	}

	.ValidCheck__icon,
	.PassCheck__icon {
		&--invalid {
			fill: fab-color(gray5);

			+encore() {
				fill: var(--gray5);
			}
		}

		&--valid {
			display: none;
			fill: fab-color(success);

			+encore() {
				fill: var(--success);
			}
		}
	}

	.validCheck {
		padding-right: 0;

		&.valid {
			.ValidCheck__icon--invalid {
				display: none;
			}
			.ValidCheck__icon--valid {
				display: block;
			}
		}
	}
	ul#requirements_list {
		// @startCleanup encore
		+jadeFabricTheme() {
			margin: 16px 0;
			padding: 0 16px;
		}
		// @endCleanup encore

		+encore() {
			margin-top: spacing(0.5);
		}

		li {
			background: none;
			position: relative;
			fab-color: gray8;
			fab-font-size: teenie;
			fab-line-height: medium;
			padding: 0;
			padding-left: 8px;

			+encore() {
				display: flex;
				align-items: center;
				padding-left: 0;
				padding-top: spacing(1.5);
				fab-text: large;
				fab-font-weight: medium;
				color: var(--gray6);
			}

			&:first-of-type {
				padding-top: 0;
			}

			&:before,
			&:after {
				content: none;
			}

			&.ok {
				background: none;

				+encore() {
					color: var(--gray9);
				}

				&:after {
					display: block;
				}

				&:before {
					+jade() {
						border-color: fab-color(success);
					}
				}
				.PassCheck__icon--invalid {
					display: none;
				}
				.PassCheck__icon--valid {
					display: inline-block;
				}
			}
		}
	}
	&__infoHeading {
		fab-color: theme;
		display: block;
		fab-font-size: h3;
		fab-font-weight: semibold;
		fab-line-height: h3;
	}

	&__infoContent {
		fab-color: gray7;
		display: block;
		fab-font-size: large;
		fab-line-height: medium;
		margin: 2px 0 4px;
	}
}
