$border-box {
	box-sizing: border-box;
}

.border-boxed {
	@extend $border-box;
	width: 100%;
}

.page {
	@extend $border-box;
	margin: 0 auto;
	text-align: left;
	// @startCleanup encore
	max-width: 1140px;
	width: 95%;
	// @endCleanup encore
	padding: 0;
	position: relative;
	+encore() {
		max-width: unset;
		width: 100%;
	}
}

#main {
	&Top {
		&.noBreadCrumb {
			padding-top: 22px;
		}
	}
}

.breadcrumbBack,
a.breadcrumbBack,
.ba-breadcrumbBack {
	align-items: center;
	display: inline-flex;
	fab-color: gray7;
	font-size: var(--font-size-xsmall);
	gap: 8px;
	line-height: var(--line-height-xsmall);
	padding-bottom: 16px;

	&:hover {
		color: #006ec2;
	}

	&:before {
		content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 320 512'%3E%3Cg fill='%23777270'%3E%3Cpath d='M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z'/%3E%3C/g%3E%3C/svg%3E");
		float: left;
		height: 16px;
	}
	
	// @startCleanup encore
	&--white:before {
		content: url("data:image/svg+xml,%3Csvg%20width%3D%228%22%20height%3D%227%22%20viewBox%3D%220%200%208%207%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20fill%3D%22%23FFF%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M3.544%200l.54%201.223-2.3%202.272%202.302%202.27-.542%201.225L0%203.495%203.544%200z%22%2F%3E%3Cpath%20d%3D%22M2%203h6v1H2z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E%0A");
		+encore() {
			content: ""
		}
	}
	// @endCleanup encore
}

#content {
	@extend .border-boxed;
	margin-top: 29px;
	z-index: 0;
	// @startCleanup encore
	min-width: 993px;
	// @endCleanup encore
	+encore() {
		min-width: unset;
		margin-top: unset;
	}

	.mainColumn {
		width: 75%;
		float: left;
		@extend $border-box;
	}

	.sideColumn {
		float: right;
		width: 28%;
		@extend $border-box;

		&Left,
		&Right {
			@extend $border-box;
			width: 24.5%;
		}

		&Left {
			float: left;
			padding-right: 3.5%;
			padding-bottom: 20px;
		}

		&Right {
			float: right;
			padding-left: 3.5%;
		}
	}

	> .headerBar {
		display: block;
		position: absolute;
		left: 0;
		width: 100%;
		height: 84px;
	}

	&-nav {
		ul {
			&.subsection-list {
				float: left;
				width: 100%;
				margin: 7px 0 10px 0;
				border-bottom: 1px solid #e5;
			}
		}
	}
	// #contentTop
	&Top {
		// #contentTop > .breadcrumbBack
		& > .breadcrumbBack {
			padding-bottom: 0;
			position: relative;
		}
		// #contentTop.noBreadCrumb,
		// #fixedWrap #contentTop.noBreadCrumb
		&.noBreadCrumb {
			&,
			#fixedWrap & {
				padding-top: 23px;
			}
		}
		// #contentTop > h2:first-child
		> h2 {
			&:first-child {
				float: left;
				margin-bottom: 2px;
			}
		}
		// #contentTop #addLinks
		#addLinks {
			margin-top: 29px;
		}
		// .headerBar + #contentTop
		.headerBar + & {
			height: 84px;
		}
		// #contentTop .left
		.left {
			width: 45%;
			float: left;
			padding: 0 0 8px 0;
		}
		// #contentTop .right
		.right {
			width: 400px;
			float: right;
			text-align: right;
			padding: 36px 0 7px 0;
		}
		// #contentTop .contentTop-action
		.contentTop-action {
			margin-top: 7px;
			// #contentTop .contentTop-action-icon
			&-icon {
				position: relative;
				top: 1px;
				margin-right: 2px;
			}
		}
	}
	//body.content-tight-top #content
	body.content-tight-top & {
		margin-top: 0;
	}
}

#inside-content {
	margin-bottom: unset;

	&.noTopMargin {
		margin-top: -12px;
	}

	&.noBottomMargin {
		margin-bottom: 0;
	}
}

#header-alert {
	@extend .border-boxed;
	text-align: center;
	min-width: 993px;
}

.hidden {
	display: none !important;
}

.no-display { display: none; }

.invisible {
	visibility: hidden !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */

.visually-hidden {
	position: absolute !important;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	white-space: no-wrap; /* 1 */
}

/*
 * Extends the .visually-hidden class to allow the element
 * to be focusable when navigated to via the keyboard
 */

.visually-hidden a:focus,
.visually-hidden input:focus,
.visually-hidden button:focus {
	position: static;
	width: auto;
	height: auto;
	overflow: visible;
	clip: auto;
	white-space: inherit;
}

.noscroll {
	overflow: hidden;
}

.full-width {
	width: 100% !important;
}

.width50 {
	width: 50%;
}

.fl-left,
.floatLeft {
	float: left;
}

.fl-right,
.floatRight {
	float: right;
}

.clearfix {
	*zoom: 1; // @stylint ignore

	&,
	/.page {
		&:before,
		&:after {
			content: "";
			display: table;
		}

		&,
		/.mainColumn,
		/.sideColumnLeft,
		/.sideColumnRight {
			&:after {
				clear: both;
			}
		}
	}
}

/* @startCleanup encore */
:where(body[data-fabric-theme~="jade"]) section {	
	&:before,
	&:after {
		content: "";
		display: table;
	}
}	
/* @endCleanup encore */

.no {
	&-margin {
		margin: 0 !important;

		&-top {
			margin-top: 0 !important;
		}

		&-right {
			margin-right: 0 !important;
		}

		&-bottom {
			margin-bottom: 0 !important;
		}

		&-left {
			margin-left: 0 !important;
		}
	}

	&-padding {
		padding: 0 !important;

		&-top {
			padding-top: 0 !important;
		}

		&-right {
			padding-right: 0 !important;
		}

		&-bottom {
			padding-bottom: 0 !important;
		}

		&-left {
			padding-left: 0 !important;
		}
	}

	&Border,
	&-borders {
		border: none !important;
	}

	&-float {
		float: none;
	}

	&-wrap {
		white-space: nowrap;
	}
}


.hideWhileProcessing {
	transition: opacity 0.2s linear;

	&--processing {
		opacity: 0;
	}
}
