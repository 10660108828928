.BhrForms--view {
	label.fab-Label--required::after {
		content: none;
	}
	
	input[type=checkbox]:enabled {
		~ label::before {
			// @startCleanup encore
			border-color: fab-color(gray3);
			// @endCleanup encore
			+encore() {
				border-color: var(--gray2);
			}
			box-shadow: none;
		}
	}

	input[type=text],
	textarea {
		&:enabled {
			// @startCleanup encore
			border-color: fab-color(gray3);
			// @endCleanup encore
			+encore() {
				border-color: var(--gray2);
			}
			box-shadow: none;
			&:hover:not(:focus) {
				// @startCleanup encore
				border-color: fab-color(gray4);
				// @endCleanup encore
				+encore() {
					border-color: var(--gray3);
				}
			}
		}
		+ button,
		+ .ControlledCurrency__Menu--wrapper {
			opacity: 0;
			visibility: hidden;
		}
	}

	textarea {
		resize: none;
	}

	.fab-SelectToggle__innerFacade {
		// @startCleanup encore
		border-color: fab-color(gray3);
		// @endCleanup encore
		+encore() {
			border-color: var(--gray2);
			box-shadow: none;
		}
	}

	.fab-SelectToggle--clearable .fab-SelectToggle__clearButton,
	.fab-SelectToggle__toggleButton {
		opacity: 0;
		visibility: hidden;
	}

	.fab-Select:hover:not(:focus) {
		.fab-SelectToggle__innerFacade {
			// @startCleanup encore
			border-color: fab-color(gray4);
			// @endCleanup encore
			+encore() {
				border-color: var(--gray3);
			}
		}
	}	

	.fabInternal-DatePickerInput .MuiInputBase-adornedEnd {
		+encore() {
			border-color: var(--gray2);
			box-shadow: none;
		}
	}

	&:not(.override-view-placeholder) label.placeholder {
		opacity: 0;
		visibility: none;
	}
}
