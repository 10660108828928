/*------------------------------------*\
$TOOLTIPS MODULE

styles used for tooltips
\*------------------------------------*/

/* -----------------------------------------------------
Fancy Tooltip
---------------------------------------------------- */
.fancyTT {
	border: 1px solid #000;
	border-radius: 3px;
	color: #FFF;
	background-color: #383838;
	display: none;
	position: absolute;
	padding: 3px 6px 4px;
	/* max-width: 125px;*/
	box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.2);
	z-index: 1;
	margin-top: 10px;
	line-height: 16px;
	font-size: 12px;
	margin-right: 3px;
	white-space: nowrap;

	&:before {
		content: url("https://staticfe.bamboohr.com/resources/images/tip-arrow.png");
		position: absolute;
		top: -12px;
		left: 50%;
		margin-left: -6px;
	}

	.ui-dialog & {
		white-space: normal;
	}

	.ttTitle {
		font-weight: 600;
	}

	&left ~ & {
		margin-left: -3px !important;

		&:before {
			left: 9px;
		}
	}

	&left ~ & {
		margin-right: -3px !important;

		&:before {
			right: 9px;
		}
	}
}

/* /fancy tooltips */
/* tooltipDark - jquery tools tooltip */
.tooltipDark {
	z-index: 99;
	height: auto;
	width: 190px;
	padding: 6px 7px 9px 10px;
	background: rgb(58, 58, 58);
	background: rgba(58, 58, 58, 0.85);
	border-radius: 3px;
	font-size: 14px;
	font-weight: 700;
	color: #ffffff;
	line-height: 18px;
	text-align: left;

	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 10px;
		left: -15px;
		border: solid transparent 7px;
		border-right-color: rgb(58, 58, 58);
		border-right-color: rgba(58, 58, 58, 0.85);
		border-right-width: 8px;
	}

	&.short {
		width: 180px;
	}

	&.left {
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 10px;
			left: initial;
			right: -15px;
			border: solid transparent 7px;
			border-left-color: rgba(58, 58, 58, 0.85);
			border-left-width: 8px;
		}
	}
}

/* -----------------------------------------------------
bhrTooltip
---------------------------------------------------- */
.bhrTooltip {
	position: relative;

	&-tip {
		// @startCleanup encore
		background-color: #64;
		border-radius: 2px;
		+encore() {
		// @endCleanup encore
			border-radius: 8px;
			box-shadow: 4px 4px 6px 2px rgba(56 49 47, 0.12);
			background-color: var(--gray9);
			color: var(--white);
		// @startCleanup encore
		}
		// @endCleanup encore
		color: #fff;
		display: block;
		font-size: 12px;
		line-height: 15px;
		opacity: 0;
		visibility: hidden;
		position: absolute;
		padding: 6px 10px;
		transition: opacity 0.2s;
		width: auto;
		z-index: 100;
		max-width: 174px;
		top: 0;
		left: -900px;

		// @startCleanup encore
		&:after {
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 6px solid #64;
			content: "";
			width: 0;
			height: 0;
			opacity: 0;
			position: absolute;
			bottom: -5px;
		  	left: calc(50% - 5px);
		  	transition: opacity 0.2s;
			+encore() {
				content: unset;
			}
		}
		// @endCleanup encore

		&.left {
			text-align: left;
		}

		&.right {
			text-align: right;
		}

		&[placement="bottom"] {
			&:after {
				left: calc(50% - 5px);
				top: -5px;
				transform: rotate(180deg);
			}
		}

		&[placement="right"],
		&[placement="topright"] {
			&:after {
				left: -7px;
				transform: rotate(90deg);
			}
		}

		&[placement="right"] {
			&:after {
				top: calc(50% - 2.5px);
			}
		}

		&[placement="topright"] {
			&:after {
				top: 8px;
			}
		}

		&[placement="left"],
		&[placement="topleft"] {
			&:after {
				left: calc(100% - 3px);
				transform: rotate(270deg);
			}
		}

		&[placement="left"] {
			&:after {
				top: calc(50% - 2.5px);
			}
		}

		&[placement="topleft"] {
			&:after {
				top: calc(20% - 2.5px);
			}
		}

		&.show {
			&,
			&:after {
				opacity: 1;
				visibility: visible;
			}
		}

		.lead {
			font-size: 13px;
			line-height: 17px;
			margin: 0;
		}

		li {
			line-height: 1.2;
			margin: 3px 0 0;
			max-width: 200px;
		}
	}
	// .bhrTooltip--autoWidth
	&--autoWidth {
		max-width: inherit;
	}
}

{featureToggleOn("jade")} {
	.bhrTooltip {
		&-tip {
			fab-background-color: gray8;
			fab-color: white;
			fab-text: teenie;
			padding: 8px 15px 10px;

			&:after {
				border-top-color: fab-color(gray8);
				border-left-width: 8px;
				border-right-width: 8px;
				border-top-width: 7px;
				bottom: -7px;
				left: calc(50% - 7px);
				transition: none;
			}

			&[placement="bottom"]:after {
				top: -7px;
			}

			&[placement="right"],
			&[placement="topright"] {
				&:after {
					left: -11px;
					transform: rotate(90deg);
				}
			}

			&[placement="left"],
			&[placement="topleft"] {
				&:after {
					left: calc(100% - 5px);
					transform: rotate(270deg);
				}
			}

			.lead {
				display: inline;
				fab-text: small;
				fab-font-weight: bold;
			}

			ul {
				display: inline;
			}

			li {
				display: inline;
			}
		}
	}
}
