root = ".blankState";

/* @startCleanup encore */
{root} {
	border-top: solid #af 1px;
	text-align: center;
	padding-top: 80px;
	font-size: 22px;
	color: #bf;
	clear: both;

	+jade() {
		color: fab-color(gray7);
		padding-top: 72px;
	}

	// this and evey usage below are temp overrides that will not be necessary once Jade code is removed
	+encoreHighSpecificity() {
		border: none;
		fab-color: gray6;
		padding-top: unset;
	}

	// .blankState--centerAlign
	&--centerAlign {
		+encoreHighSpecificity() {
			align-content: center;
			height: inherit;
		}
	}

	// .blankState__actions
	&__actions {
		margin: 24px 0 52px;

		+encoreHighSpecificity() {
			margin: unset;
			margin-top: 24px;
		}
		{root}--widget & {
			+encoreHighSpecificity() {
				margin-top: 16px;
			}
		}
	}

	// .blankState--noLine,
	// .blankState.noLine
	&--noLine,
	&.noLine {
		border: none;
	}
	// .blankState--tigherTop
	&--tigherTop {
		padding-top: 38px;
	}
	// .blankState.withBottomPadding
	&.withBottomPadding {
		padding-bottom: 160px;
	}

	&__icon {
		+jade() {
			fill: fab-color(gray4);
		}

		{root}--widget & {
			+encoreHighSpecificity() {
				fab-fill: gray5;
			}
		}
	}
	// .blankState > img
	// .blankState > .blankState__image
	> img,
	& > &__image {
		margin-bottom: 22px;

		+jade() {
			fill: fab-color(gray4);
			margin-bottom: 32px;
		}

		&--tight {
			margin-bottom: 8px;
		}

		+encoreHighSpecificity() {
			fab-fill: gray4;
			&, &--tight {
				margin-bottom: 24px;
			}
		}
		{root}--widget& {
			+encoreHighSpecificity() {
				fab-fill: gray5;
				&, &--tight {
					margin-bottom: 16px;
				}
			}
		}
	}

	// .blankState__svgImage
	&__svgImage {
		fill: #ed;
		margin: 14px 0 24px;
		+jade() {
			fab-fill: gray4;
		}
		
		+encoreHighSpecificity() {
			fab-fill: gray4;
			margin: unset;
			margin-bottom: 24px;
		}
		{root}--widget & {
			+encoreHighSpecificity() {
				fab-fill: gray5;
				margin-bottom: 16px;
			}
		}
	}

	// .blankState h3
	h3 {
		font-family: "Dancing Script";
		color: #b4;
		font-size: 50px;
		font-weight: normal;
		margin-bottom: 27px;

		+jade() {
			font-family: inherit;
			color: fab-color(gray6);
		}

		+encoreHighSpecificity() {
			fab-color: gray6;
			fab-font-weight: semibold;
			fab-text: h3;
			font-family: $encoreHeaderFontFamilyStack;
			margin-bottom: unset;
		}
		{root}--widget& {
			+encoreHighSpecificity() {
				font-family: $encoreHeaderFontFamilyStack;
				fab-fill: gray5;
			}
		}
	}

	// .blankState__title,
	// .blankState .title
	&__title,
	.title {
		font-family: BhrHeaderFontStack;
		color: #b4;
		font-size: 28px;
		font-weight: 600;
		margin-bottom: 0;
		+jade() {
			font-family: inherit;
			color: fab-color(gray6);
			fab-font-size: h3;
			fab-line-height: h3;
			fab-font-weight: semibold;
			margin-bottom: 8px;
			margin-top: 24px;
		}

		+encoreHighSpecificity() {
			font-family: $encoreHeaderFontFamilyStack;
			margin-bottom: unset;
			margin-top: unset;
			&--dark {
				color: var(--gray6, fab-color(gray6));
			}
		}
	} 

	&--widget &__title, &--widget .title {
		+encoreHighSpecificity() {
			font-family: $encoreFontFamilyStack;
			fab-text: h5;
		}
	}

	// .blankState strong
	strong {
		font-weight: 600;
		+encoreHighSpecificity() {
			font-weight: unset;
		}
	}
	// .blankState .flourish
	.flourish {
		margin: 42px auto 0;
		display: block;
		+encoreHighSpecificity() {
			display: unset;
			margin: unset;
		}
	}
	// .blankState .jobLink
	.jobLink {
		margin-top: 24px;
		font-size: 18px;
		+encoreHighSpecificity() {
			margin-top: unset;
			font-size: unset;
		}
	}
	// .blankState #foot-link
	#foot-link {
		float: none;
		margin: 23px auto 0;
		text-align: center;
		+encoreHighSpecificity() {
			float: unset;
			margin: unset;
			text-align: unset;
		}
	}

	//.blankState .footer
	.footer {
		margin: 23px auto 0;
		text-align: center;
		+encoreHighSpecificity() {
			margin: unset;
			text-align: unset;
		}
	}
	// .blankState__link
	&__link {
		font-size: 14px;
		margin-top: 8px;
		display: inline-block;
		+encoreHighSpecificity() {
			font-size: unset;
			margin-top: unset;
			display: unset;
		}
	}
	// .blankState__list
	&__listItem {
		// .blankState__list:first-of-type .blankState__link
		&:first-of-type .blankState__link {
			margin-top: 0;
			+encoreHighSpecificity() {
				margin-top: unset;
			}
		}
		// .blankState__list .blankState__link
		.blankState__link {
			font-size: 15px;
			line-height: 19px;
			font-weight: 600;
			+encoreHighSpecificity() {
				font-size: unset;
				line-height: unset;
				font-weight: unset;
			}
		}
	}
	// .blankState__subText
	&__subText {
		font-size: 16px;

		+jade() {
			color: fab-color(gray6);
			fab-font-size: large;
			fab-line-height: large;
		}

		+encoreHighSpecificity() {
			fab-color: gray6;
			fab-text: medium;
		}
		&--widget & {
			+encoreHighSpecificity() {
				font-size: var(--font-size-xsmall);
				line-height: var(--line-height-xsmall);
			}
		}

		// .blankState__subText--medium
		&--medium {
			font-size: 18px;
			
			+encoreHighSpecificity() {
				font-size: unset;
			}
		}
		// .blankState__subText--large
		&--large {
			font-size: 22px;
			line-height: 26px;
			
			+encoreHighSpecificity() {
				font-size: unset;
				line-height: unset;
			}
		}
	}
}

+encore() {
/* @endCleanup encore */
{root} {
	clear: both;
	fab-color: gray6;
	fab-font-weight: semibold;
	fab-text: h3;
	font-family: $encoreHeaderFontFamilyStack;
	text-align: center;

	&__actions {
		align-items: center;
		display: flex;
		fab-text: medium;
		gap: 16px;
		justify-content: center;
		margin-top: 24px;
	}
	&--widget {root}__actions {
		font-size: var(--font-size-xsmall);
		gap: 12px;
		line-height: var(--line-height-xsmall);
		margin-top: 16px;
	}

	icon() {
		{root}__icon,
		{root}__image,
		{root}__svgImage,
		> img {
			{block}
		}
	}
	+icon() {
		fab-fill: gray4;
		margin-bottom: 24px;
	}
	&--widget {
		+icon() {
			fab-fill: gray5;
			margin-bottom: 16px;
		}
	}

	title() {
		h3,
		{root}__title,
		.title {
			{block}
		}
	}
	+title() {
		fab-color: gray6;
		fab-text: h3;
		fab-font-weight: semibold;
		font-family: $encoreHeaderFontFamilyStack;
	}
	&--widget {
		+title() {
			fab-text: h5;
		}
	}

	&__subText {
		fab-color: gray6;
		fab-font-weight: regular;
		fab-text: medium;
		font-family: $encoreFontFamilyStack;
		margin-top: 8px;
	}
	&--widget {root}__subText {
		font-family: $encoreFontFamilyStack;
		font-size: var(--font-size-xsmall);
		line-height: var(--line-height-xsmall);
	}
	&--marginTop {
		margin-top: 80px;
	}
}
/* @startCleanup encore */
}
/* @endCleanup encore */
