.CustomApprovalForm {
  +encore() {
    & .dateFieldWrapper {
      & .CalendarPicker__toggleButton {
        &:hover,
        &:focus,
        &:focus-visible,
        &:focus-within {
          border-radius: 8px;
        }
      }
    }
  }
}