.Balloon {
	// Starting values that are overridden with inline styles later
	display: none;
	opacity: 0;

	// transitionDuration and transitionDelay are set inline in Balloon.mod.js
	transition-property: opacity;
	transition-timing-function: ease-in-out;

	box-sizing: border-box;
	position: absolute;

	&__closeX {
		position: absolute;
		right: 10px;
		top: 10px;
		z-index: 20;
	}

	&__closeSVG {
		fill: #c;
		pointer-events: none;
		transition: fill ease-in-out 0.1s;
	}

	&__border {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}

	&__tail {
		position: absolute;
	}

	&__content {
		position: relative;
		z-index: 10;
	}
}

.Balloon__closeX:hover .Balloon__closeSVG {
	fill: #5f;
}

.Balloon[position="top"] .Balloon__tail {
	transform: translate(-50%, 50%) rotate(45deg);
	bottom: 1px;
}

.Balloon[position="right"] .Balloon__tail {
	left: 1px;
	transform: translate(-50%, -50%) rotate(45deg);
	box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14);
}

.Balloon[position="bottom"] .Balloon__tail {
	top: 1px;
	transform: translate(-50%, -50%) rotate(45deg);
}

.Balloon[position="left"] .Balloon__tail {
	right: 1px;
	transform: translate(50%, -50%) rotate(45deg);
	box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14);
}

{featureToggleOn("jade")} {
	.Balloon__closeSVG  {
		fab-fill: gray6;
	}

	.Balloon__closeX:hover .Balloon__closeSVG {
		fab-fill: gray8;
	}
}

/********************************************************
Standard Popover
********************************************************/

@require "./popover.styl";
@require "./tooltip.styl";
