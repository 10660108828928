@require "~@bamboohr/fabric/dist/styles.css";

body {
	for $name, $radius in $fabBorderRadius {
		$radiusVarName = '--radius-' + $name;
		{$radiusVarName}: $radius;
	}
}

// @startCleanupEncore
body[data-fabric-theme~="encore"] {
	// Setting up the variables needed for the legacy style sheets
	--theme-base-default: $encoreDefaultTheme.base;
	--theme-dark-default: $encoreDefaultTheme.dark;
	--theme-light-default: $encoreDefaultTheme.light;
	--theme-lighter-default: $encoreDefaultTheme.lighter;
	--theme-lightest-default: $encoreDefaultTheme.lightest;
	--theme-lightest10-default: rgba($encoreDefaultTheme.lightest, 0.1);
	--theme-lightest35-default: rgba($encoreDefaultTheme.lightest, 0.35);
	--theme-lightest50-default: rgba($encoreDefaultTheme.lightest, 0.5);

	for $name, $color in $fabEncoreColors {
		$colorVarName = '--' + $name;
		{$colorVarName}: $color;
	}

	for $name, $weight in $fabEncoreText.textWeights {
		$weightVarName = '--font-weight-' + $name;
		{$weightVarName}: $weight;
	}

	for $sizeName, $values in $fabEncoreText.textSizes {
		$sizeVarName = '--font-size-' + $sizeName;
		$lineHeightVarName = '--line-height-' + $sizeName;
		{$sizeVarName}: unit($values.size, 'px');
		{$lineHeightVarName}: unit($values.line, 'px');
	}

	for $sizeName, $values in $fabEncoreText.headlineSizes {
		$sizeVarName = '--font-size-' + $sizeName;
		$lineHeightVarName = '--line-height-' + $sizeName;
		{$sizeVarName}: unit($values.size, 'px');
		{$lineHeightVarName}: unit($values.line, 'px');
	}

	for $heightName, $value in $fabEncoreSizes.heights {
		$heightVarName = '--height--' + $heightName;
		{$heightVarName}: $value;
	}

	for $widthName, $value in $fabEncoreSizes.widths {
		$widthVarName = '--width-' + $widthName;
		{$widthVarName}: $value;
	}
}
// @endCleanupEncore
