@import './styles/colors';
@import './styles/mixins';
@import './styles/text';
json('./constants.json');

$inputFocusRingWidth = 2px; // specified by designs for fab-Input
$modalSlideDistance = 20px; // specified by designs for Fabric Modal

// Modal Widths
$defaultModalWidth = 608px;
$mediumModalWidth = 800px;

// Sheet Width
$smallSheetWidth = 528px;
$mediumSheetWidth = 720px;
$largeSheetWidth = 912px;
$modalFooterTotalHeight = 90px;

modalContentReveal() {
	transition:
		opacity unit(MODAL_OPEN_DURATION, ms) ease,
		transform unit(MODAL_OPEN_DURATION, ms) ease;

	&--hidden {
		opacity: 0;
		transform: translateY(-$modalSlideDistance);
	}
}

.legacyModal__ {
	&portal {
		position: relative;
	}

	&overlay {
		align-items: center;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		left: 0;
		opacity: 0;
		position: fixed;
		right: 0;
		top: 0;
		transition: opacity unit(MODAL_OPEN_DURATION, ms) ease;

		// @startCleanup encore
		background-color: fab-color(gray1);
		// @endCleanup encore

		+encore() {
			background-color: rgba($fabEncoreColors.gray7, 0.9);

			&:has(.LockScreen) {
				background-color: var(--gray1);
			}
		}

		&.ReactModal__Overlay {
			&--after-open {
				opacity: 1;
			}

			&--before-close {
				opacity: 0;
				transition-duration: unit(MODAL_CLOSE_DURATION, ms);
			}
		}

		&:before {
			content: '';
			flex: 45; // specified by designs/guidelines for Fabric Modal
		}

		&:after {
			content: '';
			flex: 55; // specified by designs/guidelines for Fabric Modal
		}
	}

	/* -------------------------------------------------
	BASE MODAL STYLES: slides down by a specified amount
	when opening then down again by the same amount when
	closing. rests at 45% of page height (in other words
	5% above center)
	------------------------------------------------- */
	&modal {
		position: relative;
		transform: translateY(-$modalSlideDistance);
		transition: transform unit(MODAL_OPEN_DURATION, ms) ease;
		width: $defaultModalWidth;

		+encore() {
			background-color: fab-color('white');
			border-radius: var(--radius-400);
			padding: spacing(0.5);
		}

		&.ReactModal__Content {
			&--after-open {
				transform: none;
			}
			&--before-close {
				transform: translateY($modalSlideDistance);
				transition-duration: unit(MODAL_CLOSE_DURATION, ms);
			}
		}

		&Loader {
			align-items: center;
			bottom: 0;
			display: flex;
			justify-content: center;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}

		&--loading {
			+encore() {
				background-color: transparent;
			}
		}
	}

	&title {
		// startCleanup encore
		margin-bottom: 10px;
		// endCleanup encore
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		modalContentReveal();

		+encore() {
			// startCleanup encore
			margin: 0;
			// endCleanup encore
		}

		&--hasCloseButton {
			padding-right: 40px;
		}

		&CloseButton {
			grid-column-start: 2;
		}

		&Grid {
			display: grid;
			grid-template-columns: 1fr spacing(5);
			align-items: center;
			border-top-left-radius: var(--radius-400);
			border-top-right-radius: var(--radius-400);
			// startCleanup encore
			padding: 6px 20px 10px;
			// endCleanup encore
			// manual encore cleanup
			background-color: var(--gray05, white);
			// end manual cleanup
			modalContentReveal();

			+encore() {
				padding: 16px 20px;
			}
		}

		&Grid--noTitle {
			background-color: fab-color('white');
			// startCleanup encore
			padding-top: 20px;
			// endCleanup encore

			+encore() {
				padding: 16px 20px;
			}
		}

		&Grid--fullScreen {
			background-color: var(--white);
			position: absolute;
			right: 22px;
			top: 10px;
			z-index: 1;
		}
	}

	&closeButton {
		background: transparent;
		fill: fab-color(gray6);
		padding: 8px;
		position: absolute;
		right: -8px;
		top: 12px;
		fabricButtonReset();
		modalContentReveal();

		&:hover {
			fill: fab-color(gray8);
		}
	}

	&main {
		// @startCleanup encore
		background-color: fab-color('white');
		border-radius: 2px;
		box-shadow: 0 8px 14px 3px rgba(0, 0, 0, 0.05);

		// This is being used to reset items back to their original state because I can't just delete the above rules.
		// As such post encore these will become redundant
		+encore() {
			background-color: transparent;
			border-radius: var(--radius-0);
			box-shadow: none;
		}
		// @endCleanup encore

		modalContentReveal();
	}

	&header {
		display: block;
		padding: 32px 48px 0;
		position: relative; // for background attachments to .main (like confetti)
		// manual encore cleanup
		&--none {
			display: none; // hides the header on the signing modal, that above padding adds unnecessary white space.
		}
		// end manual cleanup

		&--filled {
			padding: 20px 32px 0;
		}

		&--stacked {
			margin-bottom: 8px;
			text-align: center;
		}

		&--text {
			align-items: center;
			border-bottom: 1px solid fab-color(gray3);
			display: flex;
			// @startCleanup encore
			padding: 24px 0 8px;
			margin: 0 32px 24px;
			// @endCleanup encore
			+encore() {
				padding: 20px 0 12px;
				margin: 0 20px 32px;
			}


			& ^[0]headerIcon {
				margin: 0 8px 0 0;
			}
		}
	}

	&headerBox {
		background-color: fab-color(gray1);
		border-bottom: 1px solid fab-color(gray4);
		border-radius: 2px 2px 0 0;
		padding: 12px 16px;
	}

	&headerIcon {
		fill: fab-color(gray8);
		margin-bottom: 20px;

		// @startCleanup encore
		align-items: center;
		display: flex;
		justify-content: center;
		// @endCleanup encore
		+encore() {
			display: block;
			background-color: var(--gray1);
			padding: 20px;
			border-radius: var(--radius-400);
		}

		&--theme {
			fill: fab-color(theme-base);
		}
		&--info {
			fill: var(--info, fab-color(info));
		}
		&--danger {
			fill: var(--danger, fab-color(danger));
		}
		&--attention {
			fill: var(--attention, fab-color(attention));
		}
		&--success {
			fill: var(--success, fab-color(success));
		}
		&--action {
			fill: var(--action, fab-color(action));
		}
	}

	&headerContent {
		margin: 0;
	}

	&content {
		box-sizing: border-box;
		max-height: 64vh;
		overflow: auto;
		position: relative; // for background attachments to .main (like confetti)
		// @startCleanup encore
		margin: 0 32px;
		padding: 32px 16px;
		// @endCleanup encore

		+encore() {
			margin: 0;
			padding: 32px 20px;
		}

		&:empty {
			padding-bottom: 24px;
		}

		&--noMaxHeight {
			max-height: none;
			overflow: visible;
		}

		&--noPadding {
			margin: 0 auto;
			// @startCleanup encore
			padding: 0 $inputFocusRingWidth; // prevents input focus ring from being cut off by overflow:auto (above)
			width: 'calc(100% - (64px - (-%s * 2)))' %$inputFocusRingWidth;
			// @endCleanup encore

			+encore() {
				padding: 0 $inputFocusRingWidth; // prevents input focus ring from being cut off by overflow:auto (above)
				width: 'calc(100% - %s)' %$inputFocusRingWidth;
			}
		}
	}

	&header ~ &content {
		padding-top: 0;
	}

	&footer {
		// @startCleanup encore
		border-top: 1px solid fab-color(gray4);
		background-color: fab-color(gray1);
		margin: 0 32px;
		padding: 24px 16px;
		// @endCleanup encore
		position: relative; // for background attachments to .main (like confetti)
		+encore() {
			background: initial;
			border-top: 1px solid var(--gray2);
			margin: 0 20px;
			padding: 20px 0;
		}
	}

	&actions {
		align-items: center;
		display: flex;
		// @startCleanup encore
		padding: 26px 48px 32px; // 2px divider + 24px = 26px
		// @endCleanup encore
		position: relative; // for pseudo-element and attachments to .main (like confetti)

		+encore() {
			justify-content: flex-end;
			padding: 16px 20px;
		}

		&Container {
			// manual encore cleanup
			background-color: var(--gray05, white);
			// end manual cleanup
			border-bottom-left-radius: var(--radius-400);
			border-bottom-right-radius: var(--radius-400);
			modalContentReveal();
		}

		// Using this to minimize the amount of refactoring needed in the  actual component
		& > button {
			+encore() {
				margin: 0 0 0 spacing(2);
			}
		}

		// @startCleanup encore
		&:before {
			fabricGradientLight();
			content: "";
			display: block;
			height: 2px;
			left: 32px;
			position: absolute;
			top: 0;
			width: calc(100% - 64px);

			+encore() {
				content: none;
			}
		}
		// @endCleanup encore
	}

	&actionNote {
		fab-color(gray7);
		margin-left: 12px;
		fab-text(small);
	}

	&additionalAction {
		margin-left: auto;

		+encore() {
			margin-left: unset;
			margin-right: auto;
		}
	}

	/* -------------------------------------------------
	SMALL (DEFAULT) TYPE
	------------------------------------------------- */
	&modal--small {
		display: block;
	}

	/* -------------------------------------------------
	ACTION CONFIRMATION TYPE
	------------------------------------------------- */
	&modal--aggressiveConfirmation {
		// @startCleanup encore
		& ^[0]header {
			margin-bottom: 28px;
			+encore() {
				margin-bottom: 0;
			}
		}
		// @endCleanup encore

		& ^[0]headerIcon {
			// @startCleanup encore
			fill: var(--danger, fab-color(danger));
			// @endCleanup encore
			+encore() {
				fill: var(--dangerDark);
			}
		}

		& ^[0]content {
			// @startCleanup encore
			fab-color(danger);
			background-color: fab-color(gray1);
			border-radius: 2px;
			margin: 0 48px 32px;
			padding: 20px 20px 24px;
			// @endCleanup encore
			text-align: center;
			fab-text(small);

			+encore() {
				fab-color: dangerDark;
				background-color: var(--gray05);
				border-radius: var(--radius-300);
				margin: spacing(2.5);
			}
		}

		& ^[0]note {
			margin-bottom: 12px;
		}

		& ^[0]delete {
			margin-bottom: 8px;
		}

		& ^[0]bold {
			fab-font-weight(bold);
		}
	}

	/* -------------------------------------------------
	MEDIUM TYPE
	------------------------------------------------- */
	&modal--medium {
		width: $mediumModalWidth;
	}

	/* -------------------------------------------------
	HEADLESS TYPE
	------------------------------------------------- */
	&modal--headless {
		& ^[0]closeButton{
			right: 12px;
		}

		& ^[0]main {
			padding-top: 48px;
		}

		& ^[0]header {
			padding-top: 0;
		}

		& ^[0]content {
			padding-top: 0;
		}
	}
}

/* -------------------------------------------------
FULL SCREEN TYPE
------------------------------------------------- */
modalFullscreen() {
	+encore() {
		display: flex;
		flex-direction: column
	}

	&:not(^[0]modal--loading) {
		height: 100%;
		width: 100%;
	}

	&:not(.legacyModal__modal--loading) {
		+encore() {
			height: calc(100% - 80px);
			width: calc(100% - 80px);
		}
	}

	& ^[0]closeButton {
		right: 24px;
		top: 24px;
		z-index: 1;
	}

	& ^[0]main {
		display: flex;
		flex-direction: column;
		// @startCleanup encore
		height: 100%;
		// @endCleanup encore
		padding: 0;
		+encore() {
			border-radius: var(--radius-200);
			height: 'calc(100% - %s)' % $modalFooterTotalHeight;
		// manual encore cleanup
			background-color: var(--gray05, #fff);
		// end manual cleanup

		// @startCleanup encore
			padding: 0; //duplicated because encore styles in a base rule caused specificity conflict
		// @endCleanup encore
		}
	}

	& ^[0]footer {
		+encore() {
			margin: 0 36px;
		}
	}

	& ^[0]header {
		display: flex;
		margin: 0;
		align-items: center;
		// @startCleanup encore
		background-color: fab-color(gray1);
		border-bottom: 1px solid fab-color(gray3);
		padding: 19px 32px;
		// @endCleanup encore

		&--stacked {
			+encore() {
				min-height: 42px;
			}
		}

		+encore() {
			// manual encore cleanup
			background-color: var(--white, white);
			// end manual cleanup
			border-bottom: 1px solid var(--gray2);
			border-top-left-radius: var(--radius-200);
			border-top-right-radius: var(--radius-200);
			padding: 20px 20px 20px 40px;
		}
	}

	& ^[0]headerContent {
		overflow: hidden;
		padding-right: 80px;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	& ^[0]headerIcon {
		margin: 0 16px 0 0;
	}

	& ^[0]content {
		flex: 1;
		margin: 0;
		max-height: none;

		+encore() {
			// manual encore cleanup
			background-color: var(--gray05, white);
			// end manual cleanup
		}

		&:not(^[0]content--noPadding) {
			padding: 32px 48px;

			+encore() {
				padding: 40px;
			}
		}

		&--noPadding {
			width: auto;
			padding: 0;
			// @startCleanup encore
			+encore() {
				// need this specificity for Encore, otherwise this wins: body[data-fabric-theme~="encore"] .legacyModal__content
				width: auto;
				padding: 0;
			}
			// @endCleanup encore
		}
	}

	& ^[0]actions {
		background-color: fab-color(gray1);
		border-top: 1px solid fab-color(gray3);
		display: flex;
		// @startCleanup encore
		padding: 24px 32px 32px;
		// @endCleanup encore
		+encore()
		{
			background-color: fab-color(white);
			justify-content: left;
			padding: 24px;
		}

		&:before {
			content: none;
		}
	}

	& ^[0]logo {
		fill: fab-color(gray7);
		margin-left: auto;
	}
}

.legacyModal__{
	&modal--fullScreen {
		modalFullscreen();
	}

	/* -------------------------------------------------
	SHEET TYPE: fades in and scales up when opening.
	fades out and scales down when closing.
	------------------------------------------------- */
	&overlay--sheet {
		// @startCleanup encore
		background-color: rgba(fab-color(gray2), 0.95);
		// @endCleanup encore
		transition: opacity unit(SHEET_OPEN_DURATION, ms) linear;

		+encore() {
			rgba($fabEncoreColors.gray4, 0.95);
		}
	}

	&modal--sheet {
		opacity: 0;
		transform: scale(0);
		transition: transform unit(SHEET_OPEN_DURATION, ms) ease 50ms;
		transition-property: opacity, transform;

		&.ReactModal__Content {
			+enore() {
				border-radius: 16px;
				background-color: white;
			}
			&--after-open {
				opacity: 1;
				transform: scale(1);
			}

			&--before-close {
				opacity: 0;
				transform: scale(0);
				transition-duration: unit(MODAL_CLOSE_DURATION, ms);
			}
		}

		& ^[0]title {
			transition: opacity unit(SHEET_OPEN_DURATION, ms) ease;

			+encore() {
				fab-color: theme-base;
			}

			&--hidden {
				opacity: 0;
				transform: none;
				transition: none;
			}
		}

		& ^[0]closeButton {
			top: 8px;
			transition: opacity unit(SHEET_OPEN_DURATION, ms) ease;

			&--hidden {
				opacity: 0;
				transform: none;
				transition: none;
			}
		}

		& ^[0]main {
			transition:
				transform unit(SHEET_OPEN_DURATION, ms) ease,
				opacity unit(SHEET_OPEN_DURATION, ms) ease;

			&--hidden {
				opacity: 0;
				transform: scale(0);
			}
		}
	}

	&modal--smallSheet {
		width: $smallSheetWidth;
	}

	&modal--mediumSheet {
		width: $mediumSheetWidth;
	}

	&modal--largeSheet {
		width: $largeSheetWidth;
	}

	/* -------------------------------------------------
	CUSTOM TYPE
	------------------------------------------------- */
	&modal--custom {
		width: auto;

		& ^[0]closeButton {
			right: 12px;
		}
	}
}

	/* -------------------------------------------------
	LOW RESOLUTION WINDOW HEIGHT
	------------------------------------------------- */
@media (max-height: 840px) {
	.legacyModal__ {
		&modal:not(&modal--fullScreen) {
			& ^[0]content:not(^[0]content--noMaxHeight) {
				max-height: 50vh;
			}
		}
	}
}

/*
MOBILE FRIENDLY STYLES
 */

$viewportPadding = 46px;

mobileHide() {
	&--mobileHide {
		display: none;
	}
}

mobileShow() {
	&--mobileShow {
		display: block;
	}
}

noMaxHeight() {
	^[0]modal:not(^[0]modal--fullScreen) {
		^[0]content:not(^[0]content--noMaxHeight){
			max-height: none;
		}
	}
}

fullWidthSheet() {
	width: 'calc(100% - -%s)' %$viewportPadding;
}

fullWidthHeader() {
	^[0]header {
		// @startCleanup encore
		padding: 19px 24px;
		// @endCleanup encore
		+encore() {
			padding: 20px 0 12px;
		}

		&Content {
			padding-right: 30px;
		}
	}

	^[0]closeButton {
		right: 16px;
	}
}

.legacyModal__ {
	&actions,
	&header {
		&--small,
		&--medium,
		&--smallSheet,
		&--mediumSheet,
		&--largeSheet {
			&--mobileShow {
				display: none;
			}
		}
	}
}


+encore() {
	.formSection--noMarginTop {
		margin-top: 0px;
	}
	.formSection--noPaddingBottom {
		padding-bottom: 0px;
	}
}


@media (max-width: $defaultModalWidth + $viewportPadding) {
	.legacyModal__ {
		&modal--small--mobileFriendly {
			modalFullscreen();
			fullWidthHeader();
		}

		noMaxHeight();

		&header,
		&title {
			&--small {
				mobileShow();
				mobileHide();
			}

			&Icon--small {
				mobileHide();
			}
		}
	}
}

@media (max-width: $mediumModalWidth + $viewportPadding) {
	.legacyModal__ {
		&modal--medium--mobileFriendly {
			modalFullscreen();
			fullWidthHeader();
		}

		noMaxHeight();

		&header,
		&title {
			&--medium {
				mobileShow();
				mobileHide();
			}

			&Icon--medium {
				mobileHide();
			}
		}
	}
}

@media (max-width: $smallSheetWidth + $viewportPadding) {
	.legacyModal__ {
		&modal--smallSheet--mobileFriendly {
			fullWidthSheet();

			^[0]actions {
				mobileShow();
				mobileHide();
			}

			&FullScreen {
				modalFullscreen();
				fullWidthHeader();

				^[0]header,
				^[0]title {
					&--smallSheet {
						mobileShow();
						mobileHide();
					}

					&Icon--smallSheet {
						mobileHide();
					}
				}
			}
		}
	}
}

@media (max-width: $mediumSheetWidth + $viewportPadding) {
	.legacyModal__ {
		&modal--mediumSheet--mobileFriendly {
			fullWidthSheet();

			&FullScreen {
				modalFullscreen();
				fullWidthHeader();

				^[0]header,
				^[0]title {
					&--mediumSheet {
						mobileShow();
						mobileHide();
					}

					&Icon--mediumSheet {
						mobileHide();
					}
				}
			}
		}
	}
}

@media (max-width: $largeSheetWidth + $viewportPadding){
	.legacyModal__ {
		&modal--largeSheet--mobileFriendly {
			fullWidthSheet();

			&FullScreen {
				modalFullscreen();
				fullWidthHeader();

				^[0]header,
				^[0]title {
					&--largeSheet {
						mobileShow();
						mobileHide();
					}

					&Icon--largeSheet {
						mobileHide();
					}
				}
			}
		}
	}
}
