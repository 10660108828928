lightVariantBlock = @block {
	background-color: #f9;
}

.FileCards {
	display: flex;
	flex-wrap: wrap;
	flex-shrink: 0;
	// Ben Eliason made me do this
	&:not(:empty) {
		margin-left: -5px;
		margin-right: -5px;
	}

	.FileCard {
		margin: 5px;
	}

	&--light .FileCard {
		fab-background-color: white;
		border: 1px solid var(--gray3);
	}
}

.FileCard {
	fab-background-color: gray2;
	display: flex;
	flex-direction: column;
	position: relative;
	border: none;
	border-radius: 16px;
	background-color: var(--gray2);
	height: 128px;
	width: var(--width-7);
	padding: 16px;
	box-sizing: border-box;
	fab-text: teenie;

	&--light {
		fab-background-color: gray1;
		background-color: var(--gray1);

		.FileCard__close {
			&:before,
			&:after {
				background-color: var(--gray1);
			}
		}

	}
	&--display {
		box-sizing: border-box;
		border: 1px solid (--gray3);
		box-shadow: 1px 1px 0 2px rgba(56, 49, 47, 0.03);
		fab-background-color: gray1;
		background-color: var(--gray1);

		//override focus border for a11y
		&:focus {
			box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05), 0 0 0 2px fab-color(theme-lightest35);	
			outline: 0;	
		}

		.FileCard__detail {
			max-width: 100%;
		}

		.FileCard__title {
			fab-color: info;
			fab-font-weight: semibold;
			color: var(--link);
			display: inline-block;
			font-weight: var(--font-weight-medium);
			line-height: var(--line-height-medium);
			font-size: var(--font-size-medium);
			max-height: var(--height--biggie);
			overflow: hidden;
			max-width: 100%;
			min-height: var(--height--medium);
			word-break: break-word;

			&:hover{
				cursor: pointer;
				text-decoration: underline;
			}
		}

		&:hover:not(.FileCard--disabled) {
			cursor: pointer;
			border: 1px solid fab-color(gray4);
			box-shadow: 1px 1px 0 2px rgba(56, 49, 47, 0.03);
			fab-background-color: gray2;
			background-color: var(--gray1);
			border-color: var(--theme-base-default);
			transition: border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, fill 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

			.FileCard__title {
				text-decoration: underline;
				transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, fill 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
				color: var(--theme-base-default);
			}
		}
	}

	&.FileCard--light {
		{lightVariantBlock};
		background-color: var(--gray1);

		.FileCard__close {
			&:before,
			&:after {
				background-color: var(--gray1);
			}
		}
	}

	&--disabled {
		.FileCard__title,
		.FileCard__info {
			color: inherit;

			&:hover {
				cursor: default;
				text-decoration: none;
			}
		}

		.FileCard__btn,
		.FileCard__image {
			opacity: 0.5;
		}

		.FileCard__info {
			opacity: 0.5;
		}

		&.FileCard--display {
			.FileCard__title {
				color: var(--link);
			}
		}
	}

	&__btn {
		fab-background-color: gray2;
		border-radius: 4px;
		font-size: var(--font-size-teenie);
		font-weight: var(--font-weight-medium);
		line-height: var(--line-height-teenie);
		padding: 4px 8px;
		margin: 0;
		overflow: ellipsis;
	}
		&--sign {
			fab-background-color: action;
			background-color: var(--warningDark);
			color: var(--white);
		}
		
		&--signed {
			fab-background-color: success;
			background-color: var(--successDark);
			color: var(--white);
		}

		&--optional {
			fab-background-color: gray7;
			background-color: var(--gray6);
			color: var(--white);
		}

	&__close {
		align-items: center;
		border: none;
		border-radius: 100%;
		cursor: pointer;
		display: flex;
		fab-fill: white;
		height: 12px;
		justify-content: center;
		position: absolute;
		right: 15px;
		fab-background-color: gray6;
		top: 17px;
		width: 12px;
		background-color: var(--gray6);

		&:before,
		&:after {
			width: 1px;
			fab-background-color: white;
			background-color: var(--gray2);
			height: 6px;
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
		}

		&:before {
			transform: translate3d(-50%, -50%, 0) rotate(45deg);
		}

		&:after {
			transform: translate3d(-50%, -50%, 0) rotate(-45deg);
		}

		&:hover {
			fab-background-color: gray7;
			cursor: pointer;
			background-color: var(--gray9);
			transition: background-color 200ms ease 0ms, fill 200ms ease 0ms;
		}
	}

	&__detail {
		flex: 1;
		max-width: calc(100% - 12px);
	}

	&__info,
	&__status {
		color: var(--gray7);
		font-size: var(--font-size-small);
	}

	&__info {
		align-items: center;
		display: flex;
		line-height: 1;
		fab-color: gray7;
		fab-text: teenie;
		height: auto;
		color: var(--gray7);
		font-size: var(--font-size-small);

		.FileCard__image {
			margin: 0 6px 0 0;
		}
	}

	&__icon {
		line-height: 0;
		margin-right: 8px;
	}

	&__status {
		display: inline-block;
		height: 17px;
		overflow: hidden;
	}

	&__title {
		display: inline-block;
		overflow: hidden;
		width: 100%;
		word-wrap: break-word;
		height: auto;
		font-size: var(--font-size-medium);
		line-height: var(--line-height-medium);
		white-space: normal;
		font-weight: var(--font-weight-medium);
		max-height: var(--height--biggie);
		word-break: break-word;
	}
	
	.bhrToggle {
		position: absolute;
		bottom: 17px;
		right: 15px;
		width: 32px;
		height: 16px;
		user-select: none;
		cursor: pointer;
	}

	.bhrToggle__label {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: var(--gray4);
		transition: transform 0.2s;
		border-radius: 34px;
	}

	/* Hide the default HTML checkbox */
	.bhrToggle__input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.bhrToggle__input:checked + .bhrToggle__label {
		background-color: var(--fabric-theme-base);
	}

	.bhrToggle__handle {
		position: absolute;
		height: 12px;
		width: 12px;
		left: 2px;
		bottom: 2px;
		background-color: white;
		transition: transform 0.2s;
		border-radius: 50%;
	}

	.bhrToggle__icon {
		display: block;
		position: absolute;
		color: white;
		font-size: 16px;
		left: 20px;
		top: 50%;
		transform: translateY(-35%);
		transition: left 0.2s;
		pointer-events: none;
	}

	.bhrToggle__input:checked + .bhrToggle__label + .bhrToggle__handle + .bhrToggle__icon {
			left: 4px;
	}

	.bhrToggle__input:checked + .bhrToggle__label + .bhrToggle__handle {
		transform: translateX(16px);
	}
}
