$layout = json('@bamboohr/fabric/dist/definitions/json/layout.json', { hash: true });

// .ba-banner
.ba-banner {
	background-color: #7d;
	border: none; // Override only
	box-sizing: border-box;
	color: #e6;
	font-family: BhrHeaderFontStack;
	font-size: 14px;
	font-weight: 500;
	height: 35px;
	line-height: 16px;
	min-width: 993px;
	padding: 8px 0;
	text-align: left;
	width: 100%;

	fab-background-color: info;
	font-family: unset;
	fab-color: white;
	fab-font-size: large;
	fab-line-height: small;
	fab-font-weight: regular;
	height: 44px;
	padding: 12px ($layout.pageWidths.buffer)px;
	min-width: ($layout.pageWidths.min)px;
	display: flex;

	.page {
		display: flex;
		align-items: center;
	}
	
	&-container {
		display: flex;
		align-items: center;
	}

	// .ba-banner--warning
	&--warning {
		fab-background-color: attention;
	}
	// .ba-banner--danger
	&--danger {
		fab-background-color: danger;
	}
	// .ba-banner .bold
	.bold {
		fab-font-weight: semibold;
	}
	// .ba-banner-text
	&-text {
		fab-line-height: small;
		margin: 0;
		display: inline-block;
		line-height: 20px;

		&--lrg {
			font-size: 16px;
		}
	}
	// .ba-banner-icon
	&-icon {
		position: relative;
		margin: 0 11px 0 0;
		fab-fill: white;
	}
	// .ba-banner-close
	&-close {
		box-sizing: border-box;
		border: 1px solid rgba(0, 0, 0, 0);
		display: inline-block;
		float: right;
		height: 19px;
		position: absolute;
		text-align: center;
		top: calc(50% - 3px);
		transform: translateY(-50%);
		width: 19px;
		&-btn {
			flex: 1;
			text-align: right;
		}
		// .ba-banner-close a
		a {
			width: 100%;
			height: 100%;
			display: inline-block;
			vertical-align: top;
			background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2211%22%20height%3D%2211%22%20viewBox%3D%220%200%2011%2011%22%3E%0A%09%3Cpath%20fill%3D%22%23333%22%20d%3D%22M7.1%205.5L10.6%202C11.1%201.5%2011.1%200.7%2010.7%200.3%2010.3-0.1%209.5-0.1%209%200.4L5.5%203.9%202%200.4C1.5-0.1%200.7-0.1%200.3%200.3%20-0.1%200.7-0.1%201.5%200.4%202L3.9%205.5%200.4%209C-0.1%209.5-0.1%2010.3%200.3%2010.7%200.7%2011.1%201.5%2011.1%202%2010.6L5.5%207.1%209%2010.6C9.5%2011.1%2010.3%2011.1%2010.7%2010.7%2011.1%2010.3%2011.1%209.5%2010.6%209L7.1%205.5%22/%3E%0A%3C/svg%3E');
			background-position: center center;
			background-repeat: no-repeat;
			opacity: 0.6;
		}
		// .ba-banner-close:hover
		&:hover {
			background: #fff7db;
			border: 1px solid #aea070;
			border-radius: 2px;
			cursor: pointer;
			// .ba-banner-close:hover a
			a {
				opacity: 1;
			}
		}
	}
}
// .ba-banner--alert
.ba-banner--alert {
	background: #ffefb9;
	border-bottom: solid rgba(196, 196, 196, 0.6) 1px;
	color: #3c;

	fab-background-color: info;
	fab-color: white;
	border-bottom: none;

	a {
		fab-color: white;
		fab-font-weight: bold;

		&:hover {
			fab-color: white;
		}
	}
	// .ba-banner--alert .bhr-banner-icon
	.ba-banner-icon {
		fab-fill: white;
		top: 0;
	}
	// .ba-banner--alert p
	p {
		width: 95%;
	}
}
// .ba-banner--trial
.ba-banner--trial {
	fab-background-color: gray10;

	.ba-banner-icon {
		float: none;
		top: 0;
	}

	.ba-banner-trialDays {
		float: left;
		margin: 0 5px;
		position: relative;
		top: -2px;
	}
	// .ba-banner--trial span.day
	span.day {
		background: url("https://staticfe.bamboohr.com/resources/images/icon-day-tag.png") no-repeat 0 0;
		width: 9px;
		height: 18px;
		margin-left: -2px;
		display: inline-block;
		color: #0;
		position: relative;
		font-family: helvetica;
		font-weight: bold;
		font-size: 16px;
		padding: 5px 3px 0;
		// .ba-banner--trial  span.day:last-of-type
		&:last-of-type {
			margin: 0 2px 0 -1px;
		}
	}
	.ba-banner-trial-clear {
		fab-color: white;
	}
}
.ba-banner-trial-sample-data {
	float: none;
	display: flex;
	align-items: center;

	&.fl-right {
		position: absolute;
		right: 0;
	}
	.ba-banner-icon {
		margin-right: 4px;
	}
}

// .ba-banner--newHirePacket
.ba-banner--newHirePacket {
	border-bottom: none;
	fab-color: white;

	// .ba-banner--newHirePacket .ba-banner-icon
	.ba-banner-icon {
		float: none;
		top: 0;
	}
	// .ba-banner--newHirePacket .ba-banner-container
	.ba-banner-container {
		// .ba-banner--newHirePacket .ba-banner-container:after
		display: flex;
	}
	// .ba-banner--newHirePacket .completeIt
	.completeIt {
		fab-color: white;
		fab-font-weight: semibold;
		margin-left: 0;
	}
}
// .ba-banner--cancellationStatus
.ba-banner--cancellationStatus {
	.white {
		fab-color: white;
	}
	// .ba-banner--cancellationStatus .bold
	.bold {
		fab-color: white;
		fab-font-weight: bold;
		margin-left: 0;
	}
}
// .ba-banner--acceptTos
.ba-banner--acceptTos {
	display: inline-block;
	position: absolute;
	right: 0;
	top: 0;
	right: 39px;

	.ba-banner-close {
		margin-left: 8px;
		top: 8px;
		vertical-align: top;
		background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2211%22%20height%3D%2211%22%20viewBox%3D%220%200%2011%2011%22%3E%0A%09%3Cpath%20fill%3D%22%23333%22%20d%3D%22M7.1%205.5L10.6%202C11.1%201.5%2011.1%200.7%2010.7%200.3%2010.3-0.1%209.5-0.1%209%200.4L5.5%203.9%202%200.4C1.5-0.1%200.7-0.1%200.3%200.3%20-0.1%200.7-0.1%201.5%200.4%202L3.9%205.5%200.4%209C-0.1%209.5-0.1%2010.3%200.3%2010.7%200.7%2011.1%201.5%2011.1%202%2010.6L5.5%207.1%209%2010.6C9.5%2011.1%2010.3%2011.1%2010.7%2010.7%2011.1%2010.3%2011.1%209.5%2010.6%209L7.1%205.5%22/%3E%0A%3C/svg%3E');
		background-position: center center;
		background-repeat: no-repeat;
		opacity: 0.6;
		position: relative;
	}
}
// .ba-banner--billing
.ba-banner--billing {
	//.ba-banner--billing .ba-banner-link
	.ba-banner-link {
		fab-color: white;
		fab-font-weight: bold;
		text-decoration: underline;
	}
}
