@require "chosen.lib";

.chzn {
	$chzn = selector();

	&-container {
		color: #000;
		font-size: 15px;
		text-align:left;
		font-weight:normal;
		margin: 0;
		vertical-align:top;

		&-single {
			&-nosearch {
				{$chzn} {
					&-results {
						margin-top: -2px;
					}

					&-drop {
						&up {
							{$chzn} {
								&-results {
									margin-top: 0;
									border-top: 0;
									border-radius: 2px 2px 0 0;
								}
							}
						}
					}
				}
			}

			&.small {
				{$chzn} {
					&-single {
						height: 26px;
						line-height: 26px;

						span {
							font-size: 13px;
							display: block;
							margin-top: -1px;
						}

						abbr {
							top: 6px;
						}
					}

					&-search {
						input {
							font-size: 13px;
						}
					}
				}
			}

			&.medium {
				{$chzn} {
					&-single {
						height: 26px;
						line-height: 22px;

						div {
							margin-top:-2px;
						}
					}

					&-search {
						input {
							font-size: 13px;
						}
					}
				}
			}

			{$chzn} {
				&-single {
					height: 28px;

					&:not(.chosen-default) {
						span {
							display: inline-block;
							width: 100%;
							margin-right: 0;
							padding-right: 22px;
							background-color: transparent !important;
							box-sizing: border-box;

							&{$chzn}-allow-deselect {
								padding-right: 33px;
							}
						}
					}
				}

				&-search {
					input {
						background: url("https://staticfe.bamboohr.com/resources/images/chosen-sprite-bhr.png") no-repeat -38px -22px right, linear-gradient(top, #f8, #fff);
						border: 1px solid #91;
						border-radius: 3px;
						height: 22px;
						width: 100%;
						font-family: "Source Sans Pro";
						font-size: 14px;
						color: #000;
						padding: 3px 7px;
						box-shadow:inset 0 1px 2px 0 rgba(0, 0, 0, 0.11);
					}
				}

				span {
					&{$chzn} {
						&-allow-deselect {
							margin-right:30px; // allow room for x icon
						}
					}
				}
			}
			&:not(.small) {
				{$chzn}-single {
					span {
						display: inline-block;
						margin-top: 1px;
					}
					b {
						background-position: 0 1px;
					}
				}
			}
		}

		&.small {
			&,
			{$chzn} {
				&-results {
					font-size: 13px;
				}
			}

			{$chzn} {
				&-results {
					.group {
						&-result {
							font-size: 12px;
						}
					}

					li {
						padding: 3px 6px;
					}
				}
			}
		}

		&.medium {
			&,
			{$chzn} {
				&-results {
					font-size: 13px;
				}
			}

			{$chzn} {
				&-results {
					.group {
						&-result {
							font-size: 12px;
						}
					}
					li {
						padding: 3px 6px;
					}
				}
			}
		}

		& {$chzn} {
			&-results {
				font-size: 14px;
				border-top: solid 1px #d4;
				margin: 0;
				padding: 0;

				li {
					&.borderBottom {
						border-bottom: solid 1px #ea;
					}

					&.group {
						&-option {
							padding-left: 15px;
						}
						&-result {
							padding-top: 0;
							padding-bottom: 2px;
							margin-top: 2px;

							&:first-of-type {
								display: none !important;
							}
						}
					}
				}

				&-sticky {
					li {
						line-height: 17px;

						&:not(:last-of-type) {
							border-bottom: solid #d4 1px;
						}
					}
				}
			}
		}
		&{$chzn} {
			&-with-drop {
				&up {
					{$chzn} {
						&-dropup {
							border-top: solid #78 1px;
							border-radius: 3px 3px 0 0;
							border-bottom: 0;
							box-shadow: none;
						}
					}

					> a {
						border-top-color: #ccc;
						border-radius: 0 0 3px 3px;
					}
				}
			}

			&IconSelect {
				{$chzn} {
					&-single {
						padding: 1px 0 1px 6px;
					}
				}

				&--files {
					{$chzn} {
						&-single {
							> span {
								&:before {
									content: "";
									display: inline-block;
									margin-top: -3px;
									margin-right: 3px;
									width: 26px;
									height: 19px;
									vertical-align: middle;
									background: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/sprite-files-icons.png") -1px -181px;
									background-color: red;
								}
							}
						}
					}
					// .chzn-container.chznIconSelect--files.folder .chzn-single > span:before
					&.folder {$chzn}-single > span:before {
						background-position: -1px 0;
					}
					// .chzn-container.chznIconSelect--files.signature .chzn-single > span:before
					&.signature {$chzn}-single > span:before {
						background-position: -36px -30px;
					}
				}
			}
		}

		{$chzn} {
			&-drop {
				height: 0;
				overflow: hidden;
				border: solid #949494 1px;
				border-top: 0;
				z-index: 1010;
			}
			&-single {
				background: $bhrColors.gray1;
				box-shadow: inset 0 1px 1px 0 rgba(133, 133, 133, 0.2);
				border: 1px solid #af;
				border-radius: 2px;
				color: #000;
				padding-left: 7px;
				box-sizing: border-box;

				&-with-drop {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
					background: #ff;
					border-color: #949494;
					box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.24);
				}
			}

			&-search {
				margin: -3px 0 1px;
				background: #ff;
			}

			&-results {
				.disabledOption {
					color: #ab;
					cursor: default;
				}
			}
		}

		&.optgroup-as-separator {

			& li.group-result {
				height: 0;
				padding: 0;
			}

			& li.group-option {
				padding-left: 6px;
			}
		}
	}

	&-single {
		&-with-drop {
			+ {$chzn}-drop {
				height: auto;
				overflow: visible;
				box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .24);
				border-radius: 0 0 2px 2px;
			}
		}
	}

	&-results {
		.group {
			&-result {
				color: #68;
				font-size: 13px;
				border-top: solid 1px #ea;
			}
		}
	}

	&-drop {
		&up {
			> {$chzn} {
				&-search {
					margin-top: 1px;
					border-radius: 3px 3px 0 0;
				}
			}
		}
	}
}

.error {$chzn}-container-single {$chzn}-single,
.error {$chzn}-container {$chzn}-drop{
	border-color: #cf6240;
}

{$chzn}-container {$chzn}-results .highlighted,
.ui-widget.ui-multiselect-single.ui-widget-content .ui-multiselect-checkboxes li.highlighted .ui-state-active,
.ui-widget.ui-multiselect-single.ui-widget-content .ui-multiselect-checkboxes li.highlighted label {
	font-weight: 600;
}

// Mozilla Autofill
:-moz-autofill + .chzn-container .chzn-single {
	background-color: #fffcca;
}
// Chrome autofill
:-webkit-autofill + .chzn-container .chzn-single {
	background-color: #faffc3;
}
