@require "ui.core.lib";
@require "ui.theme.lib";
@require "ui.dialog.lib";
@require "jquery.autocomplete.lib";

.ui {
	// .ui-widget
	&-widget {
		// .ui-widget.ui-dialog
		&.ui-dialog {
			background: rgba(0, 0, 0, 0.3) !important;
			min-width: 260px;
			position: relative;
			padding: 0;
			border: 5px solid transparent;
			border-radius: 3px;
			box-shadow: 0 0 150px rgba(255, 255, 255, 0.3);
			// .ui-widget.ui-dialog.stacked-dialog
			&.stacked-dialog {
				border-top-left-radius: 0 !important;
				border-top-right-radius: 0 !important;
				border-top: 0 !important;
				background: rgba(0, 0, 0, 0.5) !important;
				// .ui-widget.ui-dialog.stacked-dialog + .ui-widgetoverlay
				& + .ui-widgetoverlay {
					opacity: 1;
				}
				// .ui-widget.ui-dialog.stacked-dialog .ui-dialog
				.ui-dialog {
					&-titlebar {
						border: none;
						border-radius: 0;
						border-bottom: solid #c7 1px;
						color: #22;
						font-weight: 400;
						background: #f0;
						box-shadow: 0 1px 0 1px #00;
						margin: 0 1px;
						padding: 6px 9px;

						.ui-dialog-title {
							font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
						}

						&-close {
							span {
								background: url('https://staticfe.bamboohr.com/resources/images/x-dark.png') center center no-repeat transparent !important;
							}

							&:hover {
								border solid 1px #a2;
								background-color: #d1 !important;
								span {
									opacity: 1;
								}
							}
						}
					}

					&-content {
						border-top: 0;
						margin: 0 -1px;
					}

					&-buttonpane {
						padding: 10px 20px;
					}
				}
			}

			.ui-dialog-content {
				@media only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 192dpi) {
					// fix weird scrollbar issue on retina screens
					position: initial;
				}
			}
		}

		//.ui-widget.ui-dialog select multiple=multiple
		& .BhrForms select[multiple=multiple] {
			max-width: 334px;
		}

		// .ui-widget.ui-dialog.ui-multiselect
		&.ui-multiselect {
			font-size: 15px;
			text-align: left;
			font-weight: normal;
			margin: 0;
			vertical-align: top;
			color: #222;
			background: linear-gradient(to bottom, #fff 0, #f8 100%);
			border-color: #78;
			box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.11);
			box-sizing: border-box;
			border: 1px solid #af;
			border-radius: 3px;
			padding: 0;
			padding-left: 7px;
			height: 28px;
			line-height: normal;

			//.ui-widget.ui-dialog.ui-multiselect .ui-multiselect-value
			.ui-multiselect-value {
				display: inline-block;
				max-width: calc(100% - 20px);
			}

			// .ui-widget.ui-dialog.ui-multiselect-menu
			&-menu {
				border-radius: 3px;
				background: #fff;
				border: 1px solid #aa;
				box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
				padding: 0;
				max-height: 238px;
				overflow-y: auto;
				// .ui-widget.ui-dialog.ui-multiselect-menu:not(.flipped)
				&:not(.flipped) {
					border-top: 0;
					border-top-left-radius: 0;
					border-top-right-radius: 0;
				}
				// .ui-widget.ui-dialog.ui-multiselect-menu.flipped
				&.flipped {
					border-bottom: 0;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
				}
				// .ui-widget.ui-dialog.ui-multiselect-menu.ui-widget-content
				&.ui-widget-content {
					// .ui-widget.ui-dialog.ui-multiselect-menu.ui-widget-content .ui-state-active
					.ui-state-active {
						color: #222;
						font-weight: normal;
						outline: none;
						box-shadow: none;
					}
				}
				// .ui-widget.ui-dialog.ui-multiselect-menu li
				li {
					padding: 0 3px;
					overflow: hidden;

					&:first-child:not(.ui-multiselect-single-select) {
						padding-top: 3px;
					}

					&:last-of-type {
						padding-bottom: 3px;
					}
				}
				// .ui-widget.ui-dialog.ui-multiselect-menu label
				label {
					padding: 5px 6px;
					border: 0;
					border-radius: 0;
					display: inline-block;
					width: 100%;
					line-height: 15px;
					box-sizing: border-box;
					// .ui-widget.ui-dialog.ui-multiselect-menu label > input
					> input {
						// .ui-widget.ui-dialog.ui-multiselect-menu label > input[type="checkbox"],
						// .ui-widget.ui-dialog.ui-multiselect-menu label > input[type="radio"]
						&[type="checkbox"],
						&[type="radio"] {
							&.disabled {
								+ span {
									color: #9b;
								}
							}
						}
						// .ui-widget.ui-dialog.ui-multiselect-menu label > input[type="checkbox"]
						&[type="checkbox"] {

							&:checked {
								+ span {
									&:before {
										background-position: -1px -17px;
									}
								}
							}

							&.disabled {
								+span {
									&:before {
										background-color: #fff;
										background-image: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/checks-radios-disabled.png");
										cursor: default;
									}
								}
							}

							+ span {
								display: inline-block;
								padding-left: 20px;

								&:before {
									content: "";
									background-image: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/checks-radios.png");
									background-repeat: no-repeat;
									background-position: -1px -1px;
									height: 15px;
									width: 15px;
									display: inline-block;
									margin: 2px 5px -2px -20px;
									cursor: pointer;
								}
							}
						}

						&[type="radio"] {
							+ span {
								&:before {
									background-position: -1px -33px;
								}
							}
						}
					}
				}

				/* Hybred single/multi select dropdown - single option styles */
				/* .ui-widget.ui-multiselect-menu .ui-multiselect-single-select */
				.ui-multiselect-single-select {

					label {
						cursor: pointer;
					}

					input[type="checkbox"] + span {
						padding-left: 0;

						&:before {
							display: none;
						}
					}

					&:hover,
					&.highlighted {
						> label {
							color: #fff;
						}
					}

					+ :not(.ui-multiselect-single-select) {
						border-top: solid #ea 1px;
					}
				}
			}

			&.ui-widget {
				cursor: pointer;
			}

			&.ui-state-active {
				border-color: #aa;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				border-bottom: 1px solid #ccc;
				background: linear-gradient(to bottom, #f8 0, #fff 100%);
			}

			.ui-icon {
				&.ui-icon-triangle-1-s {
					background: url("https://staticfe.bamboohr.com/resources/images/chosen-sprite-bhr.png") no-repeat 0 -3px;
					display: block;
					width: 18px;
					height: 18px;
					margin-top: 4px;
				}
			}

			> .ui-icon {
				+ span {
					line-height: 25px;
					-webkit-font-smoothing: antialiased;
				}
			}
		}

		&-overlay {
			width: 100% !important;
			height: 100% !important;
			position: fixed;
			opacity: 0.72;
			background-color: #000;
			background-image: none;
		}

		&-content {
			a {
				color: #1753B3;
			}

			.privacyLink {
				color: #a8;
				float: right;
				padding-top: 3px;

				&:hover {
					color: #0072C6;
				}
			}
		}

		p {
			font-size: 14px;
		}
	}

	&-dialog {
		position: absolute !important;
		clear: both !important;
		text-align: left !important;
		line-height: 1.4em !important;
		border: 4px solid transparent !important;
		border-radius: 5px !important;
		box-shadow: 0 0 75px rgba(255,255,255,0.3) !important;

		& &-title {
			font-family: BhrHeaderFontStack;
			font-weight: 600;
			margin: 0 !important;
			font-size: 15px;
			padding-left: 3px;

			&bar {
				padding: 8px 9px 7px;
				background: #3a;
				border: 1px solid #000;
				border-bottom: none;
				padding-right: 30px !important;
				border-radius: 3px 3px 0 0;
				margin: 0;
				color: #fff;

				&.hidden + .ui-dialog-content {
					border-radius: 3px 3px 0 0;
				}

				&-close {
					right: 5px;
					height: 18px;
					width: 18px;
					border: 1px solid transparent;
					border-radius: 2px;
					padding: 2px;
					margin: -3px 0 0;
					top: 8px;

					&:hover {
						border: 1px solid #05;
						background-color: #29 !important;
						padding: 2px;

						span {
							opacity: 1;
						}
					}

					span {
						background: url("https://staticfe.bamboohr.com/resources/images/x.png") center center no-repeat transparent !important;
						opacity: 0.6;
						border-radius: 0 !important;
						border: none !important;
						background-image: none;
					}
				}
			}
		}

		& &-content {
			padding: 20px 20px 25px;
			background: #ff;
			border: none;
			margin: 0 0 1px;
			border: solid #00 1px;
			border-bottom: none;
			/*box-shadow: inset 0px -1px 0px 0px #c7c7c7;*/
			overflow-y: auto;
		}

		& &-buttonpane {
			background: #f0;
			border: 1px solid #000;
			border-top: none;
			border-radius: 0 0 3px 3px;
			padding: 10px 20px;
			margin: 0;
			box-shadow: 0 -2px 0 -1px #c7c7c7;

			.ui-dialog-buttonset {
				display: inline-block;
				float: none;
				margin-right: 16px;
			}

			.btn {
				margin: 0 10px 0 0;
			}

			button {
				&.cancel {
					background: #fff;
					border-color: #999;
					color: #777;
					font-weight: 600;
				}
			}

			span {
				&.cancel-option {
					display: inline-block;
					vertical-align: middle;
					margin-left: 3px;

					a {
						&.btn {
							color: inherit;
						}
					}
				}
			}
		}

		&.displaynone {
			display: none !important;
		}

		table {
			width: auto;
		}

		form {
			table {
				th {
					width: auto;
				}
			}
		}

		p {
			text-align: left;
			padding: 0 0 8px 0;
			margin: 0;

			&.alert {
				padding: 0 0 2px 14px;
				margin: 0;
			}
		}

		ul {
			margin: 0;
			padding: 0;

			&.message {
				li {
					padding: 10px 20px 17px 48px;
					margin: 0 0 10px 0;
				}
			}

			li {
				text-align: left;
				padding: 0 0 8px;
				margin: 0;
			}
		}

		.ui-icon-alert {
			position: absolute;
			margin-left: -5px;
			margin-top: 1px;
		}
	}

	&-datepicker {
		&-trigger {
			min-height: 26px;
		}

		&-multi &-buttonpane {
			button {
				background: #e5;
				border: 1px solid #ccc;
				font-size: 12px;
				color: #444;
				padding: 3px 5px;
				border-radius: 3px;
			}
		}
	}

	&-button {
		& &-text {
			line-height: 23.5px;
		}
	}

	&-state {
		&-active {
			&,
			/.ui-widget-content & {
				color: #fff;
				font-weight: normal;
				outline: none;
				box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
			}
		}

		&-disabled {
			&,
			/.ui-widget-content &,
			/.ui-widget-header & {
				opacity: 0.5;
			}
		}

		&-default {
			&.btn {
				font-weight: 600;
				border-color: #af;
			}
		}
	}
}

#ui-datepicker-div {
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.24);
	border-radius: 2px;
	background: #fff !important;
	margin: 1px 0;
	padding: 0;
	width: 18em;

	&.ui-datepicker {
		.ui-datepicker {
			&-month,
			&-year {
				width: auto;
			}
		}

		table {
			thead {
				tr {
					background-color: #f2;

					th {
						font-size: 12px;
						color: #88;
						font-weight: 600;
						padding: 0.3em;
					}
				}
			}

			td {
				a {
					font-size: 15px;
					color: #222;
					border-radius: 6px;
				}
			}
		}
	}

	.ui-datepicker {
		&-title {
			.chzn-container,
			select {
				height: 28px;
				padding: 0;
				font-family: BhrHeaderFontStack;
				font-weight: 500;
				font-size: 16px;
				max-width: 72px;
				min-width: 20px;
				margin: 1px 5px;
				border-color: #be;
				border-radius: 2px;
				background-color: #f2;
			}

			.chzn-container {
				&^[-1..-1]-single-nosearch {
					.chzn-single {
						background: none;
						border: 1px solid #be;
						border-radius: 3px;
						box-shadow: none;
						padding-left: 4px;

						&:hover {
							background: #ff;
							border-color: #94;
						}

						&.chzn-single-with-drop {
							background: #ff;
							border-color: #94;
							border-bottom-left-radius: 0;
							border-bottom-right-radius: 0;
						}
					}

					&.chzn-container-active {
						.chzn-results {
							border-top: solid #97 1px;
						}
					}

					.chzn-results {
						li {
							padding: 8px 6px 5px;
						}
					}
				}
			}

			select {
				padding: 3px 25px 0 9px;
				appearance: none;
			}
		}

		&-prev,
		&-next {
			border: 1px solid #be;
			border-radius: 2px;
			background: #f0;
			height: 26px;
			margin-top: 1px;
			top: auto;

			&-hover {
				cursor: pointer;
				overflow: visible;
				top: auto;
				background: #ff;
				border-color: #94;
			}
		}

		&-next {
			&,
			&-hover {
				right: 10px;
			}
		}

		&-prev {
			&,
			&-hover {
				left: 10px;
			}
		}

		&-calendar {
			.ui-datepicker-today {
				&:not(.ui-datepicker-current-day) {
					.ui-state {
						&-default {
							background: #ff;
							font-weight: 600;
							border: 1px solid #cf;
						}
					}
				}
			}

			.ui-state {
				&-default {
					padding: 5px;
					background: none;
					text-align: center;
					border: 1px solid #fff;
					border-radius: 0;
				}

				&-active {
					box-shadow: none;
					color: #fff;
					font-weight: 600;
				}

				&-hover {
					background: #ff;
					border-color: #fff;
				}

				&-disabled .ui-state-default {
					color: #AAA;
				}
			}
		}

		&-header {
			background: #f2;
			border-radius: 2px 2px 0 0;
			padding: 10px 0 6px;
			border: 0;
			min-height: 28px;
		}
	}

	.ui-icon {
		&^[-1..-1]-circle-triangle {
			&-e,
			&-w {
				background: none;
				border: solid transparent 5px;
				height: 0;
				width: 0;
				margin-top: -5px;
				margin-left: -8px;
			}

			&-e {
				border-left-color: #77;
				margin-left: -1px;
			}

			&-w {
				border-right-color: #77;
			}
		}
	}

	.chzn-container {
		&-single {
			.chzn-single {
				span {
					margin-top: 2px;
					margin-left: 5px;
				}
				div {
					b {
						background: url('https://staticfe.bamboohr.com/resources/images/chosen-sprite-bhr.png') no-repeat 0 0;
					}
				}
			}
		}
	}
}

.event {
	&Disable {
		pointer-events: none;
		user-select: none;
	}

	&Enable {
		pointer-events: all;
		user-select: all;
	}
}

.multipleSelect {
	&--seperator {
		border-top: 1px solid #df;
		pointer-events: none;
		margin-top: 3px;

		label {
			font-size: 12px;
			padding-bottom: 0;
			color: #999;

			span {
				&:before {
					display: none !important;
				}
			}
		}
	}
}

.noclick {
	pointer-events: none;
}

{featureToggleOn("jade")} {
	#ui-datepicker-div {
		border-color: fab-color(theme-base);
	}
}
