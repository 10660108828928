ba-option {
	display: inline-block;

	&:empty,
	&[value=""],
	ba-option-group {
		display: none !important;
	}
}

ba-option-group {
	display: block;
}