/*-------------------------------------------------
CSS RESET via http://meyerweb.com/eric/tools/css/reset/
-------------------------------------------------*/
@css {
	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, font, img, ins, kbd, q, s, samp,
	small, strike, strong, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td {
		margin: 0;
		padding: 0;
		border: 0;
		outline: 0;
		vertical-align: baseline;
	}
	html{font-size: 15px}

	div{
		vertical-align: middle;
	}
	body { line-height: 1;}
	ol, ul {list-style: none;}
	blockquote, q {quotes: none;}
	blockquote:before, blockquote:after,
	q:before, q:after {content: '';content: none;}
	/* remember to define focus styles! */
	:focus {	outline: 0;}
	/* remember to highlight inserts somehow! */
	ins { text-decoration: none;}
	del {text-decoration: line-through;}
	/* tables still need 'cellspacing="0"' in the markup */
	table {	border-collapse: collapse;border-spacing: 0;}
}
