/*------------------------------------*\
Global Stylesheet

This is where DRY stuff lives.
\*------------------------------------*/

/*
** global fonts and text styles
*/


html {
	overflow-y: scroll;
	
	@media screen {
		/*
		* Some places that use these styles don't use the main layout so we don't want to alter their scroll behavior.
		* We also can't use the Encore mixin here because that is only available to the body element and its children.
		*/ 
		&:has(> body[data-fabric-theme='encore'] > .ContentGridContainer) {
			overflow-y: auto;
		}
	}
	
	@media print {
		overflow-y: scroll;
	}

}

html,
body {
	height: 100%;
	width: 100%;
}

body {
	background: #fff;
	color: #222;
	font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
	font-size: 14px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0 auto;
	text-align: center;

	&.lockBlur {
		> div:not(.SimpleModal):not(.ui-widget-overlay):not(.ignoreBlur),
		.SimpleModal:not(.LockModal) .SimpleModal__wrapper,
		footer {
			-webkit-filter: blur(7px);
			filter: blur(7px);
		}
	}
}

ul.spaced li {
	margin: 0;
	padding: 4px 5px 2px;
}

.filterForm {
	margin-top: 22px;
	margin-right: 2px;
}

.privacyLink {
	font-size: 12px;
	color: #a8a8a8;

	> .bicon-privacy {
		vertical-align: baseline;
		margin-right: 4px;
	}
}

.optionMenuIcon {
	border: 1px solid $bhrColors.gray6;
	border-radius: 3px;
	height: 9px;
	padding: 2px;
	width: 10px;

	.caret {
		margin: 3px 0 0 0;
		border-top-color: #777;
		border-left-width: 3px;
		border-right-width: 3px;
		border-top-width: 4px;
	}
}

.chevron {
	display: inline-block;
	margin-right: 5px;
	float: left;
	margin-top: 0;
	font-size: 18px;
	height: 8px;
	width: 8px;
	transform: rotate(-90deg);
	transform-origin: 2px 9px;
	transition: all 0.2s ease-in;

	&.shown {
		transform-origin: 6px 10px;
		transform: rotate(90deg);
	}
}

.navigateAwayModal {
	margin: 4px 0 7px;

	img {
		float: left;
		margin-right: 7px;
	}

	.navHeader {
		font-size: 24px;
		color: #555;
		margin: -4px 0 13px;
		line-height: 100%;
	}

	.navMessage {
		font-size: 15px;
		color: #555;
		padding-left: 34px;
		line-height: 1.2;
		margin-top: 2px;
	}
}

/* Context-menu flash on page load */
#context-menu {
	display: none;
}

[ba-date]:not(.ba-date-loaded) {
	color: transparent;
}

.cursorText {
	cursor: text;
}
