.GroupedSliceChart {

	&__title {
		fill: #5;
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
	}

	&__emptyBar {
		fill: #c9;
		opacity: 0.36;
	}

	&__backgroundBar {
		fill: #f;
	}

	&__label {
		fill: #7;
		font-size: 12px;
	}

	&__axis {
		.domain {
			stroke-dasharray: 2, 2;
			stroke: #a;
		}
	}

	&__cell{
		outline: thin solid #f;
		shape-rendering: crispEdges;
	}

	&__popover {
		padding: 6px;
		pointer-events: none;
	}

	&__percentage {
		font-size: 18px;
		font-weight: 600;
		padding: 6px;
	}

}

.headless-view {
	.GroupedSliceChart {
		&__cell{
			outline: 3px solid #f;
			stroke: transparent;
			stroke-width: 0;
		}
	}
}
