#sessionMessage {
	height: 0;
	margin: auto;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1020; // TODO: Update after z-index standards are in place

	+encore() {
		left: 0;
		top: 18px;
	}
}

.jade-Alert {
	display: flex;
	// .jade-Alert__link
	&__link {
		color: #fff;
		font-size: 14px;
		line-height: 18px;
		font-weight: 900;

		&:hover {
			fab-color: white;
		}

		// .jade-Alert__link--withBorder
		// Add a border to the right of the alert link
		&--withBorder {
			margin-right: 24px;
			position: relative;
			// .jade-Alert__link--withBorder::before
			&::before {
				background-color: rgba(255, 255, 255, 0.6);
				content: '';
				height: 20px;
				pointer-events: none;
				position: absolute;
				right: -12px;
				top: -1px;
				width: 1px;
			}
		}
	}
	// .jade-Alert__inlineLink
	&__inlineLink {
		color: #fff;
		text-decoration: underline;
	}
	// .jade-Alert__left
	// Wrapper for alert text
	&__left {
		flex: 1;
	}
	// .jade-Alert__right
	// Wrapper for alert action
	&__right {
		display: inline-table;
		margin-left: 60px;
	}
}
