/*------------------------------------*\
$SimpleModal Style

styles used for new simple modal (non Jquery UI)
\*------------------------------------*/

.SimpleModal {
	$sm = selector();

	left: 50%;
	position: fixed;
	top: 50%;
	z-index: 1002;

	.input-list {
		max-height: 150px;
		overflow-y: auto;
	}

	.modalContentWrapper {
		padding: 20px 20px 10px;
	}

	.modalDeleteWrapper {
		padding: 20px;
	}

	.modalSingleEntry {
		padding: 20px 20px 29px;
	}

	&__bg {
		background-color: rgba(0, 0, 0, 0.72);
		position: fixed;
		top: 0;
		left: 0;
		opacity: 0;
		width: 100%;
		height: 100%;
	}

	&__wrapper {
		border-radius: 3px;
		box-sizing: border-box;
		position: relative;
		height: 100%;
		opacity: 0;
		background-clip: padding-box;
		box-shadow: 0 0 75px 0 rgba(255, 255, 255, .3),
								0 0 0 4px rgba(0, 0, 0, 0.7);

		&:before {
			background-color: #fff;
			border-radius: 3px;
			bottom: 0;
			content: '';
			display: block;
			height: calc(100% - 33px);
			position: absolute;
			right: 1px;
			width: calc(100% - 2px);
			z-index: 0;
		}

		&--animate {
			transform: scale(0.8);
			opacity: 0;
			transition: all 0.3s;

			&.show {
				transform: scale(1);
				opacity: 1;
			}
		}
	}

	&__loader,
	&__header,
	&__body,
	&__footer-wrapper {
		display: block;
		position: relative;
	}

	&__loader {
		box-sizing: border-box;
		color: rgba(255, 255, 255, 0.4);
		font-size: 32px;
		left: 50%;
		opacity: 0;
		position: fixed;
		top: 30%;
		transform: translate(-50%, -50%);
		visibility: hidden;

		.dotLoader {
			margin: 0 auto 20px;
		}

		&.show {
			opacity: 1;
			visibility: visible;
		}
	}

	&.updating {
		{$sm}__wrapper:before {
			z-index: 1;
		}
		& > {$sm}__loader {
			color: #999999;
			opacity: 1;
			visibility: visible;
		}
	}

	&__header {
		background: #3a;
		border: 1px solid #000;
		border-radius: 3px 3px 0 0;
		box-sizing: border-box;
		color: #ffffff;
		height: 36px;
		margin: 0;
		padding: 7px 30px 6px 12px;

		&-title {
			float: left;
			font-family: BhrHeaderFontStack;
			font-weight: 600;
			font-size: 15px;
			line-height: 21px;
			max-width: 0;
		}

		&-close {
			height: 16px;
			width: 18px;
			border: 1px solid transparent;
			border-radius: 2px;
			padding: 5px 2px 1px;
			margin: 0;
			position: absolute;
			top: 5px;
			right: 5px;

			.modalXpath {
				fill: rgba(255, 255, 255, 0.6);
			}

			&:hover {
				border: 1px solid #05;
				background-color: #29;
				cursor: pointer;

				.modalXpath {
					fill: rgba(255, 255, 255, 1);
				}
			}
		}
	}

	&__body {
		background-color: #fff;
		border-left: 1px solid #000;
		border-right: 1px solid #000;
		box-sizing: border-box;
		height: -webkit-calc(100% - 32px);
		height: calc(100% - 32px);
		line-height: 1.4em;
		overflow: auto;
		text-align: left;
		width: 100%;

		p {
			text-align: left;

			&.txtCenter {
				text-align: center;
			}
		}
	}

	&__footer {
		background: #f0;
		bottom: 0;
		border-top: 1px solid #c7;
		border-radius: 0 0 3px 3px;
		height: 28px;
		padding: 10px 20px;
		text-align: left;

		&-wrapper {
			width: 100%;
			border: solid #00 1px;
			border-top: none;
			border-radius: 0 0 3px 3px;
			padding: 0;
			box-sizing: border-box;
			overflow: hidden;
		}

		&-cancel {
			margin-left: 10px;
			position: relative;
			top: 2px;
		}

		&-text {
			color: #bbb;
			float: right;
			font-size: 12px;
			margin-left: 15px;
			position: relative;
			top: 9px;

			a:not(.bhr__link) {
				color: #bbb;

				&:hover {
					color: #0072c6;
				}
			}
		}
	}

	&--stacked {
		position: fixed;

		& {$sm}__wrapper {
			border-top-right-radius: 0;
			border-top-left-radius: 0;
			border-top: none;
			position: relative;
			box-shadow: 0 0 75px 0 rgba(255, 255, 255, .3),
									2px 2px 0 2px rgba(0, 0, 0, 0.7),
									2px 2px 0 2px rgba(0, 0, 0, 0.7),
									-2px 2px 0 2px rgba(0, 0, 0, 0.7);
		}

		& {$sm}__header {
			background: #f0;
			border: none;
			border-bottom: solid #c7 1px;
			border-radius: 0;
			color: #222;
			font-weight: normal;
			height: 32px;
			margin: 0 1px;
			box-shadow: 0 1px 0 1px #000;

			&-title {
				font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
				line-height: 18px;
			}

			&-close {
				padding: 3px 2px;
				top: 5px;
				right: 5px;
				height: 12px;
				width: 14px;

				.modalXpath {
					fill: #a4;
				}

				&:hover {
					background: #d1;
					border: solid #a2 1px;

					.modalXpath {
						fill: #55;
					}
				}
			}
		}

		.modalSubHeader {
			margin: 0 1px;
		}

		& {$sm}__footer {
			height: 26px;
		}
	}

	/**
	Simple Modifier Classes
	**/

	/* Data classes describing type of modal */
	&.full-screen {
		height: calc(100% - 100px);
		left: 50px;
		top: 50px;
		width: calc(100% - 100px);
	}

	&.full-height {
		height: -webkit-calc(100% - 100px);
		height: calc(100% - 100px);
		top: 50px;
	}

	/* no-box-shadow | Removes the shadow on the top of the simple modal body */
	&.no-box-shadow {$sm}__body:before {
		box-shadow: none;
	}

	/* no-overflow | No scrolling in any direction on the modal body */
	&.no-overflow {$sm}__body {
		overflow: hidden;
	}

	/* show-overflow | Allows content in the modal body to visually overflow */
	&.show-overflow {$sm}__body {
		overflow visible;
	}

	// include-footer | Gradient background that fades into footer (no border on footer) */
	&.include-footer {

		{$sm}__body {
			background: rgb(236, 236, 236); /* Old browsers */
			background: linear-gradient(to bottom, rgba(236, 236, 236, 1) 0, rgba(252, 252, 252, 1) 100%); /* W3C */
		}

		.fileModalWrapper {
			padding-bottom: 0;
		}

		{$sm}__footer {
			border-top-color: transparent;
			background: #fcfcfc;
			padding: 15px 20px;
		}
	}

	&.include-footer--dark {
		{$sm}__footer {
			border-top: none;
			background-color: #ebebeb;
			padding: 8px 20px 15px;
		}
	}

	&.jquery-padding {
		{$sm}__body {
			padding: 20px 20px 25px;
		}
	}

	&.link-style {
		{$sm}__footer {
			&-text,
			&-text a {
				color: #006ec2;
				font-size: 14px;
			}
		}
	}

	&.dark-gray {
		{$sm}__footer-text {
			color: #888;
			font-size: 14px;
		}
	}
	/* white version of modal */
	&--white {
		{$sm}__header {
			background: white none;
			border-bottom: none;
			box-shadow: none;
			height: 34px;

			&-title {
				color: #333;
			}

			&-close {
				color: #999;

				.modalXpath {
					fill: #999;
				}

				&:hover {
					background-color: #EEE;
					border: 1px solid #DDD;
				}
			}
		}

		{$sm}__body:before {
			box-shadow: none;
		}
	}

	&--noFooter {$sm}__body {
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
		border-bottom: 1px solid #000;
	}

	&--noHeader {$sm}__body {
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
		border-top: 1px solid #000;
	}

	.shareListInputLabel {
		display: block;
	}

	.navMessage {
		max-width: 425px;
	}

	// Avoid bad positioning
	.EmployeeSelector {
		min-width: 776px;
	}
}
	// IE10 and IE11 fix for darker lock modal backgrounds
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		.LockModal .SimpleModal__bg {
			background-color: rgba(0, 0, 0, 1);
		}
	}
