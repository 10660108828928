.textLayer {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	opacity: 0.2;
	// @startCleanup encore
	box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.55);
	+encore() {
		box-shadow: none;
	}
	// @endCleanup encore

	> div {
		color: transparent;
		position: absolute;
		white-space: pre;
		cursor: text;
		transform-origin: 0 0;
	}

	.highlight {
		margin: -1px;
		padding: 1px;
		background-color: rgb(180, 0, 170);
		border-radius: 4px;

		&.begin {
			border-radius: 4px 0 0 4px;
		}

		&.middle {
			border-radius: 0;
		}

		&.end {
			border-radius: 0 4px 4px 0;
		}

		&.selected {
			background-color: rgb(0, 100, 0);
		}
	}

	:selection {
		background: rgb(0, 0, 255);
	}

	:-moz-selection {
		background: rgb(0, 0, 255);
	}
}

// PDF Viewer
.pdfViewer {

	.canvasWrapper {
		overflow: hidden;
	}

	.annotationLayer {
		& section {
			position: absolute;
			text-align: initial;
			border-style: none !important;

			// Certificate styles
			input[name^='action'] {
				fab-font-weight: bold;
			}

			input[name='Document Title'] {
				font-size: 10px;
				width: 320px;
			}

			textarea[name='Document Title'] {
                overflow: hidden;
            }

			input[name='Document ID'] {
				font-size: 7.5px;
			}
		}

		& .textWidgetAnnotation textarea {
			resize: none;
		}
	}

	.annotationLayer .linkAnnotation > a,
	.annotationLayer .buttonWidgetAnnotation.pushButton > a {
		position: absolute;
		font-size: 1em;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.annotationLayer .linkAnnotation > a:hover,
	.annotationLayer .buttonWidgetAnnotation.pushButton > a:hover {
		opacity: 0.2;
		background: rgba(255, 255, 0, 1);
		box-shadow: 0 2px 10px rgba(255, 255, 0, 1);
	}

	.annotationLayer .textAnnotation img {
		position: absolute;
		cursor: pointer;
	}

	.annotationLayer .textWidgetAnnotation input,
	.annotationLayer .textWidgetAnnotation textarea,
	.annotationLayer .choiceWidgetAnnotation select,
	.annotationLayer .buttonWidgetAnnotation.checkBox input,
	.annotationLayer .buttonWidgetAnnotation.radioButton input {
		pointer-events: none;
		user-select: none;
		border: none;
		box-sizing: border-box;
		font-size: 9px;
		height: 100%;
		margin: 0;
		padding: 0 3px;
		vertical-align: top;
		width: 100%;
	}

	.annotationLayer .choiceWidgetAnnotation select option {
		padding: 0;
	}

	.annotationLayer .buttonWidgetAnnotation.radioButton input {
		border-radius: 50%;
	}

	.annotationLayer .textWidgetAnnotation input[disabled],
	.annotationLayer .textWidgetAnnotation textarea[disabled],
	.annotationLayer .choiceWidgetAnnotation select[disabled],
	.annotationLayer .buttonWidgetAnnotation.checkBox input[disabled],
	.annotationLayer .buttonWidgetAnnotation.radioButton input[disabled] {
		background: none;
		border: 0;
	}

	.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before,
	.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after,
	.annotationLayer .buttonWidgetAnnotation.radioButton input:checked:before {
		background-color: rgba(0, 0, 0, 1);
		content: "";
		display: block;
		position: absolute;
	}

	.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before,
	.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after {
		height: 80%;
		left: 45%;
		width: 1px;
	}

	.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before {
		transform: rotate(45deg);
	}

	.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after {
		transform: rotate(-45deg);
	}

	.annotationLayer .buttonWidgetAnnotation.radioButton input:checked:before {
		border-radius: 50%;
		height: 50%;
		left: 30%;
		top: 20%;
		width: 50%;
	}

	.annotationLayer .buttonWidgetAnnotation.checkBox input,
	.annotationLayer .buttonWidgetAnnotation.radioButton input {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		padding: 0;
	}

	.annotationLayer .popupWrapper {
		position: absolute;
		width: 20em;
	}

	.annotationLayer .popup {
		position: absolute;
		z-index: 200;
		max-width: 20em;
		background-color: rgba(255, 255, 153, 1);
		box-shadow: 0 2px 5px rgba(136, 136, 136, 1);
		border-radius: 2px;
		padding: 6px;
		margin-left: 5px;
		cursor: pointer;
		font: message-box;
		font-size: 9px;
		white-space: normal;
		word-wrap: break-word;
	}

	.annotationLayer .popup > * {
		font-size: 9px;
	}

	.annotationLayer .popup h1 {
		display: inline-block;
	}

	.annotationLayer .popup span {
		display: inline-block;
		margin-left: 5px;
	}

	.annotationLayer .popup p {
		border-top: 1px solid rgba(51, 51, 51, 1);
		margin-top: 2px;
		padding-top: 2px;
	}

	.annotationLayer .highlightAnnotation,
	.annotationLayer .underlineAnnotation,
	.annotationLayer .squigglyAnnotation,
	.annotationLayer .strikeoutAnnotation,
	.annotationLayer .freeTextAnnotation,
	.annotationLayer .lineAnnotation svg line,
	.annotationLayer .squareAnnotation svg rect,
	.annotationLayer .circleAnnotation svg ellipse,
	.annotationLayer .polylineAnnotation svg polyline,
	.annotationLayer .polygonAnnotation svg polygon,
	.annotationLayer .caretAnnotation,
	.annotationLayer .inkAnnotation svg polyline,
	.annotationLayer .stampAnnotation,
	.annotationLayer .fileAttachmentAnnotation {
		cursor: pointer;
	}

	.previewerPage {
		direction: ltr;
		width: 816px;
		height: 1056px;
		margin: 1px auto -8px auto;
		position: relative;
		overflow: visible;
		border: 9px solid transparent;
		background-clip: content-box;

		canvas {
			margin: 0;
			display: block;
		}

		.loadingIcon {
			position: absolute;
			display: block;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: url('https://staticfe.bamboohr.com/js/pdfjs/web/images/loading-icon.gif') center no-repeat;
		}

	}

	&.removePageBorders {

		.previewerPage {
			margin: 0 auto 10px auto;
			border: none;
		}
	}
}

#outerContainer {
	background-color: fab-color(gray2);
	width: 100%;
    height: 100%;
    position: relative;

	+encore() {
		background-color: var(--gray2);
	}
}

// outer/inner center provides horizontal center
.outerCenter {
	pointer-events: none;
	position: relative;
}

.innerCenter {
	pointer-events: auto;
	position: relative;
}

.pdfjsViewer {
	// @startCleanup encore
	overflow-y: hidden;

	+encore() {
		overflow: visible;
	}
	// @endCleanup encore

	.outerCenter {
		float: right;
		right: 50%;
	}

	.innerCenter {
		float: right;
		right: -50%;
	}
}

.isPreviewUser {
	padding-top: 76px;
}

#mainContainer {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	min-width: 320px;
}

#viewerContainer {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	position: absolute;
	top: 53px;
	right: 0;
	bottom: 0;
	left: 0;
	outline: none;

	+encore() {
		top: 66px;
	}
}

.toolbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid fab-color(gray4);
	padding: 12px 32px;

	+encore() {
		background-color: white;
		border-bottom: 1px solid var(--gray2);
		padding: 16px;
	}
}

.scaleSelect {
	margin-right: 12px;
	width: auto !important;

	+encore() {
		margin: 0;
	}
}

#toolbarViewerLeft {
	display: flex;
	align-items: center;
}

.toolbarViewerLeft {
	&__button {
		+encore() {
			margin-left: 8px;
		}
	}
}

#toolbarViewerRight {
	display: flex;
	align-items: center;
	fab-text: teenie;
	fab-color: gray6;
}

.splitToolbarButtonSeparator {
	width: 1px;
	height: 12px;
	background-color: fab-color(gray6);
	z-index: 99;
	display: inline-block;
	margin: 0 10px;
}

.overlayButton {
	min-width: 16px;
	padding: 2px 6px 0;
	border-radius: 2px;
	color: hsla(0, 0, 100%, 0.8);
	font-size: 12px;
	line-height: 14px;
	border: 1px solid transparent;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	cursor: default;
	margin: 5px 0 4px;
	background: none;
	width: 26px;
	height: 24px;

	&[disabled] {
		opacity: 0.5;
	}
}

.toolbarButtonSpacer {
  width: 26px;
  display: inline-block;
  height: 1px;
}

.toolbarButtonFlexibleSpacer {
	-webkit-box-flex: 1;
	-moz-box-flex: 1;
	min-width: 26px;
}

.toolbarField {
	&[type=checkbox] {
		display: inline-block;
		margin: 8px 0;
	}

	&.pageNumber {
		&.visiblePageIsLoading {
			background-image: url("https://staticfe.bamboohr.com/js/pdfjs/web/images/loading-small.png");
			background-repeat: no-repeat;
			background-position: 1px;
		}

		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
		    margin: 0;
		}
	}

	&:hover {
	  background-color: hsla(0, 0, 100%, 0.11);
	  border-color: hsla(0, 0, 0, 0.4) hsla(0, 0, 0, 0.43) hsla(0, 0, 0, 0.45);
	}

	&:focus {
	  background-color: hsla(0, 0, 100%, 0.15);
	  border-color: hsla(204, 100%, 65%, 0.8) hsla(204, 100%, 65%, 0.85) hsla(204, 100%, 65%, 0.9);
	}
}

// Overrides
#pageNumberLabel {
}

#pageNumber {
	-moz-appearance: textfield; // hides the spinner in moz
	background-color: transparent;
	border: 0;
	box-shadow: none;
	color: inherit;
	display: inline-block;
	font-family: inherit;
	font-size: inherit;
	text-align: right;
	width: auto;
	margin-right: 4px;
	padding: 0;
	line-height: inherit;
}

#numPages {
	margin-right: 16px;
}

/* TODO: file FF bug to support :-moz-selection:window-inactive
   so we can override the opaque grey background when the window is inactive;
   see https://bugzilla.mozilla.org/show_bug.cgi?id=706209 */
:selection {
	background: rgba(0, 0, 255, 0.3);
}

:-moz-selection {
	background: rgba(0, 0, 255, 0.3);
}

#errorWrapper {
	background: none repeat scroll 0 0 #FF5555;
	color: white;
	left: 0;
	position: absolute;
	right: 0;
	z-index: 1000;
	padding: 3px;
	font-size: 0.8em;
}
.loadingInProgress #errorWrapper {
	top: 37px;
}

#errorMessageLeft {
	float: left;
}

#errorMessageRight {
	float: right;
}

#errorMoreInfo {
	background-color: #fff;
	color: black;
	padding: 3px;
	margin: 3px;
	width: 98%;
}

.overlayButton {
	width: auto;
	margin: 5px 4px 2px 4px !important;
	padding: 2px 6px 3px 6px;
}

#overlayContainer {
	display: table;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: hsla(0, 0, 0, 0.2);
	z-index: 3000;

	+encore() {
		margin: -20px;
	}

	> * {
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}

	> .container {
		display: table-cell;
		vertical-align: middle;
		text-align: center;
	}
}

#overlayContainer > .container > .dialog {
	display: inline-block;
	padding: 15px;
	border-spacing: 4px;
	color: hsl(0, 0, 85%);
	font-size: 12px;
	line-height: 14px;
	background-color: #474747; /* fallback */
	background-image: url(/js/pdfjs/web/images/texture.png),
	                linear-gradient(hsla(0, 0, 32%, 0.99), hsla(0, 0, 27%, 0.95));
	box-shadow: inset 1px 0 0 hsla(0, 0, 100%, 0.08),
	          inset 0 1px 1px hsla(0, 0, 0, 0.15),
	          inset 0 -1px 0 hsla(0, 0, 100%, 0.05),
	          0 1px 0 hsla(0, 0, 0, 0.15),
	          0 1px 1px hsla(0, 0, 0, 0.1);
	border: 1px solid hsla(0, 0, 0, 0.5);
	border-radius: 4px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.dialog {

	> .row {
		display: table-row;

		> * {
			display: table-cell;
		}
	}

	.toolbarField {
		margin: 5px 0;
	}

	.separator {
		display: block;
		margin: 4px 0 4px 0;
		height: 1px;
		width: 100%;
		background-color: hsla(0, 0, 0, 0.5);
		box-shadow: 0 0 0 1px hsla(0, 0, 100%, 0.08);
	}

	.buttonRow {
		text-align: center;
		vertical-align: middle;
	}
}

#passwordOverlay {
	> .dialog {
  		text-align: center;
	}

	.toolbarField {
		color: fab-color(gray1);
		width: 200px;
	}
}

#documentPropertiesOverlay {

	> .dialog {
		text-align: left;
	}

	.row {

		> * {
			min-width: 100px;
		    text-align: left;
		}

		> span {
			width: 125px;
			word-wrap: break-word;
		}

		> p {
			max-width: 225px;
			word-wrap: break-word;
		}
	}

	.buttonRow {
		margin-top: 10px;
	}
}

.clearBoth {
	clear: both;
}

.fileInput {
	background: white;
	color: black;
	margin-top: 5px;
	visibility: hidden;
	position: fixed;
	right: 0;
	top: 0;
}

#viewer.textLayer-visible .textLayer > div,
#viewer.textLayer-hover .textLayer > div:hover {
	background-color: white;
	color: black;
}

#viewer.textLayer-shadow .textLayer > div {
	background-color: rgba(255, 255, 255, 0.6);
	color: black;
}

.grab-to-pan-grab {
	cursor: url("https://staticfe.bamboohr.com/js/pdfjs/web/images/grab.cur"), move !important;
	cursor: -webkit-grab !important;
	cursor: -moz-grab !important;
	cursor: grab !important;
}
.grab-to-pan-grab *:not(input):not(textarea):not(button):not(select):not(:link) {
	cursor: inherit !important;
}
.grab-to-pan-grab:active,
.grab-to-pan-grabbing {
	cursor: url("https://staticfe.bamboohr.com/js/pdfjs/web/images/grabbing.cur"), move !important;
	cursor: -webkit-grabbing !important;
	cursor: -moz-grabbing !important;
	cursor: grabbing !important;
	position: fixed;
	background: transparent;
	display: block;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
}

@page {
	margin: 0;
}

#printContainer {
	display: none;
}

@media screen and (min-resolution: 2dppx) {
	/* Rules for Retina screens */

	.toolbarField.pageNumber.visiblePageIsLoading {
		background-image: url("https://staticfe.bamboohr.com/js/pdfjs/web/images/loading-small@2x.png");
		background-size: 16px 17px;
	}
}

@media print {

	/* General rules for printing. */
	body {
		background: transparent none;
		position: relative !important;
		top: auto !important;
	}

	/* Rules for browsers that don't support mozPrintCallback. */
	.toolbar, #loadingBox, #errorWrapper, .textLayer {
		display: none;
	}

	#viewerContainer {
		overflow: visible;
	}

	#mainContainer, #viewerContainer, .previewerPage, .previewerPage canvas {
		position: static;
		padding: 0;
		margin: 0;
	}

	.previewerPage {
		float: left;
		display: none;
		border: none;
		box-shadow: none;
		background-clip: content-box;
		background-color: white;
	}

	.previewerPage[data-loaded] {
		display: block;
	}

	.fileInput {
		display: none;
	}

	/* Rules for browsers that support mozPrintCallback */
	body[data-mozPrintCallback] #outerContainer {
		display: none;
	}

	body[data-mozPrintCallback] #printContainer {
		display: block;
	}

	/* wrapper around (scaled) print canvas elements */
	#printContainer > div {
		position: relative;
		top: 0;
		left: 0;
		overflow: hidden;
	}

	#printContainer canvas {
		display: block;
	}

	#printContainer div {
		page-break-after: always;
		page-break-inside: avoid;
	}

	#mozPrintCallback-shim {
		display: none;
	}
}

.visibleLargeView,
.visibleMediumView,
.visibleSmallView {
	display: none;
}

@media all and (max-width: 770px) {
	.outerCenter {
		float: left;
		left: 205px;
	}

	#outerContainer .hiddenLargeView,
	#outerContainer .hiddenMediumView {
		display: inherit;
	}

	#outerContainer .visibleLargeView,
	#outerContainer .visibleMediumView {
		display: none;
	}
}

@media all and (max-width: 700px) {
	#outerContainer .hiddenLargeView {
		display: none;
	}

	#outerContainer .visibleLargeView {
		display: inherit;
	}
}

@media all and (max-width: 660px) {
	#outerContainer .hiddenMediumView {
		display: none;
	}

	#outerContainer .visibleMediumView {
		display: inherit;
	}
}

@media all and (max-width: 600px) {
	.hiddenSmallView {
		display: none;
	}

	.visibleSmallView {
		display: inherit;
	}

	#outerContainer.sidebarMoving .outerCenter,
	#outerContainer.sidebarOpen .outerCenter,
	.outerCenter {
		left: 156px;
	}

	.toolbarButtonSpacer {
		width: 0;
	}
}

@media all and (max-width: 510px) {
	#pageNumberLabel {
		display: none;
	}
}

#mozPrintCallback-shim {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 3001;
	display: block;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.5);

	&[hidden] {
		display: none;
	}

	.mozPrintCallback-dialog-box {
		display: inline-block;
		margin: -50px auto 0;
		position: relative;
		top: 45%;
		left: 0;
		min-width: 220px;
		max-width: 400px;
		padding: 15px;
		border: 2px solid #555555;
		border-radius: 2px;
		background-color: $bhrColors.gray3;
		color: #555;
		font-size: 14px;
		line-height: 20px;
	}

	.progress-row {
		clear: both;
		padding: 12px 0 10px;
		width: auto;
	}

	progress {
		/* Reset the default appearance */
		-webkit-appearance: none;
		appearance: none;
		height: 6px;
		width: 216px;
		display: block;
		margin-bottom: 3px;
		color: #7A7A7A;
		background-color: #D4D4D4;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25) inset;
		border-radius: 3px;
		border: none;

		&::-webkit-progress-bar {
			background-color: #D4D4D4;
			border-radius: 3px;
			box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25) inset;
		}

		&::-webkit-progress-value {
		    background-color: #7A7A7A;
			border-radius: 3px 0 0 3px;
		}
	}

	.relative-progress {
		color: #6F6F6F;
		font-size: 12px;
		clear: both;
	}

	.progress-actions {
		clear: both;
	}
}
