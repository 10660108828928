

div#filter-modal-container { display: none; }

span.filter-error.attempted:after{
	content:url('https://staticfe.bamboohr.com/resources/images/bicons/field-error.png');
	display:inline-block;
	height: 13px;
	margin: 0 3px 0 0;
}

.RemoveEmployeeFilter {
	//.RemoveEmployeeFilter__button
	&__button {
		height: 24px !important;
		//.RemoveEmployeeFilter__button svg, //.RemoveEmployeeFilter__button:hover svg
		& svg,
		&:hover svg{
			fill: fab-color(gray7) !important;
		}
		//.RemoveEmployeeFilter__button, .RemoveEmployeeFilter__button:active, .RemoveEmployeeFilter__button:focus, .RemoveEmployeeFilter__button:hover
		&,
		&:active,
		&:focus,
		&:hover {
			border: none !important;
			background: none !important;
			box-shadow: none !important;
			outline: none !important;
		}
	}
	//.RemoveEmployeeFilter span
	span {
		fab-font-size: small;
		vertical-align: text-bottom;
	}
}

.label-text {
	color: fab-color(gray7);
	fab-line-height: teenie;
	fab-font-size: small;
	fill: fab-color(gray7);
}

//.FilterListWrapper
.FilterListWrapper {
	&__header {
		+encore() {
			margin-bottom: 24px;
		}
	}
	//.FilterListWrapper__headerMessage, .FilterListWrapper__headerCount
	&__headerMessage,
	&__headerCount {
		display: inline-block;
		// @startCleanup encore
		+encore() {
		// @endCleanup encore
			fab-font-size: h6;
			fab-font-weight: semibold;
			fab-color: gray8;
		// @startCleanup encore
		}
		// @endCleanup encore
	}
	//.FilterListWrapper__headerCount
	&__headerCount {
		float: right;
		display: flex;
		align-items: center;
		// @startCleanup encore
		gap: 8px;
		+encore() {
			// @endCleanup encore
			gap: 12px;
			// @startCleanup encore
		}
		// @endCleanup encore
	}
	// @startCleanup encore
	//.FilterListWrapper__headerIcon
	&__headerIcon {
		position: relative;
		top: 2px;
	}
	// @endCleanup encore
}

.filter-list-wrapper {
	height: 77%;
	// @startCleanup encore
	margin-top: 13px;
	+encore() {
		margin-top: unset;
	}
	// @endCleanup encore
	//.filter-list-wrapper > ul.filter-list
	> ul.filter-list {
		background: fab-color(gray1);
		height: 100%;
		// @startCleanup encore
		margin-top: 5px;
		border: solid fab-color(gray4) 1px;
		border-radius: 3px;
		+encore() {
		// @endCleanup encore
			margin-top: unset;
			border-bottom: none;
			border-left: none;
			border-right: none;
			border-top-width: 1px;
			border-top-color: var(--gray2);
			border-radius: 0;
			background: unset;
			background-color: unset;
		// @startCleanup encore
		}
		// @endCleanup encore

		//.filter-list-wrapper > ul.filter-list.filter-list--employeeFilter
		&.filter-list--employeeFilter {
			height: 200px;
			overflow: auto;
			box-sizing: border-box;
			// @startCleanup encore
			+encore() {
			// @endCleanup encore
				height: auto;
			// @startCleanup encore
			}
			// @endCleanup encore
			//.filter-list-wrapper > ul.filter-list.filter-list--employeeFilter li
			li {
				// @startCleanup encore
				padding-left: 7px;
				+encore() {
					padding: 8px 0;
				}
				// @endCleanup encore
				z-index: 1;
			}
		}

		// @startCleanup encore
		//.filter-list-wrapper > ul.filter-list > li
		> li {
			//.filter-list-wrapper > ul.filter-list > li::not(.open)
			&:not(.open) {
				//.filter-list-wrapper > ul.filter-list > li::not(.open):hover
				&:hover {
					+jadeFabricTheme() {
						border-top: solid fab-color(gray3) 1px;
						border-bottom: solid fab-color(gray3) 1px;
					}
				}

				//.filter-list-wrapper > ul.filter-list > li::not(.open):last-child
				&:last-child {
					//.filter-list-wrapper > ul.filter-list > li::not(.open):last-child:hover
					&:hover {
						+jadeFabricTheme() {
							border-bottom: solid fab-color(gray3) 1px;
						}
					}
				}
			}
		}
		// @endCleanup encore

		// @startCleanup encore
		// last item styles that aren't relevant for encore
		//.filter-list-wrapper > ul.filter-list li
		li {
			//.filter-list-wrapper > ul.filter-list li:last-child
			&:last-child {
				//.filter-list-wrapper > ul.filter-list li:last-child > div.drop-filter
				> div.drop-filter {
					// @startCleanup encore
					padding: 8px 0 8px 19px;
					+encore() {
						padding: 16px;
					}
				}
			}
		}
		// @endCleanup encore
	}

	//.filter-list-wrapper ul.filter-list.wrappable
	ul.filter-list.wrappable {
		-webkit-columns: 2;
		-moz-columns: 2;
		columns: 2;
		overflow: visible;
	}

	//.filter-list-wrapper ul.filter-list
	ul.filter-list {
		//.filter-list-wrapper ul.filter-list.options
		&.options {
			//.filter-list-wrapper ul.filter-list.options > li
			> li {
				//.filter-list-wrapper ul.filter-list.options > li:hover, .filter-list-wrapper ul.filter-list.options > li:last-child:hover, .filter-list-wrapper ul.filter-list.options > li:hover + li
				&:hover,
				&:list-child:hover,
				&:hover + li {
					border-color: transparent;
					background: none;
				}
			}
		}
		//.filter-list-wrapper ul.filter-list > li
		> li {
			position: relative;
			padding: 0;
			max-width: 100%;
			// @startCleanup encore
			border-top: solid transparent 1px;
			border-bottom: solid transparent 1px;
			+encore() {
				// @endCleanup encore 
				border-bottom-color: var(--gray2);
				border-bottom-width: 1px;
				border-top: none;
			}

			// @startCleanup encore
			// closed arrow styles no longe relevant for encore
			//.filter-list-wrapper ul.filter-list > li:hover .drop-filter
			.drop-filter {
				//.filter-list-wrapper ul.filter-list > li:hover .drop-filter.df-up
				&.df-up {
					//.filter-list-wrapper ul.filter-list > li:hover .drop-filter.df-up:before
					&:before {
						content: "";
						display: inline-block;
						height: 0;
						width: 0;
						border: solid transparent 4px;
						border-left-color: fab-color(gray8);
						position: absolute;
						// @startCleanup encore
						top: 15px;
						// @endCleanup encore
						left: 14px;

						+encore() {
							content: unset;
						}
					}
				}
			}
			// @endCleanup encore

			//.filter-list-wrapper ul.filter-list > li:last-child
			&:last-child {
				// @startCleanup encore
				border-bottom-color: transparent;
				+encore() {
				// @endCleanup encore
					border-bottom-color: var(--gray2);
					border-bottom-width: 1px;
				// @startCleanup encore
				}
				// @endCleanup encore
			}

			//.filter-list-wrapper ul.filter-list > li:open
			&.open {
				// @startCleanup encore
				background: fab-color(white);
				border-top: solid fab-color(gray3) 1px;
				// @endCleanup encore
				border-bottom: none;
				+encore() {
					border-top: none;
					background: none;
				}

				//.filter-list-wrapper ul.filter-list > li:open:last-child
				&:last-child {
					border-bottom: solid fab-color(gray3) 1px;
				}

				//.filter-list-wrapper ul.filter-list > li:open + li
				+ li {
					border-top: solid fab-color(gray3) 1px;

					//.filter-list-wrapper ul.filter-list > li:open + li.open
					&.open {
						//.filter-list-wrapper ul.filter-list > li:open + li.open:last-child
						&:last-child {
							border-bottom: none;
						}
					}
				}

				//.filter-list-wrapper ul.filter-list > li:open > .drop-filter, .filter-list-wrapper ul.filter-list > li:open:hover > .drop-filter
				> .drop-filter,
				&:hover > .drop-filter {
					// @startCleanup encore
					+jadeFabricTheme() {
						fab-font-weight: semibold;
						color: fab-color(gray10);
					}
					+encore() { 
					// @endCleanup encore
						fab-color: theme-base;
					// @startCleanup encore
					}
					// @endCleanup encore
					> span:first-child, .selectedFilters {
						// @startCleanup encore
						+encore() {
						// @endCleanup encore
							fab-color: theme-base;
							fab-font-weight: semibold;
						// @startCleanup encore
						}	
						// @endCleanup encore
					}
				}

				> .drop-filter > .drop-filter-arrow-icon {
					transform: rotate(-90deg);
					> svg {
						fill: fab-color(theme-base);
					}
				}
			}

			//.filter-list-wrapper ul.filter-list > li > .filter-option
			> .filter-option {
				display: inline-block;
				padding: 7px 9px 0 10px;

				label {
					margin-bottom: 5px;
				}
			}
		}

		//.filter-list-wrapper ul.filter-list li
		li {
			// @startCleanup encore
			//.filter-list-wrapper ul.filter-list li:hover
			&:hover {
				//.filter-list-wrapper ul.filter-list li:hover > div.drop-filter
				> div.drop-filter {
					color: #006ec2;
					+encore() { 
						fab-color: gray9;
					}
 				}
			}
			// @endCleanup encore

			//.filter-list-wrapper ul.filter-list li > div.drop-filter
			//.filter-list-wrapper ul.filter-list li >
			> div.drop-filter {
				background: none;
				cursor: pointer;
				// @startCleanup encore
				padding: 7px 0 7px 19px;
				fab-font-weight: regular;
				color: fab-color(gray7);
				+encore() {
				// @endCleanup encore
					fab-font-weight: semibold;
					fab-color: gray9;
					fab-font-size: h6;
					padding: 16px;
					display: flex;
					align-items: center;
				// @startCleanup encore
				}
				// @endCleanup encore

				> .drop-filter-arrow-icon {
					// @startCleanup encore
					+encore() {
						// @endCleanup encore
						margin-left: auto;
						transition: transform 150ms, fill 150ms;
						// @startCleanup encore
					}
					// @endCleanup encore
				}

				width: 100%;
				box-sizing: border-box;

				// @startCleanup encore
				//.filter-list-wrapper ul.filter-list li > div.drop-filter span
				span {
					//.filter-list-wrapper ul.filter-list li > div.drop-filter span:first-child
					&:first-child {
						fab-font-weight: regular;
						color: fab-color(gray7);
						+encore() {
							color: inherit;
							font-weight: inherit;
						}
					}
				}
				// @endCleanup encore
			}
		}

		//.filter-list-wrapper ul.filter-list div[id^="div_"]
		div[id^="div_"] {
			display: inline-block;
			margin-top: 7px;
		}

		//.filter-list-wrapper ul.filter-list ul.filter-list
		ul.filter-list {
			// @startCleanup encore
			margin: 0 20px;
			display: block;
			padding: 0 0 10px 0;
			+encore() {
				// @endCleanup encore
				margin: 0 16px;
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 8px;
				padding: 0 0 24px 0;
				// @startCleanup encore
			}
			// @endCleanup encore
			width: auto;
			height: auto;

			//.filter-list-wrapper ul.filter-list ul.filter-list li
			li {
				background: none;
				border: none;
				display: inline-block;
				width: 100%;
				// @startCleanup encore
				padding: 3px 0;
				+encore() {
					padding: unset;
				}
				// @endCleanup encore

				//.filter-list-wrapper ul.filter-list ul.filter-list li.col2
				&.col2 {
					float: left;
					width: 50%;
					height: auto;
					clear: none;

					+encore() {
						min-height: 22px;
					}

					//.filter-list-wrapper ul.filter-list ul.filter-list li.col2 label
					label {
						// @startCleanup encore
						padding-left: 20px;
						// @endCleanup encore
						display: inline-block;
						margin-bottom: 0;

						+encore() {
							padding-left: 26px;
						}

						//.filter-list-wrapper ul.filter-list ul.filter-list li.col2 label > span
						> span {
							margin-left: -20px;
						}
					}
				}

				//.filter-list-wrapper ul.filter-list ul.filter-list li
				&.allRow {
					width: 100%;
					margin-bottom: 3px;
				}
			}
		}
	}

	// @startCleanup encore
	// open arrow indicator styles; no longer relevant for encore
	//.filter-list-wrapper .filter-list
	.filter-list {
		//.filter-list-wrapper .filter-list li
		li {
			//.filter-list-wrapper .filter-list li > div.drop-filter
			> div.drop-filter {
				//.filter-list-wrapper .filter-list li > div.drop-filter:not(.df-up)
				&:not(.df-up) {
					//.filter-list-wrapper .filter-list li > div.drop-filter:not(.df-up):before
					&:before {
						content: "";
						display: inline-block;
						height: 0;
						width: 0;
						border: solid transparent 4px;
						border-top-color: fab-color(gray8);
						position: absolute;
						// @startCleanup encore
						top: 17px;
						// @endCleanup encore
						left: 11px;

						+encore() {
							top: 23px;
							content: unset;
						}
					}
				}
			}
		}
	}
	// @endCleanup encore

	//.filter-list-wrapper label
	label {
		//.filter-list-wrapper label.all
		&.all {
			margin-bottom: 5px;
		}
	}

	//.filter-list-wrapper .bicon-people, .filter-list-wrapper bicon-people.nohover:hover, .filter-list-wrapper btn .bicon-people:hover
	.bicon-people,
	.bicon-people.nohover:hover,
	.btn .bicon-people:hover {
		width: 23px;
		height: 18px;
		background-position: top center;
		vertical-align: middle;
		margin-top: -11px;
	}

	//.filter-list-wrapper .numEmployees
	.numEmployees {
		// @startCleanup encore
		&.warning,
		&.simple-warning {
			fab-font-weight: bold;
			fab-color(attention);
		}
		// @startCleanup encore
		+encore() {
			vertical-align: bottom;
			margin-left: unset;
		}
	}
	//.filter-list-wrapper .filter-list-message
	.filter-list-message {
		font-size: 10px;
		color: fab-color(gray5);
	}
}

.numEmployeesCount {
	fill: var(--gray9);
}

.filter-label {
	+encore() {
		padding-bottom: 4px;
	}
}

.calendarRow {
	+encore() {
		margin-bottom: 0 !important;
		padding-bottom: 23px;
		padding-left: 19px;
	}
}


