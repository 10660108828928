@import './colors'

fabricButtonReset()
  background: none
  border: none
  cursor: pointer
  text-decoration: none
  -moz-appearance: none
  -webkit-appearance: none

fabricBorderFocusTheme()
  box-shadow: 0 0 0 2px fabricColor(themeLightest35Default)
  box-shadow: 0 0 0 2px fabricColor(themeLightest35)
  outline: 0
  fabricThemeColor(border-color, lighter)
