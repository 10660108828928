{featureToggleOn("jade")} {
	.LockScreen {
		text-align: center;

		// .LockScreen__header
		&__header {
			margin: 0 0 8px;

			// .LockScreen__headerIcon
			&Icon {
				margin-bottom: 10px;
				fab-fill: theme;
			}
		}

		// .LockScreen__instruction
		&__instruction {
			fab-color: gray9;
			margin-bottom: 25px;
		}

		// .LockScreen__ssoButton
		&__ssoButton {
			margin-bottom: 42px;
		}
	}
}
