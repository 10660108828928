/* Webfont: Ephesis-Regular */
@font-face {
	font-family: 'Ephesis';
	src: url('https://staticfe.bamboohr.com/resources/fonts/Ephesis-Regular.woff2') format('woff2'),
		url('https://staticfe.bamboohr.com/resources/fonts/Ephesis-Regular.woff') format('woff');
}

/* Webfont: Lato-Regular */
@font-face {
	font-family: 'Lato';
	src: url('https://staticfe.bamboohr.com/resources/fonts/Lato-Regular.woff2') format('woff2'),
		url('https://staticfe.bamboohr.com/resources/fonts/Lato-Regular.woff') format('woff');
}

/* Webfont: Lato-Italic */
@font-face {
	font-family: 'Lato';
	src: url('https://staticfe.bamboohr.com/resources/fonts/Lato-Italic.woff2') format('woff2'),
		url('https://staticfe.bamboohr.com/resources/fonts/Lato-Italic.woff') format('woff');
	font-style: italic;
}

/* Webfont: Lato-Medium */
@font-face {
	font-family: 'Lato';
	src: url('https://staticfe.bamboohr.com/resources/fonts/Lato-Medium.woff2') format('woff2'),
		url('https://staticfe.bamboohr.com/resources/fonts/Lato-Medium.woff') format('woff');
	font-weight: $fabText.textWeights.medium;
}

/* Webfont: Lato-Semibold */
@font-face {
	font-family: 'Lato';
	src: url('https://staticfe.bamboohr.com/resources/fonts/Lato-Semibold.woff2') format('woff2'),
		url('https://staticfe.bamboohr.com/resources/fonts/Lato-Semibold.woff') format('woff');
	font-weight: $fabText.textWeights.semibold;
}


/* Webfont: Lato-Bold */
@font-face {
	font-family: 'Lato';
	src: url('https://staticfe.bamboohr.com/resources/fonts/Lato-Bold.woff2') format('woff2'),
		url('https://staticfe.bamboohr.com/resources/fonts/Lato-Bold.woff') format('woff');
	font-weight: $fabText.textWeights.bold;
}

/* Webfont: Lato-Heavy */
@font-face {
	font-family: 'Lato';
	src: url('https://staticfe.bamboohr.com/resources/fonts/Lato-Heavy.woff2') format('woff2'),
		url('https://staticfe.bamboohr.com/resources/fonts/Lato-Heavy.woff') format('woff');
	font-weight: $fabText.textWeights.heavy;
}

/* Webfont: Fields-Regular */
@font-face {
	font-family: 'Fields';
	src: url('https://staticfe.bamboohr.com/assets/fonts/Fields-Regular.woff2') format('woff2');
}

/* Webfont: Fields-SemiBold */
@font-face {
	font-family: 'Fields';
	src: url('https://staticfe.bamboohr.com/assets/fonts/Fields-SemiBold.woff2') format('woff2');
	font-weight: $fabEncoreText.textWeights.semibold;
}

/* Webfont: Fields-Bold */
@font-face {
	font-family: 'Fields';
	src: url('https://staticfe.bamboohr.com/assets/fonts/Fields-Bold.woff2') format('woff2');
	font-weight: $fabEncoreText.textWeights.bold;
}

/* Webfont: Inter-Regular */
@font-face {
	font-family: 'Inter';
	src: url('https://staticfe.bamboohr.com/assets/fonts/Inter-Regular.woff2') format('woff2');
}

/* Webfont: Inter-Medium */
@font-face {
	font-family: 'Inter';
	src: url('https://staticfe.bamboohr.com/assets/fonts/Inter-Medium.woff2') format('woff2');
	font-weight: $fabEncoreText.textWeights.medium;
}

/* Webfont: Inter-Semibold */
@font-face {
	font-family: 'Inter';
	src: url('https://staticfe.bamboohr.com/assets/fonts/Inter-SemiBold.woff2') format('woff2');
	font-weight: $fabEncoreText.textWeights.semibold;
}


/* Webfont: Inter-Bold */
@font-face {
	font-family: 'Inter';
	src: url('https://staticfe.bamboohr.com/assets/fonts/Inter-Bold.woff2') format('woff2');
	font-weight: $fabEncoreText.textWeights.bold;
}

$encoreFontFamilyStack = 'Inter', sans-serif;
$encoreHeaderFontFamilyStack = 'Fields', serif;

$fontFamilyStack = 'Lato', sans-serif;

body {
	color: var(--gray10, #222);
	font-family: $fontFamilyStack;
	fab-text: medium;
	text-align: left;
	text-align: unset;
}

strong {
	fab-font-weight: semibold;
}

+encore() {
	font-family: $encoreFontFamilyStack;

	h1, h2, h3, h4 {
		font-family: $encoreHeaderFontFamilyStack;
	}
}


.truncate,
.truncateText {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
