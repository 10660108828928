
// Padding Top variable
paddingTop = spacing(3);

.SiteFooter {
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  border: none;
  z-index: 1000;
}

.SiteFooter__container {
  $slideDuration = 0.4s;
  $fadeDuration = 0.1s;

  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  transition-property: transform, opacity;
  transition-duration: $slideDuration, $fadeDuration;
  transition-timing-function: ease-in-out;
  transition-delay: 0s, $slideDuration - $fadeDuration;
  padding: 0 40px;
  border-top: 1px solid transparent;
  box-shadow: 0 0 transparent;
  transition: border-top 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

// only apply negative margin when open, otherwise it will mess up layout when closed
.PageCapsule__footer.SiteFooter--open, #po-action-footer:not(:empty) {
	// make flush with edge of page capsule
	margin: calc(var(--page-capsule-padding-y) * -1) calc(var(--page-capsule-padding) * -1);
	// account for shadow of last Fabric Section
	margin-top: 4px;
}

.PageCapsule__footer--borderTop {
  border-top: 1px solid rgba($fabEncoreColors.gray5, 0.2);
  box-shadow: 0 -2px 1px -1px rgba($fabEncoreColors.gray5, 0.05);
}

.SiteFooter__container--main {
  box-sizing: border-box;
  display: block;
  padding-top: paddingTop;
  fab-background-color: 'white';
}

.SiteFooter__container--actions {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: var(--gray05, $fabEncoreColors.gray05)
  align-items: center;
  border-radius: 0 0 var(--radius-600) var(--radius-600)
	padding: var(--page-capsule-padding-y) var(--page-capsule-padding);
  transform: translateY(100%);
  transition: border-top 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

// Adds spacing to the children in the footer
.SiteFooter__wrapper.SiteFooter__wrapper--left > *:not(:first-child) {
  margin-left: spacing(2);
}

// Encore Offer Letter Styles
.body_OLPreview .SiteFooter  {
  width: 100%;
  padding-bottom: 32px;
  background: white;
}

.body_OLPreview #po-action-footer {
  display: none;
}

.body_OLPreview .SiteFooter__container  {
  width: calc(100% - 80px);
  left: 40px;

}

  // Adds left spacing to the children of the footer on the Employee Page
.employeeSection .SiteFooter__container--actions .SiteFooter__wrapper--left {
  padding-left: 288px;
  margin: none;
}

.SiteFooter__wrapper {
  display: flex;
  align-items: center;
}

.SiteFooter__wrapper--outer {
  width: auto;
  justify-content: space-between;
}

.SiteFooter--open .SiteFooter__container--actions {
  transform: translateY(0);
  transition-delay: 0s, 0s;
  opacity: 1;
}

.SiteFooter--hidden {
  visibility: hidden;
}

.SiteFooter--light-theme {
  .SiteFooter__container--actions {
    background-color: var(--gray05, $fabEncoreColors.gray05);
  }
}

.SiteFooter--dark-theme {
  .SiteFooter__container--actions {
    background-color: var(--gray8);
  }
}
