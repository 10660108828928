.chzn-container {
	&.chzn-loose {
		.chzn-results {
			li {
				padding: 11px;
			}
		}
	}

	&.chzn-inline {
		display: inline-block;
		font-size: 14px;
		line-height: 1em;
		margin: 0 3px;
		margin-top: -3px !important;
		width: auto !important;

		/select.chzn-inline {
			&,
			+ .ui-multiselect {
				border: none !important;
				margin: 0 !important;
				padding: 0 !important;
				display: inline;
				max-height: 1em;
				box-shadow: none !important;
				width: auto !important;
			}

			+ .ui-multiselect {
				margin-top: -3px !important;
				margin-left: 3px !important;
				margin-right: 3px !important;

				.ui-icon {
					display: none !important;
				}

				.ui-multiselect-value {
					display: inline;
					line-height: 1em !important;
				}
			}
		}

		.chzn-single {
			&,
			/select.chzn-inline + .ui-multiselect {
				background: none;
				border: none;
				box-shadow: none;
				height: auto;
				margin: 0;
				padding: 0;
				display: inline;
				vertical-align: bottom;
				line-height: 1em !important;
			}

			> span,
			/select.chzn-inline + .ui-multiselect {
				font-weight: 600;
				color: $colors.link;
				display: inline !important;
				width: auto !important;
				padding: 0 !important;
				line-height: 1em !important;
				background: transparent !important;

				&:after {
					content: "";
					display: inline-block;
					border: 3px solid transparent;
					border-bottom-width: 1px;
					border-top-color: $colors.link;
					border-top-width: 4px;
					size: 1px;
					position: relative;
					margin-left: 5px;
				}

				&:hover {
					text-decoration: underline;
				}
			}

			.search-choice-close {
				top: calc(50% - 5px);
				right: -3px;
				background-color: #fff;
			}

			> div {
				display: none;
			}
		}

		.chzn-drop {
			border-top: 1px solid #94;
			border-radius: 2px;
			margin-top: 4px;
			min-width: 200px;
		}

		/.ui-multiselect-menu.chzn-inline {
			border-top: 1px solid #94 !important;
			border-radius: 2px !important;

			.ui-multiselect-checkboxes {
				height: auto !important;
			}
		}

		.chzn-results {
			border-top: none;
			padding: 6px 0 8px;

			li {
				padding: 6px 13px;
				line-height: 1em;
				white-space: nowrap;
			}
		}
	}

	&.chzn-minimal {
		width: auto !important;

		.chzn-search {
			margin: initial;
		}

		.chzn-single {
			background: none;
			border: none;
			box-shadow: none;
			color: inherit !important;
			padding: 0;
		}

		.chzn-drop {
			border-top: 1px solid #94;
			color: #222;
			font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
			min-width: 150px;
			width: 100% !important;
		}

		.chzn-results {
			border-top: none;
		}
	}
}

body > .chzn-drop {
	background: #fff;
	border: 1px solid #aaa;
	border-top-width: 0;
	border-bottom-radius: 2px;
	position: absolute;
	box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
	z-index: 9999;
	text-align: left;
	box-sizing: border-box;
	transform: translateY(-1px);

	.chzn-search {
		input[type="text"] {
			padding: 3px 19px 3px 7px;
			font-size: 14px;
			background: url('https://staticfe.bamboohr.com/resources/images/chosen-sprite-bhr.png') no-repeat 100% -23px, linear-gradient(top, #fff 85%, #eee 99%);
			box-sizing: border-box;
			width: calc(100% - 2px);
			border-radius: 2px;
			color: #222;
			border: 1px solid #af;
			box-shadow: inset 0 1px 1px 0 rgba(#85, 0.2);
			margin-bottom: 2px;
		}
	}

	&.chzn-drop-nosearch {
		.chzn-search {
			display: none;
		}
	}

	.chzn-results {
		margin: 0;
		padding: 0;
		max-height: 240px;
		position: relative;
		overflow-x: hidden;
		overflow-y: auto;
		border-top: 1px solid #d4;
		font-size: 14px;

		.active-result {
			cursor: pointer;
			display: list-item;
		}

		li {
			line-height: 15px;
			padding: 5px 6px;
			margin: 0;
			list-style: none;

			&:not(.active-result) {
				display: none;
			}

			&.highlighted {
				color: #fff;

				em {
					background: none;
				}
			}

			em {
				background: #feffde;
				font-style: normal;
			}
		}
	}
}
