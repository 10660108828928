/*------------------------------------*\
$POPOVER MODULE

styles used for popovers
\*------------------------------------*/

.bhrPopover {
	display: inline-block;
	position: relative;

	&__title {
		white-space: no-wrap;
		fab-color: theme;
		margin-bottom: 4px;
	}

	&__box--small &__title{
		fab-text: large;
	}

	&__close {
		background: 0 0;
		border: 0;
		color: #af;
		cursor: pointer;
		fill: fab-color(gray5);
		font-size: 18px;
		position: absolute;
		top: 5px;
		padding: 0;
		right: 8px;
		-webkit-appearance: none;
		-moz-appearance: none;
		transition: color 0.2s;

		&:hover {
			color: #5f;
			fill: fab-color(gray6);
			+encoreHighSpecificity() {
				color: unset;
				fill: unset;
			}
		}
	}

	&__content {
		fab-font-size: small;
		font-weight: normal;
		fab-line-height: small;
		min-width: 150px;
		max-width: 200px;
	}

	&__box--small &__content{
		fab-font-size: teenie;
		fab-line-height: teenie;
		margin-top: 4px;

		ul {
			list-style-type: disc;
			margin-left: 20px;
			margin-bottom: 10px;
		}
	}

	&__box {
		background-color: fab-color(white);
		border: 1px solid fab-color(gray1);
		border-radius: 3px;
		box-sizing: border-box;
		box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);
		color: #555;
		cursor: default;
		display: block;
		padding: 10px 25px;
		text-align: left;
		z-index: 1100;
		transform: translate3d(0, 0, 0); // Fix for scrollbar in Chrome
		+encoreHighSpecificity() {
			border-color: $fabEncoreColors.gray3;
			border-radius: 8px;
			box-shadow: rgba(56, 49, 47, 0.1) 3px 3px 10px 2px;
			padding: 20px 12px 20px 20px;
		}

		&--small {
			width: 208px;
		}

		&,
		&:before,
		&:after {
			position: absolute;
			transition: opacity 0.3s;
		}

		&:before,
		&:after {
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			content: "";
			left: calc(50% - 10px);
			height: 0;
			width: 0;
		}

		&:before {
			border-top: 10px solid fab-color(gray3);
			bottom: -11px;
		}

		&:after {
			border-top: 11px solid #fff;
			bottom: -10px;
		}

		&[placement="bottom"] {
			&:before,
			&:after {
				transform: rotate(180deg);
			}

			&:before {
				top: -11px;
			}

			&:after {
				top: -10px;
			}
		}

		&[placement="right"] {
			&:before,
			&:after {
				top: calc(50% - 5px);
				transform: rotate(90deg);
			}

			&:before {
				left: -16px;
			}

			&:after {
				left: -15px;
			}
		}

		&[placement="left"] {
			&:before,
			&:after {
				top: calc(50% - 5px);
				transform: rotate(270deg);
			}

			&:before {
				left: calc(100% - 4px);
			}

			&:after {
				left: calc(100% - 5px);
			}
		}

		&[placement="topleft"] {
			&:before,
			&:after {
				top: calc(33% - 5px);
				transform: rotate(270deg);
			}

			&:before {
				left: calc(100% - 4px);
			}

			&:after {
				left: calc(100% - 5px);
			}
		}
		&[placement="bottomleft"] {
			&:before,
			&:after {
				transform: rotate(180deg);
			}
				&:before {
					top: -11px;
					left: calc(100% - 35px); // maybe
				}

				&:after {
					top: -10px;
					left: calc(100% - 35px);
				}
		}
		&.popover-hide {
			&,
			&:before,
			&:after {
				opacity: 0;
				visibility: hidden;
			}
		}
	}
	// .bhrPopover__box.bhrPopover__box--encore
	&__box&__box--encore {
		// .bhrPopover__box.bhrPopover__box--encore .bhrPopover__close
		.bhrPopover__close {
			align-items: center;
			background-color: fab-color(white);
			border: 1px solid $fabEncoreColors.gray4;
			border-radius: 100px;
			bottom: unset;
			color: unset;
			display: inline-flex;
			fill: unset;
			font-size: 13px;
			height: 28px;
			justify-content: center;
			left: unset;
			margin-left: spacing(1);
			min-width: 36px;
			padding: 8px 12px;
			position: unset;
			right: unset;
			top: unset;
		}

		// .bhrPopover__box.bhrPopover__box--encore .bhrPopover__content
		.bhrPopover__content {
			max-width: 284px;
		}

		// .bhrPopover__box.bhrPopover__box--encore .bhrPopover__header
		.bhrPopover__header {
			align-items: center;
			display: flex;
			justify-content: space-between;
			margin-bottom: spacing(1);
		}

		// .bhrPopover__box.bhrPopover__box--encore .bhrPopover__title
		.bhrPopover__title {
			margin: 0;
		}

		// .bhrPopover__box.bhrPopover__box--encore:before,
		// .bhrPopover__box.bhrPopover__box--encore:after
		&:before,
		&:after {
			content: none;
		}
	}
}
