{featureToggleOn("jade")} {
	.Pill {
		border: 1px solid fab-color(gray2);
		border-radius: 3px;
		fab-color: gray10;
		margin: 5px 0 0;
		padding: 2px 6px;
		display: inline-block;
		position: relative;

		&__name {
			max-width: 190px;
			display: block;
		}
		&__remove {
			position: relative;
			fab-color: gray8;
			fab-text: large;
			font-wieght: 600;
			padding-left: 2px;
			top: 1px;
			transition: color 0.15s linear;

			&--truncate {
				position: absolute;
				right: 5px;
			}

			&:hover {
				cursor: pointer;
				fab-color: gray10;
			}
		}

		&.disabled {
			width: auto;

			.Pill__remove {
				color: transparent;
				opacity: 0;
				visibility: none;
				display: hidden;
				margin-right: -8px;
				padding: 0;


				&:hover {
					cursor: inherit;
				}
			}
		}

		&--truncate {
			max-width: 200px;
			width: auto;
			padding-right: 20px;
		}

		&--gray {
			fab-background-color: gray3;
		}

		&--space {
			& + & {
				margin-left: 10px;
			}
		}
	}
}
