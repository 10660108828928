.ProgressBar {
	height: 8px;
	border: none;
	// @startCleanup encore
	background-color: fab-color(gray3);
	border-radius: 11px;
	// @endCleanup encore
	+encore() {
		background-color: var(--gray2);
		border-radius: 1000px;
	}

	&--small {
		// @startCleanup encore
		height: 3px;
		border-radius: 6px;
		// @endCleanup encore
		+encore() {
			height: 4px;
			border-radius: 1000px;
		}
	}

	&--large {
		height: 16px;
	}

	&--center {
		margin:auto;
	}

	&--tasklist {
		width: 135px;
		height: 10px;
		margin: 2px 23px 0 5px;
		border: 1px solid fab-color(gray4);
		fab-background-color: gray4;

		+encore() {
			fab-background-color: gray2;
		}

	}
	/*
		NOTE:
		Per Tony this style will match the new standard progress bar in the design system.
		Adding as a stopgap until we get there.
		Currently used in the new Feeds component /ui-src/public/_components/file-list.react/index.js
	*/
	&--feeds {
		width: 115px;
		height: 3px;
		border-radius: 3px;
		margin: 0 12px 0 5px;
		border: none;
		background-color: #CCC;
	}

	&--complete &__fireworks {
		position: absolute;
		background: url("https://staticfe.bamboohr.com/resources/images/icons/fireworks.gif") no-repeat;
		width: 100px;
		height: 100px;
		top: -100px;
		left: -35px;
	}

	/*---------------------------------*\
	.ProgressBar--stepped

	Progress bar with steps on hover,
	% progress label and
	100% complete state
	/*---------------------------------*/
	&--stepped {
		position: relative;
		height: 20px;
		border-radius: 4px;
		border-color: #b8;
		background: #ea;
		background: linear-gradient(to bottom,  #ea 0, #fff 100%);
		box-shadow: inset 0 1px 1px -1px rgba(0, 0, 0, 0.21),
					inset 0 -1px 1px -1px rgba(0, 0, 0, 0.15);
		box-sizing: border-box;

		&.ProgressBar--disabled {
			cursor: default;
		}

		.ProgressBar__steps {
			display: none;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;

			&-step {
				float: left;
				height: 18px;
				border-right: solid #ccc 1px;
				box-sizing: border-box;

				&:last-child {
					border: none;
				}
			}
		}

		&:hover,
		&.ProgressBar--hover {
			.ProgressBar__steps {
				display: block;
			}
		}
	}


	&__bar {
		position: relative;
		width: 0;
		height: inherit;
		transition: all 1s ease-in-out;
		// @startCleanup encore
		border-radius: 11px;
		fab-gradient: theme-lightest theme-lighter;
		// @endCleanup encore
		+encore() {
			background: var(--fabric-theme-base);
			border-radius: 1000px;
		}

		^[0]--small &::after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			// @startCleanup encore
			fab-gradient: theme-lightest theme-lighter;
			height: 3px;
			border-radius: 6px;
			// @endCleanup encore
			+encore() {
				background: var(--fabric-theme-base);
				border-radius: 1000px;
				height: 4px;
			}
		}

		&--tasklist {
			border-radius: 11px;
			fab-gradient: theme-lightest theme-lighter;

			+encore() {
				background-image: none;
				background-color: var(--fabric-theme-base);
			}
		}

		&--EEUploads {
			fab-gradient: theme-lightest theme-lighter;
		}

		&--full {
			border-radius: 2px;
		}

		&-status {
			position: absolute;
			margin: -18px 0 0 -22px;
			margin: -18px -25px 0 -22px;
			display: inline-block;
			padding: 3px 0 3px 1px;
			height: 38px;
			width: 46px;
			text-align: center;
			font-size: 14px;
			font-weight: 700;
			background: url("https://staticfe.bamboohr.com/resources/images/icons/icon-bubble.png") no-repeat;
			background-position: -46px 0;
			box-sizing: border-box;

			.ProgressBar & {
				&--100 {
					top: 0;
					right: 0;
				}
			}

			.ProgressBar:hover:not(.ProgressBar--disabled) &,
			.ProgressBar--hover:not(.ProgressBar--disabled) & {
				color: #fff;
				background-position: 0 0;
			}

			.ProgressBar--complete &,
			.ProgressBar--complete:hover &,
			.ProgressBar--complete.ProgressBar--hover & {
				text-indent: -3000px;
				background-position: -93px 0;
			}
		}

		&-progress {
			display: inline-block;
			height: 20px;
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
			border-style: solid;
			border-width: 1px 0 1px 1px;
			box-sizing: border-box;

			.ProgressBar--complete &:not(&--hover) {
				background: #aad67a;
				border-color: #78a445;
				transition: background 0.25s ease-in-out, border-color 0.25s ease-in-out;
			}

			&--empty {
				border-width: 0;
				border-color: transparent;
				background-color: transparent;
			}

			&--hover {
				width: 0;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;

				.ProgressBar--complete & {
					border: 0;
				}
			}

			&--roundend {
				border-left-width: 1px;
				border-top-left-radius: 3px;
				border-bottom-left-radius: 3px;
			}
		}

		&-hover {
			&--hide {
				width: 0;
				border: 0;
			}

			&--fixed {
				position: absolute;
				right: 0;
			}
		}

		.ProgressBar--stepped &:not(.ProgressBar--disabled) {
			position: absolute;
			top: 0;
			left: 0;
			display: none;
			margin: -1px 0 0 -1px;
			width: 100%;
			cursor: pointer;
		}

		.ProgressBar--stepped.ProgressBar--disabled & {
			cursor: default;
		}
	}

	&__label {
		display: inline-block;
		margin: 2px 6px;
		color: #a4;
		font-size: 13px;

		&2 {
			.ProgressBar--complete & {
				position: relative;
				z-index: 1;
				color: #357b00;
				margin-right: 17px;
			}
		}
	}

	//.ProgressBar--dark
	&--dark {
		position: relative;
		left: 1px;
		height: 8px;
		background: #6c;
		border-radius: 12px;
		border: none;

		& ^[0]__bar {
			left: -1px;
			padding-right: 2px;
			background: #f1;
			border-radius: 12px 0 0 12px;

			&--full {
				border-radius: 12px;
			}
		}
	}

	//.ProgressBar--skinny
	// Used for Jade
	&--skinny {
		border: none;
		// @startCleanup encore
		fab-background-color: gray2;
		// @endCleanup encore
		+encore() {
			background-color: var(--gray2);
		}

		&.ProgressBar__parent {
			height: 4px;
		}

		& ^[0]__bar {
			// @startCleanup encore
			fab-gradient: theme-lighter theme-base;
			// @endCleanup encore
			+encore() {
				background-color: var(--gray2);
			}
		}
	}
}
