@require "./animation";
$rootSpacingUnit = 8;

edge-shadow($position, $size = 28px, $pseudo = 'after') {
	position: relative unless @position;

	$width = $size;

	&:{$pseudo} {
		content: "";
		position: absolute;
		display: block;
		z-index: 1000;
		transition: all 0.3s linear;
		border-radius: $size * 0.75;

		if $position == 'none' {
			box-shadow:
				0 0 0 rgba(#000, 0.6),
				0 0 0 rgba(#000, 0.6);
			border-radius: 0;
		}
		else {
			{$position}: 0;
			outline: $size solid transparent;

			if $position in ('left' 'right') {
				top: 0;
				bottom: 0;
				width: $width;
			}
			else if $position in ('top' 'bottom') {
				left: 0;
				right: 0;
				height: $width;
			}

			box-shadow: 0 0 $size rgba(#000, 0.35), 0 0 ($size / 3) rgba(#000, 0.35);

			$offset = $size + $width;

			if $position == 'left' {
				/*
				*  Turns out clip-path isn't ready for the big time,
				*  commenting out until browser support catches up
				*/
				// clip-path: polygon(
				// 	0 $size,
				// 	$size $size,
				// 	$size "calc(100% - %s)" % $size,
				// 	0 "calc(100% - %s)" % $size
				// );
				// -webkit-clip-path: @clip-path;
				clip: rect(0, 0, 10000px, -($size));
			}
			else if $position == 'right' {
				// clip-path: polygon(
				// 	$offset $size,
				// 	100% $size,
				// 	100% "calc(100% - %s)" % $size,
				// 	$offset "calc(100% - %s)" % $size
				// );
				// -webkit-clip-path: @clip-path;
				clip: rect(0, $offset, 10000px, $width);
			}
			else if $position == 'top' {
				// clip-path: polygon(
				// 	$size 0,
				// 	"calc(100% - %s)" % $size 0,
				// 	"calc(100% - %s)" % $size $size,
				// 	$size $size
				// );
				// -webkit-clip-path: @clip-path;
				clip: rect(-($size), 10000px, 0, 0);
			}
			else if $position == 'bottom' {
				// clip-path: polygon(
				// 	$size $offset,
				// 	"calc(100% - %s)" % $size $offset,
				// 	"calc(100% - %s)" % $size 100%,
				// 	$size 100%
				// );
				// -webkit-clip-path: @clip-path;
				clip: rect($width, 10000px, $offset, 0);
			}
		}
	}
}

n-siblings($content, $start = 1, $count = 6) {
	for $n in ($start..$count) {
		&:nth-last-child({$n}) {
			&,
			& ~ * {
				$content($n);
			}
		}
	}
}

border-top-radius($radius) {
	border-top-left-radius: $radius;
	border-top-right-radius: $radius;
}

border-right-radius($radius) {
	border-top-right-radius: $radius;
	border-bottom-right-radius: $radius;
}

border-bottom-radius($radius) {
	border-bottom-left-radius: $radius;
	border-bottom-right-radius: $radius;
}

border-left-radius($radius) {
	border-top-left-radius: $radius;
	border-bottom-left-radius: $radius;
}

truncate() {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

jade() {
	{featureToggleOn('jade')} & {
		{block};
	}
}

legacy() {
	{featureToggleOff('jade')} & {
		{block};
	}
}

encore() {
	{fabricThemeEnabled('encore')} & {
		{block};
	}
}

jadeFabricTheme() {
	{fabricThemeEnabled('jade')} & {
				{block};
	}
}

// for styles that need to trump +jade() styles temporarily and will be removed in Encore
encoreHighSpecificity() {
	{fabricThemeEnabledHighSpecificity('encore')} & {
		{block};
	}
}

spacing($spacingUnit) {
	return unit($spacingUnit * $rootSpacingUnit, 'px');
}
