$layout = json('@bamboohr/fabric/dist/definitions/json/layout.json', { hash: true });

.PageHeader {
	box-sizing: border-box;
	display: flex;
	justify-content: flex-start;
	flex-flow: column nowrap;
	text-align: left;
	// @startCleanup encore
	padding-bottom: 5px;
	// @endCleanup encore
	border-bottom: 1px solid $fabColors.hex.gray4;
	min-width: 0; // Fix for min-width iside of min-width
	top: 0;

	+encore() {
		padding-bottom: spacing(4);
	}

	// .PageHeader--backButton
	+encore() {
		&--backButton {
			padding-top: 0;
		}
	}

	// .PageHeader--noBorder
	&--noBorder {
		border-bottom: 0;
	}

	&--borderPadding {
		padding-bottom: 175px !important;
		+encore() {
			padding-bottom: unset !important;
		}
	}

	// @startCleanup encore
	// .PageHeader--sm
	&--sm {
		min-height: 89px;
		max-height: 105px;
		+encore() {
			min-height: unset;
			max-height: unset;
		}
	}
	// .PageHeader--md
	&--md {
		min-height 144px;
		max-height: 161px;
		+encore() {
			min-height: unset;
			max-height: unset;
		}
	}
	// @endCleanup encore
	// .PageHeader--mdBrand,
	// .PageHeader--lg
	&--mdBrand,
	&--xl,
	&--lg {
		height: 196px;
		position: relative;
		fab-color: white;
		fab-fill: white;
		// @startCleanup encore
		padding-bottom: 80px;
		// @endCleanup encore
		+encore() {
			padding-bottom: 80px;
			padding-top: 0;
		}
		// .PageHeader--mdBrand > *,
		// .PageHeader--lg > *
		> * {
			z-index: 1;
		}
		// .PageHeader--mdBrand:before,
		// .PageHeader--mdBrand:after,
		// .PageHeader--lg:before,
		// .PageHeader--lg:after
		&:before,
		&:after {
			content: '';
			width: 100vw;
			position: absolute;
			left: 50%;
			right: 50%;
			margin-left: -50vw;
			margin-right: -50vw;
			+encore() {
				width: unset;
				top: calc(var(--page-capsule-padding) * -1);
				left: calc(var(--page-capsule-padding) * -1);
				right: calc(var(--page-capsule-padding) * -1);
				margin-left: unset;
				margin-right: unset;
			}
		}
		// .PageHeader--mdBrand:before,
		// .PageHeader--lg:before
		&:before {
			height: 196px;
			// @startCleanup encore
			fab-gradient-page-header: theme-lighter theme-base;
			// @endCleanup encore
			+encore() {

				//@startCleanup encore
				// This isn't needed once the fab-gradient-page-header mixin is removed above
				background-image: unset;
				// @endCleanup encore
				background-color: var(--fabric-theme-base);
			}
		}
		// .PageHeader--mdBrand:after,
		// .PageHeader--lg:after
		&:after {
			bottom: 0;
			height: 3px;
			opacity: 0.5;
			background: linear-gradient(180deg, rgba(0,0,0,0) 0, rgba(0,0,0,0.1) 100%);
		}
		// .PageHeader--mdBrand .PageHeader__backBtn,
		// .PageHeader--lg .PageHeader__backBtn
		.PageHeader__backBtn {
			fab-color: white;
			fab-fill: white;
		}
		//.PageHeader--mdBrand .PageHeader__title,
		//.PageHeader--lg .PageHeader__title
		.PageHeader__title {
			fab-color: white;
			&Icon {
				fab-fill: white;
			}
		}
	}
	// .PageHeader--rounded
	&--rounded {
		+encore() {
			&:before {
				border-radius: var(--radius-600)
				left: calc(var(--page-capsule-padding) * -1);
			}
			&:after {
				display: none
			}
		}
	}
	// .PageHeader--mdBrand
	&--mdBrand {
		height: 200px;
		// .PageHeader--mdBrand:before
		&:before {
			height: 200px;
		}
		// .PageHeader--mdBrand .PageHeader__titleWrap
		.PageHeader__titleWrap {
			margin-top: 32px;
			+encore() {
				margin-top: unset;
			}
		}
	}
	// .PageHeader--lg
	&--lg {
		//.PageHeader--lg .PageHeader__title
		.PageHeader__title {
			line-height: 48px;
			//.PageHeader--lg .PageHeader__titleIcon
			&Icon {
				margin-top: 4px;
			}
		}
	}
	// .PageHeader--xl
	&--xl {
		+encore(){
			height: var(--page-header-height-xl);
			// .PageHeader--xl:before
			&:before {
				height: calc(var(--page-header-height-xl) + var(--page-capsule-padding));
			}
		}
		// .PageHeader--xl .PageHeader__titleWrap
		.PageHeader__titleWrap {
			margin-top: 32px;
			+encore() {
				margin-top: unset;
			}
		}
	}
	// .PageHeader__backBtn
	&__backBtn {
		display: flex;
		align-items: center;
		margin-right: auto;
		// @startCleanup encore
		margin-bottom: 18px;
		margin-top: 12px;
		fab-text: small;
		// @endCleanup encore
		fab-color: gray7;
		fab-fill: gray7;
		+encore() {
			fab-text: teenie;
			margin-top: 0;
			margin-bottom: spacing(2);
		}
		// .PageHeader__backBtnArrow
		&Arrow {
			// @startCleanup encore
			margin-right: 6px;
			// @endCleanup encore
			+encore() {
				margin-right: spacing(1);
			}
		}

		& + .PageHeader__titleWrap {
			+encore() {
				margin-top: 0;
			}
		}
	}
	// .PageHeader__left
	&__left {
		flex: 1;
		overflow: hidden;
		// .PageHeader__left--sameLineSubTitle
		&--sameLineSubTitle {
			align-items: baseline;
			display: flex;
		}
	}
	// .PageHeader__title
	&__title {
		margin: 0;
		// @startCleanup encore
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		// @endCleanup encore
		+encore() {
			fab-text: h1;
			font-weight: $fabEncoreText.textWeights.bold;
			// @startCleanup encore
			overflow: unset;
			text-overflow: unset;
			white-space: normal;
		// @endCleanup encore
		}

		// .PageHeader__titleWrap
		&Wrap {
			display: flex;
			align-items: center;
			// @startCleanup encore
			margin-top: auto;
			// @endCleanup encore
			margin-bottom: 10px;
			+encore() {
				margin-top: unset;
				margin-bottom: unset;
			}
		}
		// .PageHeader__titleIcon
		&Icon {
			display: inline-flex;
			line-height: 0;
			// @startCleanup encore
			margin-right: 12px;
			// @endCleanup encore
			fab-fill: theme;
			+encore() {
				margin-right: 8px;
			}
		}
		// .PageHeader__titleSecondary
		&Secondary {
			margin-left: 8px;
		}

		// .PageHeader__titleSubTitleWrap
		&SubTitleWrap {
			&--sm, &--md, &--mdBrand {
				display: flex;
				align-items: baseline;
			}
		}
		// .PageHeader__title--secondaryInline
		&--secondaryInline {
			display: flex;
			align-items: baseline;
		}
	}
	// .PageHeader__subTitle
	&__subTitle {
		// @startCleanup encore
		fab-text: biggie;
		fab-color: gray7;
		// @endCleanup encore
		padding: 0;
		margin: 0 0 0 8px;
		+encore() {
			fab-color: gray6;
			fab-font-weight: semibold;
			fab-text: h3;
			font-family: $encoreHeaderFontFamilyStack;
			margin-left: 24px;
		}

		&--large {
			margin: 0;
			fab-color: white;
			fab-font-weight: medium;
		}
	}
	// .PageHeader__optionalContent
	&__optionalContent {
		// .PageHeader__optionalContentWrap
		&Wrap {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			padding-bottom: 11px;
			padding-top: 2px;
			box-sizing: border-box;
			height: 50px;
			// hide this element if ALL of the child div blocks end up being empty at runtime
			&:not(:has(:not(:empty))) {
				display: none;
			}
			+encore() {
				margin-top: 20px;
				padding: unset;
				height: unset;
			}
		}
		// .PageHeader__optionalContent--right
		&--right {
			margin-left: auto;
			align-self: flex-end;
		}
	}
	&--homeFixed {
		position: sticky;
		z-index: 200;
		border-bottom: none;
		transition: top ease-in-out 0.2s;
		height: 54px;
		padding-bottom: 0;

		&:before {
			height: 54px;
		}
		&:after {
			bottom: 146px;
		}

		.PageHeader__titleWrap {
			margin: 0;
		}

		.PageHeader__optionalContent--right {
			position: relative;
			top: 10px;
		}

		.PageHeader__homeFixedheightPreserve {
			background: blue;
			height: 300px;
		}
	}
}
// @startCleanup encore
#content .PageHeader {
	margin-top: -29px;
	+encore() {
		margin-top: 0;
	}
}

&.content-tight-top #content  .PageHeader {
	margin-top: 0;
}
// @endCleanup encore

+encore() {
	&.HomeFeature #content .PageHeader {
		&--mdBrand,
		&--xl,
		&--lg {
			fill: unset;
			height: unset;
			margin-top: 0;
			padding-bottom: 0;
		}
		&:before {
			background-color: unset;
		}
		&:after {
			display: none;
		}
		&__titleWrap {
			margin-bottom: 32px;
			margin-top: unset;
		}
		&__optionalContent--right {
			align-self: center;
		}
	}
	&.content-tight-top #content .PageHeader {
		margin-top: 0;
	}

	&.employeeSection #content .PageHeader {
		&--lg {
			height: var(--page-header-height-lg);
			padding-bottom: unset;
			&:before {
				border-radius: var(--radius-600)
				margin-left: 0;
				margin-right: 0;
				width: unset;
				left: calc(var(--page-capsule-padding) * -1);
			}

			&:after {
				display: none
			}
		}

		&.PageHeader__looping-nav {
			margin-top: var(--page-header-looping-offset-top);			
			
			&:before {
				height: calc(var(--page-capsule-padding) + var(--page-header-looping-offset-top) + var(--page-header-height-lg)); 
				top: calc((var(--page-capsule-padding) + var(--page-header-looping-offset-top)) * -1);
			}
		}

		&:before {
			height: calc(var(--page-capsule-padding) + var(--page-header-height-lg));
		}

		&__title {
			margin-bottom: 4px;
		}

		&__optionalContent {
			// .PageHeader__optionalContent--right
			&--right {
				margin-left: auto;
				align-self: flex-start;
			}
		}
	}
}

@media screen and (max-width: ($layout.pageWidths.min + ($layout.pageWidths.buffer * 2))px) {
	.PageHeader--mdBrand,
	.PageHeader--lg {
		&:before,
		&:after {
			min-width: ($layout.pageWidths.min)px;
			left: -($layout.pageWidths.buffer)px;
			right: 0;
			margin-left: 0;
			margin-right: 0;
		}
	}
}
