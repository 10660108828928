.Avatar {
	display: inline-block;
	overflow: hidden;
	border-radius: 12px;
	box-shadow: 1px 1px 0 1px rgba(56, 49, 47, 0.04);
	fab-background-color: gray6;

	&__iconHover {
		+jade() {
			fill: fab-color(gray5);
		}
	}

	&__iconHover.qsPhotoGroup-hover {
		+jade() {
			fab-fill: white;
		}
	}

	// New Avatar styles for encore
	// Please only use these approved sizes
	&__fab-Avatar--32 {
		height: 32px
		width: 32px
		border-radius: 8px;
		box-shadow: 1px 1px 0 1px rgba(56, 49, 47, 0.04);
	}
	&__fab-Avatar--40 {
		height: 40px
		width: 40px
		border-radius: 8px;
		box-shadow: 1px 1px 0 1px rgba(56, 49, 47, 0.04);
	}
	&__fab-Avatar--48 {
		height: 48px
		width: 48px
		border-radius: 12px;
		box-shadow: 1px 1px 0 1px rgba(56, 49, 47, 0.04);
	}
	&__fab-Avatar--56 {
		height: 56px
		width: 56px
		border-radius: 8px;
		box-shadow: 1px 1px 0 1px rgba(56, 49, 47, 0.04);
	}
	&__fab-Avatar--64 {
		height: 64px
		width: 64px
		border-radius: 16px;
		box-shadow: 1px 1px 0 2px rgba(56, 49, 47, 0.03);
	}
	&__fab-Avatar--72 {
		height: 72px
		width: 72px
		border-radius: 16px;
		box-shadow: 1px 1px 0 2px rgba(56, 49, 47, 0.03);
	}
	&__fab-Avatar--80 {
		height: 80px
		width: 80px
		border-radius: 16px;
		box-shadow: 1px 1px 0 2px rgba(56, 49, 47, 0.03);
	}
	&__fab-Avatar--96 {
		height: 96px
		width: 96px
		border-radius: 20px;
		box-shadow: 1px 1px 0 2px rgba(56, 49, 47, 0.03);
	}
	&__fab-Avatar--128 {
		height: 128px
		width: 128px
		border-radius: 20px;
		box-shadow: 1px 1px 0 2px rgba(56, 49, 47, 0.03);
	}
	&__fab-Avatar--160 {
		height: 160px
		width: 160px
		border-radius: 20px;
		box-shadow: 2px 2px 0 2px rgba(56, 49, 47, 0.05);
	}
	&__fab-Avatar--224 {
		height: 224px
		width: 224px
		border-radius: 24px;
		box-shadow: 2px 2px 0 2px rgba(56, 49, 47, 0.05);
	}
}
