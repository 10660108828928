.Balloon--tooltip {

	// @startCleanup encore
	border-radius: 2px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
	// @endCleanup encore

	+encore() {
		border-radius: 8px;
	}

	.Balloon {

		&__border {
			display: none;
		}

		// @startCleanup encore
		&__tail {
			background-color: #64;
			border: none;
			box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
			box-sizing: border-box;
			+encore() {
				display: none;
			}
		}
		// @endCleanup encore

		&__content {
			// @startCleanup encore
			background-color: #64;
			border-radius: 2px;
			color: #f;
			// @endCleanup encore

			+encore() {
				border-radius: 8px;
				box-shadow: 4px 4px 6px 2px rgba(56 49 47, 0.12);
				background-color: var(--gray9);
				color: var(--white);
			}
		}
	}
}

/********************************************************
Standard Tooltip
********************************************************/
.TooltipStandard {
	// @startCleanup encore
	font-size: 12px;
	line-height: 15px;
	padding: 6px 10px;
	// @endCleanup encore
	max-width: 174px;

	+encore() {
		font-size: var(--font-size-small);
		line-height: var(--line-height-small);
		max-width: 263px;
		padding: 12px;
		text-align: center;
	}

	&--textLeft {
		text-align: left;
	}

	&--textRight {
		text-align: right;
	}

	&--textCenter {
		text-align: center;
	}

	&__title {
		// @startCleanup encore
		font-size: 13px;
		font-weight: 600;
		line-height: 17px;
		margin: 0;
		// @endCleanup encore

		+encore() {
			font-size: var(--font-size-medium);
			font-weight: var(--font-weight-bold);
			line-height: var(--line-height-medium);
			margin-bottom: 2px;
		}
	}
}


{fabricThemeEnabled('jade')} {
	.Balloon--tooltip {

		.Balloon {

			&__tail {
				fab-color('background-color', gray8);
			}

			&__content {
				fab-color('background-color', gray8);
				fab-color: white;
			}
		}
	}
	.Balloon--tooltip-info {
		@extends .Balloon--tooltip;

		.Balloon {

			&__tail {
				fab-color('background-color', info);
			}

			&__content {
				fab-color('background-color', info);
			}
		}
	}
	.TooltipStandard {
		fab-text: teenie;
		padding: 8px 15px 10px;

		&__title {
			fab-text: small;
			fab-font-weight: bold;
		}
	}

}
