#available,#selected {
	overflow-y: auto;
	overflow-x: hidden;
	margin: 0;
	border: 1px solid fab-color(gray4);
	position: relative;
	border-radius: 16px;
	width: 320px;
	height: 384px;
	fab-background-color: gray1;
}
.customize-add-ee-form #available, .customize-add-ee-form #selected{
  height: 465px;
}
#available > li {
	padding:0 0 0 0;
}
#available .drag-list li {
	position: relative;
	cursor:pointer;cursor: hand;
	-moz-user-select: none;
}

#available .drag-list li svg {
	display: none;
}

.selector-page .showHide{
	display: flex;
	width: 100%;
	-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
	vertical-align: middle;
	border-top: 1px solid transparent;
	border-bottom: 1px solid fab-color(gray4);
	font-size: 14px;
	font-weight: 600;
	color: fab-color(gray9);
	padding-left: 24px;
	position: relative;
	align-items: center;
	fab-font-size: large;
	line-height: 24px;
	background-color: #F6F6F4!important;
	height: 48px;
	svg {
		margin-right: 8px;
		transition-duration: 100ms;
	}
}
.selector-page .showHide:hover{
	text-decoration:none;
}

.selector-page li, .selector-page li{
	-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
	vertical-align:middle;
	min-height: 48px;
	line-height: 48px;	
}
.selector-page:hover .showHide {
	fab-color: theme-base;
	fab-background-color: gray2;	
	svg {
		transition-duration: 100ms;
		fab-fill: theme-base;
	}	
}
.selector-page:not(.collapsed) {
	& + .selector-page .showHide {
		border-top-color: fab-color(gray4);
	}
	.showHide svg {
		transform: rotate(90deg);
		transition-duration: 100ms;
		fab-fill: theme-base;
	}
}

ul.drag-list {
	margin: 0;
	position: relative;
}
.drag-list li {
	background:#ffffff none;
	border-top:1px solid #d9d9d9;
	font-weight: normal;
	font-size:15px;
	min-height: 36px;
	line-height: 36px;
	text-overflow: ellipsis;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	padding: 0 23px 0 0;
	text-indent: 20px;
}
.drag-list li.permanent {
	background: #f6f6f6;
	background: linear-gradient(to bottom, #f6f6f6 0, #f9f9f9 100%);
	pointer-events: none;
}
.drag-list li.permanent span.fieldName {
	color: #B9B9B9;
}

.drag-list li.tableField { text-indent: 13px; }
.drag-list li:not(.tableField):first-child{border-top: 1px solid fab-color(gray1);}

.drag-list li.ui-sortable-helper { border-bottom:solid #d9d9d9 1px; }
.drag-list li.ui-sortable-placeholder {
	border:none !important;
	border-top:solid #d9d9d9 1px !important;
	visibility:visible !important;
	background-color:transparent !important;
}
.drag-list > li.ui-sortable-placeholder:last-child,
.drag-list > li.ui-sortable-helper:last-child {
	box-shadow:none;
}
.drag-list > li.ui-sortable-placeholder:nth-last-child(2),
.drag-list > li.ui-sortable-placeholder:last-child {
	box-shadow:inset 0 2px 2px rgba(0,0,0,.12);
	border-bottom: none;
}
.drag-list > li.ui-sortable-helper ~ li[style="position: relative;"] + li.ui-sortable-placeholder:nth-last-child(2) { box-shadow:none; }

.subDragList li{
	border:solid 1px #d9d9d9;
	border-bottom:none;
	color: #222;
	margin-left: 0;
}
.subDragList li.ui-sortable-helper, .subDragList li.ui-sortable-placeholder ~ li:nth-last-child(2) { border-bottom:solid #d9d9d9 1px; }
.subDragList li.ui-sortable-placeholder { border:none !important; border-top:solid #d9d9d9 1px !important; visibility:visible !important; }
.subDragList li.ui-sortable-placeholder:first-child,
.subDragList li.ui-sortable-helper:first-child + li.ui-sortable-placeholder {
	border:none !important;
}
.subDragList li.ui-sortable-placeholder ~ li:not(.ui-sortable-helper):last-child { border-top:none; }
.subDragList li.ui-sortable-placeholder + li:not(.ui-sortable-helper):last-child,
.subDragList li.ui-sortable-helper + li:not(.iu-sortable-helper):last-child {
	border-top:solid #d9d9d9 1px;
}
.subDragList { border-bottom:1px solid #d9d9d9; }
.tableLabel{
	line-height: 30px;
	font-weight: 600;
	max-width: 270px;
	overflow: hidden;
	text-overflow: ellipsis;
	.tableHeaderCheckbox {		
		margin-left: 0px;
	}
}
.remove , .config{
	display: none;
}
/*#available li.isHovering {
  color: #fff;
	font-weight:bold;
}*/
li.field { position: relative; }
#available .drag-list li.field.isHovering svg,
#available .drag-list li.field:hover svg,
#available .subTable.isHovering > .tableLabel svg {
	fab-fill: theme-base;
	display: block;
	right: 10px;
	top: 14px;
	z-index:5;
}

.customize-add-ee-form #selected .subTable.isHovering{
	fab-background-color: gray1;
}
.subTable.isHovering > .tableLabel:after{right:16px}
.tableField{
	margin-left: 1px;
}
.tableFieldEllipses {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.drag-list li.subTable, #selected li.subTable{
	padding: 0 24px 20px;
	text-indent: 0;
	fab-font-size: medium;
	color: #676260;

	.tableLabel {		
		padding-top: 0px!important;
		padding-left: 20px;
	}
}
.customize-add-ee-form .drag-list li.subTable, .customize-add-ee-form #selected li.subTable{ padding-bottom: 11px;}
#selected.drag-list li {
	cursor: move;
	position: relative;
}
#selected li:hover  a.remove, #reportWizard #selected li:hover  a.config{
	display: inline-block;
}

#selected li.tableField  a.remove, #selected li.tableField  a.config{
	display: none;
}
#selected li.tableField:hover a.remove,
#selected li.tableField:hover a.config {
	display: inline-block;
	svg {
		display: inline-block;
	}
}

#selected.drag-list:not(.nohover) > li:not(.subTable):hover:before,
#selected.drag-list:not(.nohover) li.subTable.isHovering:not(.ui-sortable-placeholder):before,
#selected.drag-list:not(.nohover) li.subTable.dragIcon:not(.ui-sortable-placeholder):before {
	content: '';
	display: block;
	background: url("https://staticfe.bamboohr.com/resources/images/icon-drag.png") no-repeat;
	width: 9px;
	height: 10px;
	position: absolute;
	top: 13px;
	left: 20px;
}
#selected.drag-list li.subTable.isHovering:before {
	top:10px;
	left:6px;
}
#selected.drag-list li {
	align-items: center;
	display: flex;

	&.subTable {
		display: block;
	}
}

#selected.drag-list li a {	
	position: absolute;
	text-indent: 100px;
	overflow-y: hidden;	
	height: 16px;
	width: 16px;
	text-indent: 0px;
	overflow: visible;
	top: 2px;
	right: 12px;
	background: transparent;
	background-image: none;
	background-position: null;	
}

#selected .subTable > a.remove{
	top:10px;	
	height: 16px;
	width: 16px;
	background: transparent;
	text-indent: 0px;
	overflow: visible;
	right: 12px;
	background-image: none;
	background-position: null;
	right: 24px;	
}

#selected.drag-list li a:hover{background-position:left bottom}

.historicalSettings input{
	cursor: pointer;
}
#available .historicalSettings{
	display: none;
}
.historicalSettings div{
	height: 30px;
	width: 30px;
}
.historicalSettings input + label{font-size:14px; color: #777; margin-left: 5px; }

#selected.drag-list li a.config{
	background-image: url("https://staticfe.bamboohr.com/resources/images/reports/export_gears.png");
	height: 12px;
	right: 26px;
	text-indent: 6px;
	top: 12px;
	width: 12px;
	background-position: 0 12px;
}
#selected.drag-list li a.config.hasCustom{
	background-position: 0 0;
}

.colRowIcon{
	background-image: url("https://staticfe.bamboohr.com/resources/images/reports/reports-sprite.png");
	height: 24px;
	width: 35px;
	display: inline-block;

}
.colRowIcon.fieldSelect{ background-position: 0 0; }
.colRowIcon.filterSelect{	background-position: 0 -26px; }
.colRowIcon.sortSelect{	background-position: 0 -51px; }
.colRowIcon.groupSelect{ background-position: 0 -76px; }

.fieldsetIndent-icon > legend {
    line-height: 1.5em !important;
    padding-top: 8px !important;
}

.fieldsetIndent-icon legend > span:first-child {
    margin-bottom: 0;
    position: absolute;
    left: -45px;
}

#fieldSelector--blankState{
	position: absolute;
	width: 300px;
	padding: 0 25px;
	color: #999;
	font-size: 19px;
	top: 263px;
	z-index: 2;
	text-align:center;
	line-height: 24px;
	font-family: "BhrHeaderFont", "Trebuchet MS";
	display: flex;
	flex-direction: column;
	width: 272px;
	align-items: center;
	top: 127px;
	gap: 24px;
}

.FieldSelector {
	&__customValuesIcon {
		display: none;
		line-height: 0;

		&Container {
			min-height: 0px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}

		&New {
			padding: 0px 4px 0px 4px;
			min-height: 0px;
			display: flex;
			width: 16px;

			svg {
				fab-fill: gray6;
			}
			&:hover {
				svg {
					fab-fill: gray9;
				}
			}
		}

		&New:nth-of-type(even) {
			padding-left: 16px;
		}

		&.hasCustom {
			display: inline;
		}

		& svg {
			fab-fill: theme-base;
		}
	}

	// .FieldSelector__requiredStar
	&__requiredStar {
		position: relative;
		top: 4px;
		right: 9px;

		// .FieldSelector__requiredStar:after
		&:after {
			font-size: 30px;
		}
	}

	&__selected {
		margin-left: 25px !important;
	}

	&__selectedName {
		fab-color: gray10;
		fab-font-size: medium;
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: 47px; // 47px + 1px border = 48px high li
		max-width: 222px;
	}

	&__spacer {
		flex: 1 1 auto;

		// .exportName is the input in the selected list on the Add Webhook page
		.exportName + & {
			flex-shrink: 0;
			width: 20px;
			
			// Collapse the space to make room for the X button on hover
			li:hover > & {
				width: 0;			
			}
		}
	}

	&__configHeaderButton {
		margin-top: 16px!important;
	}

	&__configButton {
		background-color: transparent;
		border-width: 0px;
		padding: 0px;
		width: 16px;
		cursor: pointer;
		margin: 0px 10px;
		height: 18px;
	}


	&__listContainer {
		overflow: hidden;
		border-radius: 16px;		
	}
	&__fieldSelectorLabel {		
		fab-font-size: medium;
		fab-font-weight: semibold;
		fab-color: gray10;
		margin-bottom: 8px;		
	}
	&__warningContainer {
		align-self: flex-start;
		background-color: #fff1e5;
		border-radius: 16px;
		display: flex;
		margin-left: 25px;
		margin-top: 28px;
		max-width: 277px;
		padding: 12px;

		&--hidden {
			display: none;
		}
	}
	&__warningIcon {
		color: #A14300;
		fill: #A14300;
		margin-top: 2px;
	}
	&__warning {
		display: flex;
		flex-direction: column;
		gap: 2px;
	}
	&__warningTitle {
		color: #A14300;
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;
	}
	&__warningText {
		color: #48413f;
		font-size: 13px;
		line-height: 19px;
	}
}

.selector-page {
	box-shadow: 0 1px fab-color(gray1);
	fab-background-color: gray1;

	.field:hover {
		fab-background-color: theme-lightest10;

		.FieldSelector__selectedName {
			fab-color: theme-base;
			text-overflow: ellipsis;
			max-width: 260px;
		}
	}

	.showHide svg {
		fab-fill: theme-base;
	}

	.tableField,
	.field {
		.FieldSelector__selectedName {
			fab-color: gray8;
			fab-font-size: medium;
			overflow: hidden;
			text-overflow: ellipsis;
			line-height: 48px;
			max-width: 260px;
		}
	}

	.tableLabel {
		fab-color: gray8;
		fab-font-size: medium;
		line-height: 42px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&.collapsed:hover {
		.showHide {
			background-image: none;
		}
	}
	
	.displaynone,
	.displayNone {
		display: none !important;
	}	
}

#available .drag-list li button.config,
#available .drag-list li button.remove,
#selected.drag-list li button.config,
#selected.drag-list li button.remove,
#selected.drag-list li .customValuesCheck {
	display: none;
}

#selected.drag-list li:hover > button.config,
#selected.drag-list li:hover > button.remove,
#selected.drag-list li:hover > .customValuesCheck {
	display: inline-block;
}

// Both are needed as there are some use cases still using ba-icon
#available .drag-list li .tableLabel > ba-icon,
#available .drag-list li > ba-icon {
	display: inline;

	svg {
		position: absolute;
	}
}

#available .drag-list .subDragList > .tableField > ba-icon svg {
	top: 8px;	
}

#available .drag-list .subTable > .tableLabel > svg,
#available .drag-list .subTable > .tableLabel > ba-icon svg {
	top: 10px;
}

#available .drag-list li > svg,
#available .drag-list li .tableLabel > svg {
	display: none;
	position: absolute;
}

// Both are needed as there are some use cases still using ba-icon
#selected.drag-list li > ba-icon,
#selected.drag-list li .tableLabel > ba-icon,
#selected.drag-list li > svg,
#selected.drag-list li .tableLabel > svg {
	display: none;
}

#selected.drag-list li.subTable {

	& button.remove {
		float: right;
	}

	& .historicalSettings {
		display: block;
		margin-bottom: 8px;
	}
}

#available .drag-list li.subTable {
	background-color: none;

	.tableLabel {
		fab-color: gray8;
	}
}
#available .drag-list li.subTable.isHovering {
	fab-background-color: theme-lightest10;

	.tableLabel {
		fab-color: theme-base;
	}
}

#available .FieldSelector__selectedName {
	max-width: 260px;
}
.reportWizardTextField {
	margin-top: 4px;
}

#selected .tableLabel {
	line-height: 48px;
}

.checkedClass {
	background-color: cyan;
}

.approvalRequiredToggleIcon {
	text-indent: 0;
}
