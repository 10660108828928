menuToggle() {
	display: inline-block;

	ba-toggle-content {
		display: none;
	}

	ba-option,
	ba-option-group {
		display: none !important;
	}

	&[width="100"] {
		width: 100%;
	}
}