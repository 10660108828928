.fieldRow,
.fieldGroup {
	margin-bottom: 16px;

	.fab-Label {
		min-height: 12px;
		position: relative;
	}
}

.fieldBox {
	display: inline-block;
	+ .fieldBox:not(.masked) {
		margin-left: 8px;
		&.spaced {
			margin-left: 16px;
		}
	}

	&.fullWidth {
		display: block;
	}
}

.fieldDiv {
	position: relative;
	display: inline-block;

	&.fullWidth {
		display: block;
	}
}

.fieldRow > .fab-Label ~ .fieldBox > .fab-Label:first-child {
	margin-top: -20px;
}

.fieldGroup > .fieldRow {
	margin-bottom: 16px;
}

.alignedTight {
	margin-top: -20px;

	.fieldBox {
		vertical-align: bottom;
	}
}

.fab-TextInput {
	width: 128px;
}

.fab-InfoIcon {
	align-self: center;
	cursor: pointer;
	fab-fill: gray6;
	margin-left: 8px;
}

.repeatItem {
    position: relative;
	&:not(:last-child) {
		border-bottom: 1px solid #d4d4d4;
		+encore() {
			padding-bottom: 20px;
		}
	}
}

.formDelete {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	+encore() {
		top: 20px;
	}
}
