$title = @block {
	+legacy() {
		font-family: BhrHeaderFontStack;
	}
	font-weight: 500;
	line-height: 1em;
	color: #222;
}

$title1 = @block {
	{$title}
	font-size: 25px;
}

$title2 = @block {
	{title}
	font-size: 19px;
}

$title3 = @block {
	{title}
	font-size: 16px;
}

$fontMedium = @block {
	font-weight: 500;
}

$semiBold = @block {
	font-weight: 600;
}

$textWrap = @block {
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
}
