@css {
	.ac_results {
		padding: 1px 0px 1px 0px;
		background-color: white;
		overflow: hidden;
		z-index: 99999;
		margin-top: 3px;
		min-width: 220px;
		margin-left: -8px;
		box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);
		-moz-box-shadow: 0px 2px 10px rgba(0,0,0,.1);
		-webkit-box-shadow: 0px 2px 10px rgba(0,0,0,.1);
		border-radius: 3px;
		border: 1px solid #787878;
	}

	.ac_results ul {
		width: 100%;
		list-style-position: outside;
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.ac_results li {
		margin: 0px;
		padding: 2px 5px;
		cursor: default;
		display: block;
		text-align: left;
		/*
		if width will be 100% horizontal scrollbar will apear
		when scroll mode will be used
		*/
		/*width: 100%;*/
		/*font: menu;*/
		font-size: 11px;
		/*font-family: "Lucida Grande",Tahoma,Arial,sans-serif;*/
		/*
		it is very important, if line-height not setted or setted
		in relative units scroll will be broken in firefox
		*/
		line-height: 16px;
		overflow: hidden;
		white-space: nowrap;

	}

	.ac_results li.empty {
		background: none;
		color: #777 !important;
	}

	div#search-inside input.ac_loading,
	div#search-inside input.ac_input {
		background: #fff; border: none;
	}

	.ac_odd {
		background-color: #fff;
	}

	/*.ac_over {
		background-color: #E1EDF7;
	}*/

	/***Bamboo additions***/

	.ac_results li .qs_status.icon {
		-moz-border-radius:100%;
		-webkit-border-radius:100%;
		border-radius:100%;
		border-width: 1px;
		height: 8px;
		width:8px;
		display: block;
		float: left;
		margin: 5px 5px 0 0;
	}

	.ac_results li .qs_status.icon.na {
		display: none;
	}
	.ac_results li .qs_status.icon.Active{
		background-color: #88bb40;
		border-color: #88bb40;
	}

	.ac_results li .qs_status.icon.Inactive{
		background-color: #CCCCCC;
		border-color: #CCCCCC;
	}

	/*.ac_results li .qs_status.Active{
		color: #88bb40;
	}*/

	.ac_results li .qs_status.Inactive{
		color: #CCCCCC;
	}

	.ac_results .qsPhoto {
		height: 30px;
		margin: 5px 8px 5px 3px;
		vertical-align: top;
		float: left;
	}
	.ac_results .qsPhoto.qsPhotoGroup:not(.qsPhotoGroup-hover) {
		display: block;
	}
	.ac_results .ac_over .qsPhoto.qsPhotoGroup:not(.qsPhotoGroup-hover) {
		display: none;
	}
	.ac_results .qsPhoto.qsPhotoGroup.qsPhotoGroup-hover {
		display: none;
	}
	.ac_results .ac_over .qsPhoto.qsPhotoGroup.qsPhotoGroup-hover {
		display: block;
	}
	.ac_results .ac_over .qsPhoto {
		background-color: transparent;
	}
	.ac_results .qsPhoto + span{
		line-height: 23px;
		font-size: 14px;
		font-weight: 400;
		display: block;
		margin-bottom: -4px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.ac_results .qsEmpty {
	    font-size: 14px;
	    font-weight: 400;
	}
	.ac_results .jt{
		font-size: 12px;
		font-weight: 400;
		color: #777;
		display: block;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.ac_over .jt{
		color: #FFF;
	}
}
