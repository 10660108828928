.BhrForms .CurrencyPicker {
	position: relative;
}

.BhrForms .CurrencyPicker .CurrencyPicker__label {
	text-align: left;
}
//TODO: Greenhouse may still need these styles, leaving in for the moment but should be cleanedup.
.BhrForms .CurrencyPicker .CurrencyPicker__input:not(.fab-TextInput--error) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-color: #AFAFAF;

	&--disabled {
		border-right-color: #D7D7D7;
	}
}

.BhrForms .CurrencyPicker {
	&--pending .request-icon {
		right: 34px;
	}

	.CurrencyPicker__button {
		border: solid #afafaf 1px;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
		color: #777777;
		float: right;
		font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
		font-size: 12px;
		height: 26px;
		line-height: 2.1;
		margin-top: 4px;
		margin-left: -1px;
		position: relative;
		text-decoration: none;
		vertical-align: middle;
		width: 30px;
		text-align: center;

		&--disabled {
			background-color: #FFFFFF;
			border-color: #D7D7D7;
			box-shadow: none;
			box-sizing: none;
			color: #D7D7D7;
			pointer-events: none;
		}

		// Checks if the CurrencyPicker__buton <a> element contains the follow classes
		// CurrencyPicker__button CurrencyPicker--disabled CurrencyPicker__button--disabled
		// So that when the Input field is enabled and the button is disabled it will show
		// the correct border on the left side of the button.
		^[0]--disabled.CurrencyPicker__button--disabled {
			border-left-color: #D7D7D7;
		}

		&:hover {
			background: grey;
			background: linear-gradient(#FFFFFF, #F6F6F6); /* Standard syntax */
			border-color: #919191;
			box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.11);
		}

		&:active {
		    border-color: #AFAFAF;
		    background: #fff;
		    background: linear-gradient(to bottom, #f0f0f0, #FAFAFA);
		    box-shadow: inset 0 1px rgba(0,0,0,0.09);
		}
	}
}

.BhrForms {
	+encore() {
		.CurrencyPicker__button, #currency-picker-button {
			color: var(--gray6);
			border: none;
			border-left: none;
			font-weight: var(--font-weight-semibold);
			height: 92%;
			padding: 0 13px;
			border-radius: var(--radius-200);

			&:before {
				content: "";
				display: inline-block;
				width: 1px;
				height: 65%;
				position: absolute;
				top: 15%;
				left: -1px;
				background-color: var(--gray4);
			}

			&--disabled {
				background-color: var(--gray05);
				border: none;
				box-shadow: none;
				pointer-events: none;
			}

			&:hover {
				background-color: var(--gray1) !important;
				border: none;
				border-left: none;
				box-shadow: 0 0 0 1px var(--gray4);
			}
			&:focus {
				background: white !important;
				border-left: none;
				box-shadow: 0 0 0 4px var(--fabric-theme-lightest50);
				border: 1px solid var(--fabric-theme-light);
				outline: 1px solid var(--fabric-theme-base);
				color: var(--fabric-theme-base);
				&:before {
					display: none;
				}
			}
			&:disabled {
				background-color: var(--gray05);
				border: none;
    			box-shadow: none;
				pointer-events: none;
			}
		}
	}
}

#newCurrencyPickerWidget {
	/* @startCleanup encore */
	max-height: 175px;
	width: 225px;
	border-radius: 2px;
	box-shadow: 0 0 0 2px fab-color(theme-lightest35);
	overflow-y: auto;
	margin-top: 2px;
	border: 1px solid #d6;
	fab-color(border-color, theme-lighter);
	/* @endCleanup encore */
	display: none;
	position: absolute;
	background-color: white;
	padding: 0 0 3px 0;
	z-index: 100;

	+encore() {
		border-radius: 8px;
		box-sizing: border-box;
		max-height: 368px;
		width: unset;
		min-width: 176px;
		max-width: 368px;
		border: 1px solid var(--gray3);
		box-shadow: 3px 3px 10px 2px rgba($fabEncoreColors.gray3, 0.1);
		overflow: hidden;
		height: auto;
		margin-top: 9px;

		ul {
			overflow-y: auto;
			max-height: inherit;
			height: auto;
		}
	}

	&,
	/.currencyPicker {
		/* @startCleanup encore */
		font-family: Lato;
		/* @endCleanup encore */
		fab-font-size(small);

		+encore() {
			font-family: $encoreFontFamilyStack;
		}
	}

	li {
		&.divider {
			/* @startCleanup encore */
			padding: 0 5px;
			/* @endCleanup encore */
			margin: 0;

			hr {
				padding: 0;
				margin: 0;
			}

			+encore() {
				padding: spacing(0) spacing(2);

				&:hover {
					cursor: default;
					background-color: #fff;
				}
			}
		}

		&:not(.divider) {
			text-align: left;
			text-decoration: none;
			/* @startCleanup encore */
			color: black;
			line-height: 1;
			padding: 8px 10px;
			/* @endCleanup encore */

			+encore() {
				color: var(--gray9);
				line-height: var(--line-height-medium);
				padding: 10px 16px;
				font-size: var(--font-size-medium);
			}

			&:hover {
				cursor: pointer;
				/* @startCleanup encore */
				fab-color(background-color, theme-base);
				color: #FFFFFF;
				font-weight: 600;
				/* @endCleanup encore */

				+encore() {
					background-color: var(--gray1);
					color: var(--gray9);
					font-weight: var(--font-weight-regular);
				}
			}

			.code {
				display: inline-block;
				width: auto;
			}
		}
	}
}
