/* -------------------------------------------------
ITALIC
------------------------------------------------- */
fabricItalic()
	font-style: italic
	fab-font-weight(regular)

/* -------------------------------------------------
MONOSPACE
------------------------------------------------- */
fabricMonospace()
	font-family: 'SourceCodePro', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace
	fab-font-weight(semibold)
