/* Popover container for the calendar and controls */ 
.CalendarPicker__popover {
	background: var(--white);
	border: 1px solid var(--gray3);
	border-radius: var(--radius-200);
	box-shadow: 3px 3px 10px 2px rgba($fabEncoreColors.gray9, 0.10);
	margin: 0;
	padding: spacing(.5) spacing(.5) spacing(1) spacing(.5);
}

/* Arrow above the popover (removed for Encore) */
.CalendarPicker__caret {
	display: none;
}

/* Calendar structure */
.CalendarGrid { 
	display: flex;
	flex: 1;
	flex-direction: column;
}

.CalendarGrid__header {
	background-color: var(--gray05);
}

.CalendarGrid__body {
	background-color: var(--white);
    display: flex;
	padding: spacing(1) spacing(1) 0;
	flex: 1;
    flex-direction: column;
	gap: spacing(.5);
	margin-top: spacing(1);
}

.CalendarGridManager {
	display: inline-block;
	width: inherit;
}

.CalendarGridManager__grids {
	white-space: nowrap;
	display: flex;
}

.CalendarGridManager__grid {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	vertical-align: top;
	width: 100%;
}

/* Header containing the prev/next buttons and the dropdowns */
.CalendarGridManager__gridHeader {
	align-items: center;
	background-color: var(--gray05);
	border-radius: var(--radius-200) var(--radius-200) 0 0;
	display: flex;
	justify-content: center;
	padding-top: spacing(1);
	gap: spacing(1);
}

.CalendarGridManager__gridHeaderContent {
	align-items: center;
	display: flex;
	gap: spacing(1);
	height: 100%;
}



/* Previous and Next buttons */
.CalendarGridManager__button {
	align-items: center;
	background-color: var(--white);
	border: 1px solid var(--gray4);
	border-radius: var(--radius-1000);
	box-shadow: 1px 1px 0px 1px rgba($fabEncoreColors.gray9, 0.04);
	cursor: pointer;
	display: inline-flex;
	height: 32px;
    min-width: 40px;
	padding: 0 spacing(1.5);
}

.CalendarGridManager__button:hover {
	border-color: var(--gray7);
	box-shadow: 1px 1px 0px 2px rgba($fabEncoreColors.gray9, 0.03);
}

.CalendarGridManager__previousButton--disabled,
.CalendarGridManager__nextButton--disabled{
	opacity: 0.3;
}

/* Month and Year dropdows */
.CalendarPicker__inputWrapper {
	position: relative;
	display: inline-block;
}

.CalendarGridManager__gridHeaderDropdown {
	width: 88px !important;
}

/* Select container */
.CalendarGridManager__gridHeaderDropdown.chzn-container {
	border: 1px solid transparent;
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.CalendarGridManager__gridHeaderDropdown.chzn-container:not(.chzn-container-active) {
	border-radius: var(--radius-1000);
	box-shadow: 0 0 0 .5px var(--gray4);
}

.CalendarGridManager__gridHeaderDropdown.chzn-container.chzn-container-active {
	border-radius: var(--radius-1000);
	box-shadow: 0 0 0 1.5px var(--fabric-theme-light);
}

/* Select */
.CalendarGridManager__gridHeaderDropdown .chzn-single {
	align-items: center;
	background-color: var(--white);
	border: none;
	border-radius: var(--radius-1000);
	box-shadow: 1px 1px 0px 1px rgba($fabEncoreColors.gray9, 0.04);
	cursor: pointer;
    display: flex;
    height: 30px;
	padding: spacing(1) spacing(1) spacing(1) spacing(2);
	width: 88px;
}

/* Month/Year text */
.CalendarGridManager__gridHeaderDropdown .chzn-single span {
	color: var(--gray8);
    font-size: var(--font-size-small);
	line-height: var(--line-height-small);
}

/* Dropdown Icon */
.CalendarGridManager__gridHeaderDropdown .chzn-single div {
    border-left: 1px solid var(--gray4);
    fill: var(--gray5);
    height: 16px;
	padding: 0 spacing(.25) 0 spacing(1);
	top: auto;
    width: 19px;
}

.CalendarGridManager__gridHeaderDropdown .chzn-single div b {
	// the jQuery plugin is creating the dropdown and this was the most direct way to restyle the icon without modifying javascript
	// using a mask and background color since you cannot change the color of a background-image SVG in CSS
	background-color: var(--gray5);
	mask: url('https://staticfe.bamboohr.com/assets/icons/caret-down-solid.svg') no-repeat center;
  	mask-size: contain;
	height: 15px;
	width: 10px;
}

.CalendarGridManager__gridHeaderDropdown .chzn-drop {
	border: 1px solid var(--gray3);
	border-radius: var(--radius-200);
	box-shadow: 3px 3px 10px 2px rgba($fabEncoreColors.gray9, 0.1);
	margin-top: 9px;
	min-width: 176px;
	overflow: hidden;
}

.CalendarGridManager__gridHeaderDropdown .chzn-drop .chzn-results {
	border-top: none;
	max-height: 368px;
}

.CalendarGridManager__gridHeaderDropdown .chzn-drop .chzn-results:first-child {
	padding-top: spacing(.5);
}

.CalendarGridManager__gridHeaderDropdown .chzn-drop .chzn-results:last-child {
	padding-bottom: spacing(.5);
}

.CalendarGridManager__gridHeaderDropdown .chzn-drop .chzn-results .active-result {
    line-height: var(--line-height-xsmall);
    font-size: var(--font-size-small);
    min-height: 42px;
    display: flex;
    align-items: center;
    color: var(--gray9);
    padding-left: spacing(2);
    padding: 0 spacing(2);
    text-decoration: none;
    user-select: none;
}

.CalendarGridManager__gridHeaderDropdown .chzn-drop .chzn-results .highlighted {
	background-color: var(--gray1);
	background-image: none;
	color: var(--gray9);
}

/* Days of week header display */
.CalendarGrid__daysOfWeek {
	background-color: var(--gray05);
	display: flex;
	gap: spacing(.5);
	justify-content: space-around;
	margin-bottom: spacing(1);
	padding: spacing(1.5) spacing(.5) 0 spacing(.5);
	white-space: nowrap;
}

.CalendarGrid__dayOfWeek {
	color: var(--gray6)
	display: inline-block;
	font-size: var(--font-size-xsmall);
	font-weight: var(--font-weight-semibold);
	line-height: var(--line-height-xsmall);
	text-align: center;
}

/* Rows for the date cells */
.CalendarGrid__row {
	display: flex;
	gap: 4px;
	justify-content: space-evenly;
}

/* Date cells and each of the states */
.CalendarGrid__cell {
	align-items: center;
	border-radius: var(--radius-300);
	box-sizing: border-box;
	color: var(--gray9);
	display: flex;
	font-size: var(--font-size-small);
	font-weight: var(--font-weight-medium);
	height: 40px;
	justify-content: center;
	line-height: var(--line-height-small);
	margin: 0;
	padding: 0;
	position: relative;
	min-width: 40px;
	width: 100%;
}

.CalendarGrid__cell--withValue {
	cursor: pointer;
}

.CalendarGrid__cell--now {
	background-color: #fff;
	border: 1px solid var(--gray3);
    font-weight: var(--font-weight-semibold);
}

.CalendarGrid__cell--now:hover {
	border: none;
}

.CalendarGrid__cell--now.CalendarGrid__cell--inRange {
	border: 1px solid var(--fabric-theme-base);	
}

.CalendarGrid__cell--selected {
	background-color: var(--fabric-theme-base);
	border: 1px solid var(--fabric-theme-base);
    color: var(--white);
	font-weight: var(--font-weight-heavy)	
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}


.CalendarGrid__cell--withValue:not(.CalendarGrid__cell--disabled):hover {
	background-color: var(--fabric-theme-lightest);
	color: var(--fabric-theme-base);
	font-weight: var(--font-weight-semibold);
	z-index: 2;
}

.CalendarGrid__cell--disabled {
	background-color: var(--gray05);
	color: var(--gray6);
	cursor: default;
}


/* Range picker instance specific */
.CalendarPicker__rangeColumn {
	display: inline;
}

.CalendarPicker__rangeSeparator {
	padding: 0 5px;
}

.CalendarGridManager__gridHeader--paddedRight {
	border-radius: var(--radius-200) 0 0 0;
	justify-content: flex-start;
	padding-left: spacing(2);

}

.CalendarGridManager__gridHeader--paddedRight .CalendarGridManager__gridHeaderContent {
    width: 100%;
}

.CalendarGridManager__gridHeader--paddedLeft {
	border-radius: 0 var(--radius-200) 0 0;
	justify-content: flex-end;
	padding-right: spacing(2);

}

.CalendarGridManager__gridHeader--paddedLeft .CalendarGridManager__gridHeaderContent {
	border-left: 1px solid var(--gray2);
	justify-content: flex-end;
	width: 100%;
}

.CalendarGridManager__grid:not(:first-child) .CalendarGrid__daysOfWeek {
	border-left: 1px solid var(--gray2);
}

.CalendarGridManager__grid:not(:first-child) .CalendarGrid__body {
	border-left: 1px solid var(--gray2);
}

.CalendarGrid__cell--inRange:not(.CalendarGrid__cell--selected) {
	background-color: var(--fabric-theme-lightest);
	color: var(--fabric-theme-base);
	font-weight: var(--font-weight-semibold);
	z-index: 2;
}

.CalendarGrid__cell--inRange:not(.CalendarGrid__cell--selected).CalendarGrid__cell--disabled {
	color: var(--gray6);
}
/* End: Range specific */


/* Month only instance specific styles */
/* Fixes the padding since the daysOfWeek section is absent */
.CalendarGridManager__monthView .CalendarGridManager__gridHeader {
	padding-bottom: spacing(1);
}

.CalendarGridManager__gridTitle {
	color: var(--gray9);
	font-size: var(--font-size-large);
	font-weight: var(--font-weight-semibold);
	line-height: var(--line-height-biggie);
	display: flex;
    width: 100%;
    justify-content: center;
}

.CalendarGridManager__gridHeader--paddedRight .CalendarGridManager__gridTitle {
	margin-right: 20px;
}

.CalendarGridManager__gridHeader--paddedLeft .CalendarGridManager__gridTitle {
	margin-left: 20px;
}

.CalendarGridManager__monthView .CalendarGrid__body {
	padding-top: 0;
}

.CalendarGridManager__monthView .CalendarGrid__cell {
	width: 56px;	
}