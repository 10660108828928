.ui-widget.ui-multiselect { padding: 0 0 2px 4px; text-align:left }
.ui-widget.ui-multiselect span.ui-icon { float:right }
.ui-widget.ui-multiselect-single .ui-multiselect-checkboxes input { position:absolute !important; top: auto !important; left:-9999px; }
.ui-widget.ui-multiselect-single .ui-multiselect-checkboxes label { padding:5px !important }

.ui-multiselect-header { margin-bottom:3px; padding:3px 0 3px 4px }
.ui-multiselect-header ul { font-size:0.9em }
.ui-multiselect-header ul li { float:left; padding:0 10px 0 0 }
.ui-multiselect-header a { text-decoration:none }
.ui-multiselect-header a:hover { text-decoration:underline }
.ui-multiselect-header span.ui-icon { float:left }
.ui-multiselect-header li.ui-multiselect-close { float:right; text-align:right; padding-right:0 }

.ui-widget.ui-multiselect-menu { display:none; position:absolute; z-index:10000; text-align: left; background-color: #fffff; min-width: 175px; }
.ui-widget.ui-multiselect-checkboxes { position:relative /* fixes bug in IE6/7 */; overflow-y:auto }
.ui-widget.ui-multiselect-checkboxes label { cursor:default; display:block; border:1px solid transparent; padding:3px 1px }
.ui-widget.ui-multiselect-checkboxes label input { position:relative; top:1px }
.ui-widget.ui-multiselect-checkboxes li { clear:both; font-size:0.9em; padding-right:3px }
.ui-widget.ui-multiselect-checkboxes li.ui-multiselect-optgroup-label { text-align:center; font-weight:bold; border-bottom:1px solid }
.ui-widget.ui-multiselect-checkboxes li.ui-multiselect-optgroup-label a { display:block; padding:3px; margin:1px 0; text-decoration:none }
.ui-widget.ui-multiselect-menu li:last-child label { padding: 3px 6px 6px; } /* More padding on last child */

.ui-widget.ui-multiselect-menu .ui-multiselect-checkboxes .single label > input[type="checkbox"] + span:before { display: none; }
.ui-widget.ui-multiselect-menu li.single:hover { color: #fff; font-weight: normal; cursor: pointer !important; }
.ui-widget.ui-multiselect-menu .dividerLabel { font-size: 12px; color: #999; padding: 0px 6px; }

/* remove label borders in IE6 because IE6 does not support transparency */
* html .ui-multiselect-checkboxes label { border:none }
