.Balloon--popover {

	border-radius: 2px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);
	padding: 1px;

	.Balloon {

		&__closeX {
			cursor: pointer;
			fill: #ccc;
			opacity: 0.6;
		}

		&__border {
			border-radius: 2px;
			border: 1px solid #d4d4d4;
		}

		&__tail {
			background-color: #f;
			border: 1px solid #d4d4d4;
			box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.14);
			box-sizing: border-box;
		}

		&__content {
			background-color: #f;
			border-radius: 1px;
		}

	}
}



.Balloon--popover-error {

	@extends .Balloon--popover;

	.Balloon {

		&__border {
			border: 1px solid #D06441;
		}

		&__tail {
			border: 1px solid #D06441;
		}

		&__content {
			color: #b5320a;
		}

	}
}


/********************************************************
Standard Popover
********************************************************/

.PopoverStandard {
	fab-font-weight: normal;
	font-size: inherit;
	line-height: inherit;
	min-width: 240px;
	// @startCleanup encore
	padding: 20px 24px;
	max-width: 200px;
	// @endCleanup encore

	+encore() {
		padding: 0;
		max-width: 324px;
	}

	&__title {
		fab-color(theme);
		// @startCleanup encore
		fab-text(biggie);
		fab-line-height: biggie;
		font-family: inherit;
		margin-bottom: 4px;
		// @endCleanup encore


		+encore() {
			fab-text: h4;
			font-family: $encoreHeaderFontFamilyStack; 
			fab-font-weight: semibold;
			margin-bottom: 8px;
		}
	}

	&__content {
		fab-font-weight: normal;
		fab-line-height: teenie;
		// @startCleanup encore
		fab-text(small);
		// @endCleanup encore

		+encore() {
			fab-text: medium;
		}
	}
}


{featureToggleOn("jade")} {
	.Balloon--popover {

		.Balloon {

			&__closeX {
				display: flex;
				fab-fill: gray5;
				opacity: 1;
				right: 12px;
				top: 12px;

				&:hover {
					.Balloon__closeSVG {
						fab-fill: gray6;
					}
				}
			}

			&__closeSVG {
				fab-fill: gray5;
			}

			&__border {
				border: 1px solid fab-color(gray3);
			}

			&__tail {
				background-color: fab-color(white);
				border: 1px solid fab-color(gray3);
			}

			&__content {
				background-color: fab-color(white);
				fab-color(gray9);
			}

		}

		&-error {
			.Balloon {

				&__border {
					border: 1px solid fab-color(danger);
				}

				&__tail {
					border: 1px solid fab-color(danger);
				}

				&__content {
					fab-color(dangerDark);
				}

			}
		}
	}
}

+encore() {
	.Balloon--popover { 
		border-radius: 8px;
		background-color: fab-color(white);
		box-shadow: 3px 3px 10px 2px rgba($fabEncoreColors.gray9, 0.1);

		.Balloon {
			&__border {
				border-radius: 8px;
				border-color: fab-color(gray3);
			}

			&__closeX {
				top: 20px;
				fill: unset;
				position: unset;
			}

			&__closeSVG {
				fab-fill: gray8;
			}

			&__content {
				display: flex;
				flex-direction: row-reverse;
				gap: 12px;
				padding: 20px 12px 20px 20px;
				border-radius: 8px;
				&--noPadding {
					padding: 0;
				}
			}
		}
	}
}
