/**
 * The stylesheets below were removed from the encore build for one or more of the following reasons:
 *
 * 1. They are static css stylesheets, which should no longer be included in encore
 * 2. They are no longer used or no longer widely used enough to be in the global stylesheet, and should be more locally scoped
 * 3. Styles have a wide specificity/scope and need to be more adequately scoped down and organized using BEM
 * 4. Fabric styles or components should be used instead
 *
 * If you believe a stylesheet should be included, please reach out to Fabricants or Grease Monkeys
* */// @require "./_styles.css";
// @require "Box.mod.css";
// @require "BhrShape.mod";
// @require "CalendarPicker.mod";
// @require "CurrencyPicker.mod";
// @require "GlobalSearch.mod"
// @require "Label.mod";
// @require "InfoChanges.mod";
// @require "mlDrop.mod";
// @require "./view-as";
// @require "./_header";
// @require "ManageNav.mod";
// @require "Modal.mod";
// @require "./_buttons";
// @require "Icons.mod";
// @require "BhrIcons.mod.css";
// @require "BhrSvg.mod";
// @require "SearchInput.mod";
// @require "./_tables.css";
// @require "./_glyphicons-sprite.css";
// @require "Svg.mod";
// @require "Comments.mod";
// @require "Animation.mod";
// @require "Overlay.mod";
// @require "ProgressiveLoader.mod";
// @require "TimePicker.mod.css";
// @require "Box.mod.css";
// @require "ScrollBox.mod.css";
// @require "List.mod.css";
// @require "InboxDropdown.mod";
// @require "callout.mod";
// @require '../../public/files/no-access/styles';
// @require '../forms/forms.styl';

@require "reset.lib";
@require "Alerts.mod";
@require "Banners.mod";
@require "Avatar.mod";
@require "BhrPage.mod";
@require "BlankState.mod";
@require "Chosen.mod";
@require "CustomApprovalModal.mod";
@require "CurrencyPickerNew.mod";
@require "FileCard.mod";
@require "FilterList.mod";
@require "jQuery.mod";
@require "./_global_styles";
@require "./_layout";
@require "ProgressBar.mod";
@require "jquery.autocomplete.lib";
@require "jquery.calendar-picker.encore.lib";
@require "Tooltips.mod";
@require "Popovers.mod";
@require "Balloon.mod/Balloon.mod";
@require "Loaders.mod";
@require "LoginModal.mod";
@require "LegacyShareModal.mod";
@require "FilePreviewer.mod";
@require "PdfJs.mod";
@require "Pill.mod";
@require "./_change-password";
@require "SimpleModal.mod";
@require "DatePicker.mod";
@require "MultiSelect.mod";
@require "Chart.mod";
@require "ba-file-icon.mod";
@require "ba-icon.mod";
@require "custom-elements.comp/ba-dropdown/ba-dropdown.styl";
@require "custom-elements.comp/ba-option/ba-option.styl";
@require "custom-elements.comp/ba-select/ba-select.styl";
@require "FieldsSelector.mod";
@require "./jade-globals/themes";
@require "./jade-globals/page-header";
@require "./jade-globals/typography";
@require "./encore-site-footer";
@require "./jade-globals/legacy-form/layout.styl";
// TinyMCE Editor Custom Styles - Can be removed once we enable TinyMCE V6 in PO
@require "./jade-globals/_bamboo-rich-text.styl";
// autoInputList jQuery plugin styles - Can be removed once we migrate the share modals, and email alerts away from it
@require "./jade-globals/legacy-form/typeahead.styl";
@require "./jade-globals/legacy-form/masked-value.styl";
@require "./jade-globals/legacy-form/view.styl";
@require "./jade-globals/legacy-form/text-area-counter.styl";

:root {
	--page-capsule-padding: ($fabEncorePageCapsule.padding) px;
	--page-capsule-padding-y: ($fabEncorePageCapsule.paddingY) px;
	--page-header-height-lg: 186px;
	--page-header-height-xl: 224px;
	--page-header-looping-offset-top: 30px;
}

input, button, textarea, select {
	font: inherit;
}

hr {
	background-color: transparent;
	border-color: var(--gray4, $fabEncoreColors.gray4);
	border-style: solid;
	border-width: 0 0 thin;
	flex-shrink: 0;
	height: unset;
	margin: 0;
	width: unset;

	&.fab-Divider--weak {
		border-color: var(
			--gray3,
			$fabEncoreColors.gray3
		);
	}

	&.fab-Divider--x-weak {
		border-color: var(
			--gray2,
			$fabEncoreColors.gray2
		);
	}
}

body {
	font-family: $encoreFontFamilyStack;
}

// browser defaults to min-content width, which is unintuitive and can cause overflow
fieldset {
	min-inline-size: auto;
}

+encore() {
	.MainPageContainer {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		flex-shrink: 0;
		padding-bottom: 24px;
		padding-right: ($fabEncorePageWidths.buffer)px;

		@media print {
			padding-right: 0;
		}
	}

	.MainPageContainer__inner {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		min-width: clamp(($fabEncorePageWidths.min)px, 100%, ($fabEncorePageWidths.max)px);
		max-width: var(--mainContentMaxWidth, ($fabEncorePageWidths.max)px);
		margin-left: auto;
		margin-right: auto;
		position: relative;
		width: var(--mainContentWidth, 100%);

		@media print {
			min-width: 100%;
			margin-left: 0;
			margin-right: 0;	
		}
	}

	.BhrPage-wrap {
		flex-grow: 1;
		padding: unset;
	}

	.flexFullHeight {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	.PageCapsule {
		border-radius: var(--radius-600);
		box-sizing: border-box;
		background-color: var(--gray05, $fabEncoreColors.gray05);
		display: flex;
		flex-direction: column;
		height: 100%;
		min-width: 100%;
		/*
		* This is necessary to allow children to use `position: sticky`. Setting `overflow: hidden` will break `position: sticky`.
		* The previous implementation used `contain: paint` but it prevents content from expanding the capsule without also using `width: fit-content`.
		*/
		overflow: clip;
		padding: var(--page-capsule-padding);

		@media print {
			background: none;
			border-radius: 0;
			display: block;
		}
	}

	.PageCapsule-children {
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		@media print {
			display: block;
		}
	}

	.PageCapsule-footer {
		position: sticky;
		bottom: -1px;
	}

	.ContentGridContainer {
		display: grid;
		grid-template-areas: "banners banners" "navigation scrollContainer";
		grid-template-columns: auto 1fr;
		grid-template-rows: auto minmax(0, 1fr);
		height: 100%;
		width: 100%;

		@media print {
			// This is needed so that print tables have their headers, and footers printed on every page.
			display: block;
		}
	}

	.GlobalScrollContainer {
		grid-area: scrollContainer;
		height: 100%;
		width: 100%;
		container-name: scrollContainer;
		container-type: inline-size;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		overflow-x: auto;
		overflow-y: scroll;

		@media print {
			overflow: visible;
		}
	}

	.GlobalNavigation {
		background-color: fab-color(white);
		grid-area: navigation;
		/*
		* We need to set a width because GlobalNavigation (from Fabric) is loaded via a Po micro-frontend and causes layout shift without it.
		* We must also use `width` here instead of `min-width` to allow the width to be transitioned when the navigation is opened.
		*/
		width: 104px;
		transition: width 0.2s linear;

		&--open {
			width: 248px;
		}
	}

	.GlobalSiteHeader {
		background-color: fab-color(white);
		box-sizing: border-box;
		/*
		* We need to set the height because Header (from Fabric) is loaded via Po microfrontend and causes layout shift without it.
		*/
		flex: 0 0 var(--fabric-global-header-height, 91px);
		left: 0;
		min-width: ($fabEncorePageWidths.min)px;
		position: sticky;
		top: 0;
		width: 100%;
		z-index: 30;
	}

	.SessionMessage {
		position: fixed;
		margin-top: 18px;
		left: 0;
		width: 100%;
	}
	
	.ScrollDivider {
		background-color: @css{color-mix(in srgb, var(--gray5) 20%, transparent)}
		box-shadow: 0 2px 1px -1px @css{color-mix(in srgb, var(--gray5) 5%, transparent)}
		border: none;
		box-sizing: border-box;
		content: "";
		margin-top: -1px;
		height: 1px;
		opacity: 0;
		position: sticky;
		transition: opacity 0.3s ease-out, box-shadow 0.3s ease-out;
		top: calc(var(--fabric-global-header-height, 91px) - 1px);
		width: 100%;
		z-index: 30;
	}
	
	.ScrollDivider--visible {
		opacity: 1;	
	}

	.BannerContainer {
		grid-area: banners;
		top: 0;
		z-index: 30;
	}

	.fabricBrandColor {
		fill: var(--fabric-theme-base);
	}

	.customFieldBuilderSupport {
		align-items: unset;
		background-color: var(--infoLight);
		border: 0;
		border-radius: var(--radius-100);
		box-shadow: none;
		column-gap: 13px;
		display: flex;
		height: unset;
		justify-content: space-between;
		margin-top: 15px;
		padding: 12px;
		width: fit-content;

		&-message {

			&--description {
				color: var(--gray8);
				font-size: var(--font-size-teenie);
				font-weight: var(--font-weight-regular);
				padding-top: 2px;
			}
			&--icon {
				padding-top: 2px;
			}
			&--text {
				display: flex;
				flex-direction: column;
				line-height: unset;
			}
			&--title{
				color: var(--infoDark);
				font-size: var(--font-size-small);
				font-weight: var(--font-weight-semibold);
			}
		}
	}

	/*
	* This removes the gutter next to the scrollbar when there's not enough space and the gutter would cause an unnecessary scrollbar to appear.
	* The query needs to account for the minimum content width, the gutter width, and the scrollbar width.
	*/
	$scrollbarWidth = 16;
	$minContentWidthWithGutter = $fabEncorePageWidths.min + $fabEncorePageWidths.buffer + $scrollbarWidth;
	@container (width < {$minContentWidthWithGutter}px) {
		.MainPageContainer {
			padding-right: 0;
		}
	}
}
