.BhrPage {
	&-wrap {
		min-height: calc(100% - 92px);
		text-align: center;
		&:after {
			content: '';
			display: block;
		}
	}
}
