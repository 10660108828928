.limitCount {

	&--error {
		// @startCleanup encore
		fab-color: danger;
		// @endCleanup encore

		+encore() { 
			color: var(--error); 
		}
	}

	&--warning {
		// @startCleanup encore
		fab-color: attention;
		// @endCleanup encore

		+encore() {
			color: var(--warning);
		}
	}

}