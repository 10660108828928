
.ShareModal {
	.modalSubHeader {
		+encore() {
			margin-top: 0;
		}
	}
	.typeahead {
		.input-list {
			.description {
				fab-color: gray6;
				fab-font-size: medium;
				fab-line-height: medium;
				fab-font-weight: regular;
				padding-left: 0;
			}    
		}
	}
}

// Cut out of both FilePreviewer.mod.styl and Modal.mod.styl to reduce redudant styles and increase legibility 
#sharedWith {

	.shareForm & {
		// @startCleanup encore
		max-height: 200px;
		min-height: 115px;
		// @endCleanup encore
		+encore() {
			max-height: 195px;
			min-height: 130px;
		}
		// @startCleanup encore
		width: 440px;
		+encore() {
			width: unset;
		}
		// @endCleanup encore
		overflow-y: auto;
		padding-bottom: 25px;
	}

	// @startCleanup encore
	border-top: 1px solid fab-color(gray3);
	// @endCleanup encore
	+encore() {
		border-top: 1px solid fab-color(gray1);
		overflow-y: auto;
	}

	tr {
		* {
			vertical-align: middle;
		}
		// @startCleanup encore
		border-bottom: 1px solid fab-color(gray3);
		padding-left: 18px;
		// @endClenaup encore
		+encore() {
			border-bottom: 1px solid fab-color(gray1);
			padding-left: 11px;
		}
		display: flex;
	}

	td {
		height: 64px;
		border-bottom: none;
		padding: 0;
		display: inline-flex;
		flex-direction: column;
		justify-content: center;

		&.nameColumn {
			flex-grow: 2;
			// @startCleanup encore
			margin-left: 9px;
			// @endCleanup encore
			+encore() {
				fab-font-weight: semibold;
				margin-left: spacing(1.5);

				.nameColumn__name {
					display: flex;
					flex-direction: column;	
				}
			}
		}

		&.photoColumn {
			// @startCleanup encore
			.Avatar {
				width: 35px;
			}
			// @endCleanup encore
			+encore() {
				.Avatar {
					display: flex;
					justify-content: center;
					align-items: center;
					fab-fill: theme-base;
					fab-background-color: gray1;
					width: 40px;
					height: 40px;	
				}
			}
		}

		&.missingNoticeCol {
			width: 4px;
			padding: 5px 0 5px 5px;

			img {
				margin: -4px 3px 0 2px;
			}
		}

		&.unShareColumn {
			text-align: right;
			padding-right: 20px;
			fab-font-size: teenie;
			color: fab-color(gray7);

			button, a {
				margin-right: -4px;
			}
		}

		.reportOwnerName {
			// @startCleanup encore
			fab-font-weight: bold;
			// @endCleanup encore
			+encore() {
				fab-font-weight: semibold;
			}
			fab-font-size: medium;
		}

		.hint {
			fab-font-weight: regular;
			fab-color: gray7;
			fab-font-size: teenie;
			+encore() {
				fab-line-height: teenie;
			}
			// @startCleanup encore
			// In Jade the hint is wrapped in parens, but in encore it isn't.
			// The markup is also generated in PHP, and we don't currently have a way to toggle encore work in PHP.
			// I moved the parens from the markup into pseudo elements as it lets us get around this.
			// It also results in cleaner code once encore is cleaned up.
			+jadeFabricTheme() {
				&::before {
					content: '(';
				}

				&::after {
					content: ')';	
				}
			}
			// @endCleanup encore
		}
	}
}
