$fabCoreColors = json('@bamboohr/fabric/dist/definitions/json/colors.json', { hash: true }).colors;
$fabColors = merge($fabCoreColors, {});
$fabText = json('@bamboohr/fabric/dist/definitions/json/text.json', { hash: true });
$fabThemes = json('@bamboohr/fabric/dist/definitions/json/theme-colors.json', { hash: true }).themeColors;
$fabSizes = json('@bamboohr/fabric/dist/definitions/json/sizes.json', { hash: true });
$fabPageWidths = json('@bamboohr/fabric/dist/definitions/json/layout.json', { hash: true }).pageWidths;
$fabBorderRadius = json('@bamboohr/fabric/dist/definitions/json/encore-border-radiuses.json', { hash: true }).borderRadiuses;
$defaultTheme = $fabThemes['0']; // BambooHR brand "lime1";

// @startEncoreCleanup
$fabEncoreThemes = json('@bamboohr/fabric/dist/definitions/json/encore-theme-colors.json', { hash: true }).themeColors;
$fabEncoreColors = json('@bamboohr/fabric/dist/definitions/json/encore-colors.json', { hash: true }).colors;
$fabEncoreText = json('@bamboohr/fabric/dist/definitions/json/encore-text.json', { hash: true });
$fabEncoreSizes = json('@bamboohr/fabric/dist/definitions/json/sizes.json', { hash: true });
$fabEncorePageCapsule = json('@bamboohr/fabric/dist/definitions/json/encore-layout.json', { hash: true }).pageCapsule;
$fabEncorePageWidths = json('@bamboohr/fabric/dist/definitions/json/encore-layout.json', { hash: true }).pageWidths;
$encoreDefaultTheme = $fabEncoreThemes['0']; // BambooHR brand "lime1";
// @endEncoreCleanup

$colorHash = {
	hex: {},
};
for $name, $color in $fabCoreColors {
	$varName = '--' + $name;
	$colorHash[$name] = s('var(%s, %s)', unquote($varName), $color);
	$colorHash.hex[$name] = $color;
}

$fabColors = merge($colorHash, {
	theme-base-default: var(--theme-base-default, $defaultTheme.base),
	theme-light-default: var(--theme-light-default, $defaultTheme.light),
	theme-lighter-default: var(--theme-lighter-default, $defaultTheme.lighter),
	theme-lightest-default: var(--theme-lightest-default, $defaultTheme.lightest),
	theme-lightest10-default: var(--theme-lightest10-default, rgba($defaultTheme.lightest, 0.1)),
	theme-lightest35-default: var(--theme-lightest35-default, rgba($defaultTheme.lightest, 0.35)),
	theme-lightest50-default: var(--theme-lightest50-default, rgba($defaultTheme.lightest, 0.5)),
	theme-base: var(--fabric-theme-base, $defaultTheme.base),
	theme-light: var(--fabric-theme-light, $defaultTheme.light),
	theme-lighter: var(--fabric-theme-lighter, $defaultTheme.lighter),
	theme-lightest: var(--fabric-theme-lightest, $defaultTheme.lightest),
	theme-lightest10: var(--fabric-theme-lightest10, rgba($defaultTheme.lightest, 0.1)),
	theme-lightest35: var(--fabric-theme-lightest35, rgba($defaultTheme.lightest, 0.35)),
	theme-lightest50: var(--fabric-theme-lightest50, rgba($defaultTheme.lightest, 0.5)),
});

--fab-theme($name) {
	s('var(--fabric-theme-%s, %s)', $name, $defaultTheme[$name]);
}

fab-font-size($name) {
	$size = $fabText.textSizes[$name] || $fabText.headlineSizes[$name];

	font-size: var(--font-size-+$name, unit($size.size || $fabText.textSizes.medium.size, 'px'));
}

fab-line-height($name) {
	$size = $fabText.textSizes[$name] || $fabText.headlineSizes[$name];

	line-height: var(--line-height-+$name, unit($size.line || $fabText.textSizes.medium.line, 'px'));
}

fab-text($name) {
	fab-font-size: $name;
	fab-line-height: $name;
}

fab-font-weight-font-face($name) {
	font-weight: $fabText.textWeights[$name] || $fabText.textWeights.regular;
}

fab-font-weight($name) {
	font-weight: var(--font-weight-+$name, $fabText.textWeights[$name] || $fabText.textWeights.regular);
}

/**
 *  Examples:
 *      .someClass {
 *          fab-color: theme-lightest;
 *          fab-color: border-color gray3;
 *          border-color: fab-color(gray3);
 *          box-shadow: 1px 1px 1px fab-color(theme-lightest10);
 *      }
 */
fab-color($name) {
	if (mixin == 'root') {
		error("😬 fab-color doesn't work as a root mixin!");
	}

	$prop = 'color';
	$isTheme = false;
	$variant = 'base';

	if (length(arguments) == 2) {
		$prop = arguments[0];
		$name = arguments[1];
	}

	$name = '' + $name;

	if (match('^theme', $name)) {
		$isTheme = true;

		if (match('^theme-', $name)) {
			$variant = replace('theme-', '', $name) || $variant;
		}

		$name = 'theme-' + $variant;
	}

	if ($name == '#fff') {
		$name = 'white';
	}

	if (!$fabColors[$name]) {
		error($name + " is not a valid Fabric color name value.");
	}

	if (mixin) {
		// It's being used like a property:value pair

		if ($isTheme) {
			{$prop}: $fabColors[$name + '-default'];
		}

		{$prop}: $fabColors[$name];
	} else {
		// It's being used like a function to return a value
		if ($isTheme) {
			$sep = list-separator(current-property[1]);
			$line = join($sep, current-property[1]);
			$newLine = replace('__CALL__', '' + $fabColors[$name + '-default'], $line);
			add-property(current-property[0], convert($newLine));
		}


		if ($name in $fabColors.hex) {
			return $fabColors.hex[$name];
		}
		return $fabColors[$name];
	}
}

fab-fill($name) {
	fab-color: fill $name;
}

fab-background-color($name) {
	fab-color: background-color $name;
}

fab-border-color($name) {
	fab-color: border-color $name;
}

fab-size($dimension, $name) {
	$prop = replace('s', '', $dimension);
	$dimension = $prop + 's';

	if (length(arguments) > 2) {
		$prop = arguments[1];
		$name = arguments[2];
	}

	$name = '' + $name;

	if (!$fabSizes[$dimension]) {
		error('"' + $dimension + '" is not a valid Fabric size. Try something like "' + join('", "', slice(keys($fabSizes), 0, 2)) + '" etc.');
	}

	if (!$fabSizes[$dimension][$name]) {
		error('"' + $name + '" is not a valid Fabric ' + replace('s', '', $dimension) + '. Try something like "' + join('", "', slice(keys($fabSizes[$dimension]), 0, 3)) + '" etc.');
	}

	$value = unit($fabSizes[$dimension][$name], 'px');

	if (mixin) {
		{$prop}: $value;
	} else {
		return $value;
	}
}

fab-height($name) {
	$prop = 'height';

	if (length(arguments) > 1) {
		$prop = arguments[0];
		$name = arguments[1];
	}

	fab-size: 'heights' $prop $name;
}

fab-height-size = fab-height;

fab-min-height($name) {
	fab-size: 'heights' 'min-height' $name;
}

fab-max-height($name) {
	fab-size: 'heights' 'max-height' $name;
}

fab-width($name) {
	$prop = 'width';

	if (length(arguments) > 1) {
		$prop = arguments[0];
		$name = arguments[1];
	}

	fab-size: 'widths' $prop $name;
}

fab-width-size = fab-width;

fab-min-width($name) {
	fab-size: 'widths' 'min-width' $name;
}

fab-max-width($name) {
	fab-size: 'widths' 'max-width' $name;
}


fab-input-focus() {
	border-color: $fabColors.theme-lighter;
	box-shadow: 0 0 0 2px $fabColors.theme-lightest35-default;
	box-shadow: 0 0 0 2px $fabColors.theme-lightest35;
	outline: 0;
}

/**
 *  Examples:
 *      .someClass {
 *          fab-gradient: theme-lightest theme-lighter;
 *          fab-gradient: 0 theme-base theme-light;
 *      }
 */
fab-gradient() {
	if (mixin == 'root' || !mixin) {
		error('😬 fab-gradient only works as a strict mixin, generating a property:value pair.');
	}

	$angle = 90deg;
	$from = arguments[0];
	$to = arguments[1];

	if (length(arguments) > 3 || length(arguments) < 2) {
		error('Whoa there. This humble mixin only accepts 2 or 3 arguments.');
	} else if (length(arguments) == 3) {
		$angle = arguments[0];
		$from = arguments[1];
		$to = arguments[2];
	}

	if (match('^theme-', $from)) {
		$from = unquote(replace('theme-', '', $from));
		$to = unquote(replace('theme-', '', $to));

		if (!$defaultTheme[$from] && !$defaultTheme[$to]) {
			error("That's not a valid Fabric themed gradient value.");
		}

		background-image: linear-gradient($angle, $defaultTheme[$from] 0, $defaultTheme[$to] 100%);
		background-image: linear-gradient($angle, --fab-theme($from) 0, --fab-theme($to) 100%);
	} else {
		if (!$fabColors[$from] && !$fabColors[$to]) {
			error("That's not a valid Fabric color name value.");
		}

		background-image: linear-gradient($angle, $fabColors[$from] 0, $fabColors[$to] 100%);
	}
}

fab-gradient-page-header() {
	if (mixin == 'root' || !mixin) {
		error('😬 fab-gradient-page-header only works as a strict mixin, generating a property:value pair.');
	}

	$angle = 90deg;
	$from = arguments[0];
	$to = arguments[1];

	if (length(arguments) != 2) {
		error('Whoa there. This humble mixin only accepts 2 arguments');
	}

	if (match('^theme-', $from)) {
		$from = unquote(replace('theme-', '', $from));
		$to = unquote(replace('theme-', '', $to));

		if (!$defaultTheme[$from] && !$defaultTheme[$to]) {
			error("That's not a valid Fabric themed gradient value.");
		}

		background-image: linear-gradient($angle, $defaultTheme[$from] 0, $defaultTheme[$to] 40%);
		background-image: linear-gradient($angle, --fab-theme($from) 0, --fab-theme($to) 40%);
	} else {
		if (!$fabColors[$from] && !$fabColors[$to]) {
			error("That's not a valid Fabric color name value.");
		}

		background-image: linear-gradient($angle, $fabColors[$from] 0, $fabColors[$to] 40%);
	}
}

/**
 * Block mixins
 */

fab-max-width() {
	@media screen and (max-width: ($fabPageWidths.max + ($fabPageWidths.buffer * 2))px) {
		{block};
	}
}


/**
 * Placeholder Selectors
 */

$fab-PageWidth--base = @block {
	width: $fabPageWidths.base;
}

$fab-PageWidth--min = @block {
	min-width: ($fabPageWidths.min)px;
}

$fab-PageWidth--max = @block {
	max-width: ($fabPageWidths.max)px;
}

$fab-PageWidth--padding = @block {
	padding: 0 ($fabPageWidths.buffer)px;
}

$fab-PageWidth--bufferLeft = @block {
	left: ($fabPageWidths.buffer)px;
}

$fab-PageWidth--bufferRight = @block {
	right: ($fabPageWidths.buffer)px;
}

$fab-Page = @block {
	{$fab-PageWidth--base};
	max-width: $fabPageWidths.base;
	margin: 0;
}

$fab-SiteContentLegacy = @block {
	{$fab-PageWidth--base};
	{$fab-PageWidth--min};
	{$fab-PageWidth--max};
	margin-left: auto;
	margin-right: auto;

	+fab-max-width() {
		{$fab-PageWidth--padding};
	}
}
