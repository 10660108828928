.typeahead {

	.shareForm {
		display: flex;
		flex-direction: column;
	}

	.inputField {
		// @startCleanup encore
		{fabricThemeEnabled('jade')} & {
			border-radius: 2px;
			border-style: solid;
			border-width: 1px;
			box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);
			fab-background-color: white;
			fab-border-color: gray4;
			fab-color: gray10;
			min-height: 64px;
			padding: 6px 6px 0 6px;
			resize: none;
			transition: all 200ms ease-in-out;

			&:not(.inactive) {
				box-shadow: 0 0 0 2px fab-color('theme-lightest35');
				fab-border-color: theme-lighter;
				min-height: 64px;
				outline: 0;
			}
		}
		// @endCleanup encore
		+encore() {
			min-height: 76px;
		}
	}

	.input-list {
		&.shown {
			// @startCleanup encore
			min-height: 64px;
			// @endCleanup encore
			+encore() {
				min-height: 76px;
			}
		}

		&--small {
			width: 418px;
		};

		.item {
			align-items: center;
			display: inline-flex;
			// @startCleanup encore
			border-radius: 3px;
			fab-background-color: gray3;
			fab-font-size: small;
			// @endCleanup encore
			+encore() {
				fab-background-color: gray1;
				fab-font-size: teenie;
				border-radius: 15px;
			}
			fab-font-weight(medium);
			height: 24px;
			margin-bottom: 6px;
			margin-right: 6px;
			padding: 3px 8px;

			.remove {
				cursor: pointer;
				display: inline-block;
				// @startCleanup encore
				fab-fill: gray7;
				// @endCleanup encore
				+encore() {
					fab-fill: gray6;
				}
				margin: 0 0 -1px 13px;
			}
		}

		.description {
			fab-color: gray7;
			margin: 0;
		}

		.input {
			margin-bottom: 9px;
		}

		input.free {
			border: none;
			fab-font-size: small;
			height: 20px;
		}

	}

}

.ac_results {
	padding: 0;
	// @startCleanup encore
	border: none;
	overflow: visible;
	// @endCleanup encore
	+encore() {
		border-radius: 8px;
		border-style: solid;
		border-width: 1px;
		fab-border-color: gray2;
		overflow: hidden auto;
	}
	transition: all 200ms ease-in-out;
	width: 276px !important; // Gotta override the style set by jQuery for Jade

	ul {
		outline: 0;
		box-sizing: border-box;
		// @startCleanup encore
		{fabricThemeEnabled('jade')} & {
			fab-background-color: white;
			border-style: solid;
			border-width: 1px;
			box-shadow: 0 0 0 2px fab-color('theme-lightest35');
			border-radius: 2px;
			fab-border-color: theme-lighter;
		}
		// @endCleanup encore
		+encore() {
			padding: 12px 0;
		}
	}

	ul:empty {
		border: none; 
		box-shadow: none;
	}

	li {
		padding: 10px 15px;
		+encore() {
			fab-color: gray9;
		}
	}

	+encore() {
		li .jt {
			fab-color: gray6;
		}

		li strong {
			font-weight: 400;
		}

		.qsPhotoGroup {
			position: relative;
			border-radius: 8px;
			svg {
				fab-fill: theme-base;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
		}

		/**
		* The autoInputList library targets the photo group with a high level of specifivity :(.
		* Inorder to beat the background color it gives to the photo group we got to do this.
		**/
		.user, .user_group {
			.qsPhoto.qsPhotoGroup {
				fab-background-color: gray2;
			}
		}
	}

	.qsPhoto {
		height: 35px;
		width: 35px;
		margin: 0 10px 0 0;
	}

	.ac_over {
		// @startCleanup encore
		{fabricThemeEnabled('jade')} & {
			fab-background-color: theme-base;
			fab-color: white;
			.jt {
				fab-color: white;
			}
		}
		// @endCleanup encore
		+encore() {
			fab-background-color: gray1;
			color: inherit;
			.jt {
				fab-color: gray6;
			}
		}
	}
	
}

.typeahead--small {
	.inputField {
		min-height: 29px;
	}

	.input-list {
		width: 418px;
	}

	.ac_results {
		width: unset !important;
	}
}
