ba-icon {
	display: inline-flex;
	font-size: 0;
	line-height: 0;
	overflow: hidden;
}

.ba-icon-inline-flex {
   /*
      Used in an element (such as span) with some text and a ba-icon.
      Will center the ba-icon and label in the container and create a gap of 8px.
      Will center the elements in the container as well.
   */
   +encore() {
      display: inline-flex;
      align-items: center;
      gap: 8px;
   }
}

// This class is used for a container that holds an icon and text, and sets the icon inline
.ba-icon-inline {
	+encore() {
		display: flex;
		padding-top: 2px;
		gap: 8px;
		overflow: visible;
	}
}
