.SliceChart {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin: 0 43px;

	&__bar {
		margin-left: auto;
	}

	&__title {
		align-items: center;
		display: flex;
		font-size: 16px;
	}

	&__subtitle::before {
		content: '\00a0';
	}

	&__subtitle {
		color: #af;
	}

	&__cell {
		shape-rendering: crispEdges;
	}

	&__popover {
		min-width: 0;
		padding: 10px 16px;
		width: 126px;
	}

}

@media print {
	.SliceChart {
		page-break-inside: avoid;
	}
}
